/** Absolute imports */
import React, { useEffect, useState } from 'react';
import { Switch, Route, RouteComponentProps, Link } from "react-router-dom";
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

/** Components */
import { Header } from "../components/Header/header";
import { LoginPage } from '../components/Login/login';
import { RegisterPage } from '../components/Login/register';
import Preloader from '../components/Preloader/preloader';

/** Ant designe */
import { Layout, Menu } from 'antd';
import 'antd/dist/antd.css';
import './layout.scss';
import {
  DashboardOutlined,
  SettingOutlined,
  FileOutlined,
  ControlOutlined,
  UserOutlined,
} from '@ant-design/icons';

/** Styles */
import s from './mainStyle.module.scss';
import './mainStyle.scss';

/** Types */
import { MainPageSelectors } from './types';

/** Selectors */
import { makeGetIsAuth, makeGetIsInitialized, makeGetRoles } from '../store/auth/selectors';

/** Store */
import { ApplicationState } from '../store';
import { initializeAppRequest } from '../store/auth/actions';

/** Enum */
import { routeNames } from './routeNames';

/** Pages */
import { DashboardPage } from '../pages/Dashboard/dashboard';
import { MainPage } from '../pages/MainPage/mainPage';
import { ProfileContainer } from '../pages/Profile/profile-container';
import { AddNewDevice } from '../pages/Dashboard/Add/add-new-device';
import { TypesPage } from '../pages/Settings/MainSettings/types-page';
import { MacrosPage } from '../pages/Settings/MainSettings/macros-page';
import { TemplatesPage } from '../pages/Settings/MainSettings/templates-page';
import { EditDevice } from '../pages/Dashboard/Edit/edit-device';
import { UsersPage } from '../pages/Settings/Access/users-page';
import { RolesPage } from '../pages/Settings/Access/roles-page';
import { MainSettingsPage } from '../pages/Settings/MainSettings/mainSettings';


const { Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;


export const LayoutPage: React.FC<RouteComponentProps> = (props) => {

    const [collapsed, setColapset] = useState(false);

    const onCollapse = () => {
        setColapset(!collapsed);
      };

    const dispatch = useDispatch();
    const selector = createStructuredSelector<
        ApplicationState,
        MainPageSelectors
        >({
        isInitialized: makeGetIsInitialized(),
        isAuth: makeGetIsAuth(),
        roles: makeGetRoles()
    });

    const {
        isInitialized,
        isAuth,
        roles
    } = useSelector(selector);

    useEffect(() => {
        dispatch(initializeAppRequest());
    },[])

    if (!isInitialized) {
        return <Preloader />
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            {isAuth &&
          <Sider collapsible width={340} collapsed={collapsed} onCollapse={onCollapse}>
            <div className={s.logo} />
            <Menu defaultSelectedKeys={['1']} mode="inline">
              <Menu.Item key="1" icon={<UserOutlined style={{fontSize: "18px"}} />}>
                 <Link to="/profile">Profile</Link>
              </Menu.Item>
              <Menu.Item key="2" icon={<DashboardOutlined />}>
                 <Link to="/dashboard">Dashboard</Link>
              </Menu.Item>
              <SubMenu key="Settings" icon={<SettingOutlined />} title="Settings">
                <Menu.Item key="3">Set up1</Menu.Item>
              </SubMenu>
              {roles.includes("admin") &&
                <SubMenu key="Administration" icon={<ControlOutlined />} title="Administration">
                  <Menu.Item key="4"><Link to="/access/main_settings">Main Settings</Link></Menu.Item>
                  <Menu.Item key="5"><Link to="/access/users">Users</Link></Menu.Item>
                  <Menu.Item key="6"><Link to="/access/roles">Roles</Link></Menu.Item>
                </SubMenu>
              }
              <SubMenu key="9" title={"Manual"} icon={<FileOutlined style={{fontSize: "18px"}} />}>
                <Menu.Item key="6">Article 1</Menu.Item>
                <Menu.Item key="8">Article 2</Menu.Item>
                <Menu.Item key="9">Article 3</Menu.Item>
                <Menu.Item key="10">Article 4</Menu.Item>
                <Menu.Item key="11">Article 5</Menu.Item>
                <Menu.Item key="12">Article 6</Menu.Item>
                <Menu.Item key="13">Article 7</Menu.Item>
                <Menu.Item key="14">Article 8</Menu.Item>
                <Menu.Item key="15">Article 9</Menu.Item>
                <Menu.Item key="16">Article 10</Menu.Item>
              </SubMenu>
            </Menu>
          </Sider>}

          <Layout className="site-layout">
          <Header {...props} />
          <Content className={s.contentBlock}>
            <Layout className={s.layoutInfo}>
          <Content className={s.mainPart}>    
                <Switch>
                <Route
                    path={routeNames.login}
                    component={LoginPage}
                />
                <Route
                    path={routeNames.register}
                    component={RegisterPage}
                />
                <Route
                    path={routeNames.dashboard}
                    component={DashboardPage}
                />
                <Route
                    path={routeNames.profile}
                    component={ProfileContainer}
                />  
                <Route
                    path={routeNames.mainSettings}
                    component={MainSettingsPage}
                /> 
                <Route
                    path={routeNames.addNewDevice}
                    component={AddNewDevice}
                />  
                <Route
                    path={routeNames.editDevice}
                    component={EditDevice}
                />
                <Route
                    path={routeNames.users}
                    component={UsersPage}
                />
                <Route
                    path={routeNames.roles}
                    component={RolesPage}
                />
                <Route
                    exact
                    path={routeNames.default}
                    component={MainPage}
                />  
                </Switch>
            </Content>
            </Layout>
            </Content>
            <Footer style={{ textAlign: 'center' }}>VIMonitoring ©2021 Created by <a href="https://kirav.ru/">Nikita Kirav</a></Footer>
          </Layout>
        </Layout>
      );
}