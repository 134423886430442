import React, { useEffect, useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useSelector, useDispatch } from 'react-redux';
import './react-cropper.scss';
import { createStructuredSelector } from "reselect";
import { ApplicationState } from "../../../store";
import { ReactCropperSelectors } from "../types";
import { makeGetProfilePhotoLarge } from "../../../store/profile/selectors";
import { addPhotoRequest } from "../../../store/profile/actions";

type ReactCropperType = {
  rerender: boolean
}

export const ReactCropper: React.FC<ReactCropperType> = React.memo(({rerender}) => {

  const dispatch = useDispatch();

  const selector = createStructuredSelector<
      ApplicationState,
      ReactCropperSelectors
   >({
      photo: makeGetProfilePhotoLarge()
   });

   const {
      photo
   } = useSelector(selector);

  const cropperRef = useRef<HTMLImageElement>(null);
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(key + 1);
  }, [rerender])

  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    let imageData = cropper.getCroppedCanvas() && cropper.getCroppedCanvas().toDataURL();
    if (imageData)
      dispatch(addPhotoRequest(imageData));
  };

  return (<div key={key}>
            <Cropper
            src={photo}
            style={{ height: 400, width: "100%" }}
            // Cropper.js options
            initialAspectRatio={1}
            guides={false}
            crop={onCrop}
            ref={cropperRef}
            />
        </div>
  );
});