/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { DeleteTemplateRequest } from "../actionTypes";
import {
    deleteTemplateFailure,
    deleteTemplateSuccess,
    getTemplatesRequest,
} from "../actions";

/** Services */
import { deleteTemplate } from "../../../services/hostSettingsService";

/** Types */
import { DeleteResponseDataType } from "../../device/types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* deleteTemplateSaga({
  payload: { templateId },
}: DeleteTemplateRequest) {
  const { cancelToken, source } = createCancelToken();

  try {
    const response: DeleteResponseDataType = yield call(
      deleteTemplate,
      templateId
    );
    if(response && response.resultCode === ResultCode.Success) {
      yield put(deleteTemplateSuccess());
      yield put(getTemplatesRequest());
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(deleteTemplateFailure(response.message));
    }    
  } catch (error) {
    yield put(deleteTemplateFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default deleteTemplateSaga;