/** Absolute imports */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

/** Ant design */
import { Button, Input, Form, Select, Space, Modal } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

/** Components */

/** Store */
import { saveTemplateRequest } from '../../../../store/deviceSettings/actions';
import { ApplicationState } from '../../../../store';
import { makeGetError, makeGetMacros } from '../../../../store/deviceSettings/selectors';

/** Types */
import { TemplateEditSelectors, TemplateEditProps } from '../types';
import { Template } from '../../../../store/deviceSettings/types';

/** Styles */
import s from '../styles.module.scss';


export const TemplateEdit: React.FC<TemplateEditProps> = ({visible, onCancel, initialValues}) => {

    const [form] = Form.useForm();

    const selector = createStructuredSelector<
    ApplicationState,
    TemplateEditSelectors
    >({
        macros: makeGetMacros(),
        error: makeGetError()
    });

    const {
        macros,
        error
    } = useSelector(selector);

    const dispatch = useDispatch();

    const onFinish = (values: Template) => {
        console.log('Success:', values);
        dispatch(saveTemplateRequest(values));
        onCancel();
      };
    
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleOk = () => {
        form.submit();
    };

    return (
        <Modal title="Edit template" visible={visible} onOk={handleOk} onCancel={onCancel}>
        <div className={s.templateEdit}>
        <Form
            form={form}
            initialValues={initialValues}
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className={s.formTemplateEdit}
        >
            <Form.Item
                name="id"
                hidden={true} 
            >
                <Input /> 
            </Form.Item>
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input a name!' }]}
            >
                <Input placeholder="{$EXAMPLE}" /> 
            </Form.Item>
            <Form.Item
                label="TemplateId"
                name="templateid"
                rules={[{ required: true, message: 'Please input a templateId!' }]}
            >
                <Input /> 
            </Form.Item>
            <Form.Item
                label="Description"
                name="description"
            >
                <Input /> 
            </Form.Item>
            <Form.Item
                label="Macros of device"   
                >
                <Form.List name="macros">
                {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Form.Item
                        {...restField}
                        name={[name, 'macro']}
                        fieldKey={[fieldKey, 'macro']}
                        rules={[{ required: true, message: 'Missing Macro Type' }]}
                        >
                        <Select style={{minWidth: 200}} placeholder={"${EXAMPLE}"}>
                            {macros.map(macro => {
                            return (
                                <Select.Option key={macro.id} value={macro.id}>{macro.name}</Select.Option>
                            );
                            })}
                        </Select>
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                    ))}
                    <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add field
                    </Button>
                    </Form.Item>
                </>
                )}
            </Form.List>
            </Form.Item>
    </Form>
    </div>
    </Modal>
    );
}
