/** Absolute imports */
import { combineReducers } from "redux";

/** Reducers */
import auth from "./auth/reducer";
import profile from "./profile/reducer";
import device from "./device/reducer";
import deviceSettings from "./deviceSettings/reducer";
import access from "./access/reducer";

/** Utils */
import { history } from "../utils/routerUtils";
import { connectRouter } from "connected-react-router";


export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    auth,
    profile,
    device,
    deviceSettings,
    access,
    router: connectRouter(history),
    ...injectedReducers,
  });

  return rootReducer;
}