/** Absolute imports */
import { put } from "redux-saga/effects";

/** Redux */
import {
    initializedSuccess,
    setAuthUserData,
    setError,
} from "../actions";

/** Services */
import { getLocalStorage } from "../../../services/api";

function* initializeAppSaga() {
  try {
    const data = getLocalStorage(); 
    if(data && data.token) {
        yield put(setAuthUserData(data.userId, data.userName, data.userPhoto, data.token, data.roles));
    }
    yield put(initializedSuccess());
  } catch (error) {
    yield put(setError(error));
  }
}

export default initializeAppSaga;