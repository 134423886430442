/** Absolute imports */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

/** Ant design */
import { Button, Table, Modal, Alert } from 'antd';

/** Types */
import { ServerPageSelectors } from './types';
import { Server } from '../../../store/deviceSettings/types';

/** Components */
import { ServerEdit } from './Edit/server-edit';

/** Store */
import { ApplicationState } from '../../../store';
import { deleteServerRequest, getServerRequest, getServersRequest } from '../../../store/deviceSettings/actions';
import { makeGetError, makeGetServer, makeGetServers } from '../../../store/deviceSettings/selectors';

/** Styles */
import s from './styles.module.scss';
import { makeGetIsAuth } from '../../../store/auth/selectors';


export const ServersPage: React.FC = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isServerEditVisible, setIsServerEditVisible] = useState(false);
    const [serverData, setServerData] = useState<Server | undefined>(undefined);
    const [serverId, setServerId] = useState<string | undefined>(undefined);

    const dispatch = useDispatch();
    const selector = createStructuredSelector<
    ApplicationState,
    ServerPageSelectors
    >({
        isAuth: makeGetIsAuth(),
        servers: makeGetServers(),
        error: makeGetError(),
        server: makeGetServer()
    });

    const {
        isAuth,
        servers,
        error,
        server
    } = useSelector(selector);

    useEffect(() => {
        dispatch(getServersRequest());
    }, []);

    useEffect(() => {
        if (server)
            setServerData(server);
    }, [server]);

    const handleCancelIsOpenTypeEditModal = () => {
        setIsServerEditVisible(false);
        setServerData(undefined);
    }

    const showModal = (serverId: string) => {
        setServerId(serverId);
        setIsModalVisible(true);
      };

    const onEditServerHandle = (id: string | undefined) => {
        if (id) {
            dispatch(getServerRequest(id));
        } else {
            setServerData({
                id: "",
                name: "",
                address: "",
                login: "",
                password: "",
                groupid: "",
            });
        }
        setIsServerEditVisible(true);
    }

    const handleOk = () => {
        if(serverId) {
          setIsModalVisible(false);
          dispatch(deleteServerRequest(serverId));
        }
    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns: any = [
        {
          width: 1,
          dataIndex: 'id',
          key: 'id',
          fixed: 'left',
          className: s.idColumn
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: '1',
            width: 350
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: '1',
            width: 350
        },
        {
            title: 'Login',
            dataIndex: 'login',
            key: '1',
            width: 350
        },
        {
          title: 'Edit',
          key: 'editOperation',
          width: 50,
          className: s.buttonsEditDelete,
          render: (values: any) => { 
            return (<Button onClick={() => onEditServerHandle(values.id)}>Edit</Button>)
          },
        },
        {
          title: 'Delete',
          key: 'deleteOperation',
          width: 50,
          className: s.buttonsEditDelete,
          render: (values: any) => <Button onClick={() => showModal(values.id)}>Delete</Button>,
        },
      ];      
      

    if (!isAuth) {
        return  <Redirect to={"/login"} />
    }
 
    return (
        <>
        {error && 
            <Alert
                message={error}
                type="error"
                showIcon
                className={s.error}
            />
        }
        <div style={{marginBottom: '15px'}}><Button onClick={() => onEditServerHandle(undefined)}>Add server</Button></div>
        <Table rowKey={record => record.id} columns={columns} dataSource={servers} className={s.dashboardTable} />
        <Modal title="Delete the server" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <div>Are you sure that you want to delete this server?</div>
        </Modal>
        {serverData && 
            <ServerEdit visible={isServerEditVisible} initialValues={serverData} onCancel={handleCancelIsOpenTypeEditModal} />
        }
        </>
    );
}