import { Role, User, UserEdit, UserRoles } from "./types";

/** Types */
import {
    SET_ERROR,
    SetError,
    GET_USERS_REQUEST,
    GetUsersRequest,
    GET_USERS_SUCCESS,
    GetUsersSuccess,
    GET_USERS_FAILURE,
    GetUsersFailure,
    SaveUserRequest,
    SAVE_USER_REQUEST,
    SAVE_USER_SUCCESS,
    SaveUserSuccess,
    SAVE_USER_FAILURE,
    SaveUserFailure,
    GetUserRequest,
    GetUserSuccess,
    GetUserFailure,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
    GET_ROLES_REQUEST,
    GetRolesRequest,
    GetRolesSuccess,
    GET_ROLES_SUCCESS,
    GetRolesFailure,
    GET_ROLES_FAILURE,
    GET_ROLE_REQUEST,
    GetRoleRequest,
    GetRoleSuccess,
    GET_ROLE_SUCCESS,
    GetRoleFailure,
    GET_ROLE_FAILURE,
    SaveRoleRequest,
    SaveRoleSuccess,
    SaveRoleFailure,
    SAVE_ROLE_REQUEST,
    SAVE_ROLE_SUCCESS,
    SAVE_ROLE_FAILURE,
    CHANGE_USER_ROLES_FAILURE,
    CHANGE_USER_ROLES_SUCCESS,
    CHANGE_USER_ROLES_REQUEST,
    ChangeUserRolesRequest,
    ChangeUserRolesSuccess,
    ChangeUserRolesFailure,
    DeleteUserRequest,
    DeleteUserSuccess,
    DeleteUserFailure,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
    DeleteRoleRequest,
    DeleteRoleSuccess,
    DeleteRoleFailure,
    DELETE_ROLE_REQUEST,
    DELETE_ROLE_SUCCESS,
    DELETE_ROLE_FAILURE,
    GET_USER_ROLES_REQUEST,
    GetUserRolesRequest,
    GET_USER_ROLES_SUCCESS,
    GetUserRolesSuccess,
    GET_USER_ROLES_FAILURE,
    GetUserRolesFailure,
  } from "./actionTypes";
  

export const setError = (
error: string | undefined
): SetError => ({
type: SET_ERROR, 
payload: {error}
});

/** Get Users */
export const getUsersRequest = (): GetUsersRequest => ({
    type: GET_USERS_REQUEST
});

export const getUsersSuccess = (users: Array<User>): GetUsersSuccess => ({
  type: GET_USERS_SUCCESS,
  payload: { users }
});

export const getUsersFailure = (error: string | undefined): GetUsersFailure => ({
    type: GET_USERS_FAILURE,
    payload: { error }
});

/** Get User */
export const getUserRequest = (userId: string): GetUserRequest => ({
  type: GET_USER_REQUEST,
  payload: { userId }
});

export const getUserSuccess = (user: UserEdit): GetUserSuccess => ({
  type: GET_USER_SUCCESS,
  payload: { user }
});

export const getUserFailure = (error: string | undefined): GetUserFailure => ({
  type: GET_USER_FAILURE,
  payload: { error }
})

/** Save User */
export const saveUserRequest = (user: UserEdit): SaveUserRequest => ({
  type: SAVE_USER_REQUEST,
  payload: { user }
});

export const saveUserSuccess = (): SaveUserSuccess => ({
  type: SAVE_USER_SUCCESS
});

export const saveUserFailure = (error: string | undefined): SaveUserFailure => ({
  type: SAVE_USER_FAILURE,
  payload: { error }
});

/** Delete User */
export const deleteUserRequest = (userId: string): DeleteUserRequest => ({
  type: DELETE_USER_REQUEST,
  payload: { userId }
});

export const deleteUserSuccess = (): DeleteUserSuccess => ({
  type: DELETE_USER_SUCCESS
});

export const deleteUserFailure = (error: string | undefined): DeleteUserFailure => ({
  type: DELETE_USER_FAILURE,
  payload: { error }
});

/** Get Roles */
export const getRolesRequest = (): GetRolesRequest => ({
  type: GET_ROLES_REQUEST
});

export const getRolesSuccess = (roles: Array<Role>): GetRolesSuccess => ({
type: GET_ROLES_SUCCESS,
payload: { roles }
});

export const getRolesFailure = (error: string | undefined): GetRolesFailure => ({
  type: GET_ROLES_FAILURE,
  payload: { error }
});

/** Get Role */
export const getRoleRequest = (roleId: string): GetRoleRequest => ({
  type: GET_ROLE_REQUEST,
  payload: { roleId }
});

export const getRoleSuccess = (role: Role): GetRoleSuccess => ({
  type: GET_ROLE_SUCCESS,
  payload: { role }
});

export const getRoleFailure = (error: string | undefined): GetRoleFailure => ({
  type: GET_ROLE_FAILURE,
  payload: { error }
})

/** Save Role */
export const saveRoleRequest = (role: Role): SaveRoleRequest => ({
  type: SAVE_ROLE_REQUEST,
  payload: { role }
});

export const saveRoleSuccess = (): SaveRoleSuccess => ({
  type: SAVE_ROLE_SUCCESS
});

export const saveRoleFailure = (error: string | undefined): SaveRoleFailure => ({
  type: SAVE_ROLE_FAILURE,
  payload: { error }
});

/** Delete Role */
export const deleteRoleRequest = (roleId: string): DeleteRoleRequest => ({
  type: DELETE_ROLE_REQUEST,
  payload: { roleId }
});

export const deleteRoleSuccess = (): DeleteRoleSuccess => ({
  type: DELETE_ROLE_SUCCESS
});

export const deleteRoleFailure = (error: string | undefined): DeleteRoleFailure => ({
  type: DELETE_ROLE_FAILURE,
  payload: { error }
});

/** Change Roles of User */
export const changeUserRolesRequest = (userId: string, roles: Array<string>): ChangeUserRolesRequest => ({
  type: CHANGE_USER_ROLES_REQUEST,
  payload: { userId, roles }
});

export const changeUserRolesSuccess = (): ChangeUserRolesSuccess => ({
  type: CHANGE_USER_ROLES_SUCCESS
});

export const changeUserRolesFailure = (error: string | undefined): ChangeUserRolesFailure => ({
  type: CHANGE_USER_ROLES_FAILURE,
  payload: { error }
});

/** Get User Roles */
export const getUserRolesRequest = (userId: string): GetUserRolesRequest => ({
  type: GET_USER_ROLES_REQUEST,
  payload: { userId }
});

export const getUserRolesSuccess = (userRoles: UserRoles): GetUserRolesSuccess => ({
  type: GET_USER_ROLES_SUCCESS,
  payload: { userRoles }
});

export const getUserRolesFailure = (error: string | undefined): GetUserRolesFailure => ({
  type: GET_USER_ROLES_FAILURE,
  payload: { error }
});