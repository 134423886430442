/** Absolute imports */
import { createSelector } from "reselect";

/** Redux */
import { INITIAL_STATE } from "../auth/reducer";

/** Types */
import { ApplicationState } from "..";
import { ProfileState } from "./types";

const selectProfile = (state: ApplicationState): ProfileState =>
  state.profile || INITIAL_STATE;

export const makeGetError = () => 
    createSelector(selectProfile, (profileState) => profileState.error);

export const makeGetProfile = () => 
    createSelector(selectProfile, (profileState) => profileState.profile);

export const makeGetProfilePhotoLarge = () => 
    createSelector(selectProfile, (profileState) => { 
        return profileState.profile ? profileState.profile.photos.large : "";
    });

export const makeGetProfilePhoto = () => 
    createSelector(selectProfile, (profileState) => { 
        return profileState.photo;
    });