import { Host, HostForShowing } from "./types";

export const SET_ERROR = "[DEVICE] SET_ERROR";

export const GET_HOSTS_REQUEST = "[DEVICE] GET_HOSTS_REQUEST";
export const GET_HOSTS_SUCCESS = "[DEVICE] GET_HOSTS_SUCCESS";
export const GET_HOSTS_FAILURE = "[DEVICE] GET_HOSTS_FAILURE";

export const SAVE_HOST_REQUEST = "[DEVICE] SAVE_HOST_REQUEST";
export const SAVE_HOST_SUCCESS = "[DEVICE] SAVE_HOST_SUCCESS";
export const SAVE_HOST_FAILURE = "[DEVICE] SAVE_HOST_FAILURE";

export const SET_HOST_STATUS_REQUEST = "[DEVICE] SET_HOST_STATUS_REQUEST";
export const SET_HOST_STATUS_SUCCESS = "[DEVICE] SET_HOST_STATUS_SUCCESS";
export const SET_HOST_STATUS_FAILURE = "[DEVICE] SET_HOST_STATUS_FAILURE";

export const GET_HOST_REQUEST = "[DEVICE] GET_HOST_REQUEST";
export const GET_HOST_SUCCESS = "[DEVICE] GET_HOST_SUCCESS";
export const GET_HOST_FAILURE = "[DEVICE] GET_HOST_FAILURE";

export const DELETE_HOST_REQUEST = "[DEVICE] DELETE_HOST_REQUEST";
export const DELETE_HOST_SUCCESS = "[DEVICE] DELETE_HOST_SUCCESS";
export const DELETE_HOST_FAILURE = "[DEVICE] DELETE_HOST_FAILURE";

/** Set Host Status */
export interface SetHostStatusRequest {
  type: typeof SET_HOST_STATUS_REQUEST;
  payload: {
      id: string;
      status: boolean;
  };
}

export interface SetHostStatusSuccess {
  type: typeof SET_HOST_STATUS_SUCCESS;
}

export interface SetHostStatusFailure {
  type: typeof SET_HOST_STATUS_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Save Host */
export interface SaveHostRequest {
  type: typeof SAVE_HOST_REQUEST;
  payload: {
      host: Host | undefined;
  };
}

export interface SaveHostSuccess {
  type: typeof SAVE_HOST_SUCCESS;
}

export interface SaveHostFailure {
  type: typeof SAVE_HOST_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Get Host */
export interface GetHostRequest {
  type: typeof GET_HOST_REQUEST;
  payload: {
      hostId: string | undefined;
  };
}

export interface GetHostSuccess {
  type: typeof GET_HOST_SUCCESS;
  payload: {
      host: Host | undefined;
  };
}

export interface GetHostFailure {
  type: typeof GET_HOST_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Get Hosts */
export interface GetHostsRequest {
  type: typeof GET_HOSTS_REQUEST;
}

export interface GetHostsSuccess {
  type: typeof GET_HOSTS_SUCCESS;
  payload: {
      hosts: Array<HostForShowing> | undefined;
  };
}

export interface GetHostsFailure {
  type: typeof GET_HOSTS_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Delete Host */
export interface DeleteHostRequest {
  type: typeof DELETE_HOST_REQUEST;
  payload: {
      hostId: string | undefined;
  };
}

export interface DeleteHostSuccess {
  type: typeof DELETE_HOST_SUCCESS;
}

export interface DeleteHostFailure {
  type: typeof DELETE_HOST_FAILURE;
  payload: {
    error: string | undefined;
  };
}

export interface SetError {
    type: typeof SET_ERROR;
    payload: {
      error: string | undefined;
    };
  }

export type DeviceActionTypes =
  | SaveHostRequest
  | SaveHostSuccess
  | SaveHostFailure
  | DeleteHostRequest
  | DeleteHostSuccess
  | DeleteHostFailure
  | DeleteHostSuccess
  | GetHostRequest
  | GetHostFailure
  | GetHostSuccess
  | GetHostsRequest
  | GetHostsFailure
  | GetHostsSuccess
  | SetError;
