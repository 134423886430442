/** Absolute imports */
import { all, fork } from "redux-saga/effects";

/** Sagas */
import watchAuthSagas from "./auth/sagas";
import watchProfileSagas from "./profile/sagas";
import watchDeviceSagas from "./device/sagas";
import watchDeviceSettingsSagas from "./deviceSettings/sagas";
import watchAccessSagas from "./access/sagas";

function* rootSaga() {
  yield all([
    fork(watchAuthSagas),
    fork(watchProfileSagas),
    fork(watchDeviceSagas),
    fork(watchDeviceSettingsSagas),
    fork(watchAccessSagas),
  ]);
}

export default rootSaga;