/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import {
    getTemplatesSuccess,
    getTemplatesFailure,
} from "../actions";

/** Services */
import { getTemplates } from "../../../services/hostSettingsService";

/** Types */
import { GetTemplatesResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* getTemplatesSaga() {
  const { source } = createCancelToken();

  try {
    //yield put(setError(undefined));
    const response: GetTemplatesResponseDataType = yield call(getTemplates);
    if(response && response.resultCode === ResultCode.Success && response.data) {
      yield put(getTemplatesSuccess(response.data.templates));
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(getTemplatesFailure(response.message));
    }    
  } catch (error) {
    yield put(getTemplatesFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default getTemplatesSaga;