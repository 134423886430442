/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { GetMacrosByTemplateIdRequest } from "../actionTypes";
import {
  getMacrosByTemplateIdFailure,
  getMacrosByTemplateIdSuccess,
} from "../actions";

/** Services */
import { getMacrosByTemplateId } from "../../../services/hostSettingsService";

/** Types */
import { GetMacrosResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* getMacrosByTemplateIdSaga({
  payload: {templateId},
}: GetMacrosByTemplateIdRequest) {
  const { source } = createCancelToken();

  try {
    const response: GetMacrosResponseDataType = yield call(
      getMacrosByTemplateId,
      templateId
    );
    if(response && response.resultCode === ResultCode.Success && response.data) {
      yield put(getMacrosByTemplateIdSuccess(response.data.macros));
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(getMacrosByTemplateIdFailure(response.message));
    }    
  } catch (error) {
    yield put(getMacrosByTemplateIdFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default getMacrosByTemplateIdSaga;