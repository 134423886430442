/** Absolute imports */
import { AxiosRequestConfig } from "axios";

/** Services */
import { api } from "./api";

export const login = (
    data: {email: string, password: string},
    options?: AxiosRequestConfig
    ) =>
    api
        .post(`/api/auth/login`, data, options)
        .then((response) => {
            api.defaults.headers['Authorization'] = `Bearer ${response.data.data.token}`;
            return response.data;
        });

export const logout = () => {
    api.defaults.headers['Authorization'] = `Bearer`;
}

export const register = (
    data: {email: string, password: string, userName: string},
    options?: AxiosRequestConfig
    ) =>
    api
        .post(`/api/auth/register`, data, options)
        .then((response) => {
            return response.data;
        });

