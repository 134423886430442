/** Absolurte imports */
import { Reducer } from "redux";

/** Types */
import { AuthState } from "./types";
import {
    AuthActionTypes,
    SET_USER_DATA,
    GET_CAPTCHA_URL_SUCCESS,
    SET_ERROR,
    SET_REGISTER_SUCCESS,
    INITIALIZED_SUCCESS,
    RESET_AUTH,
    SET_USER_NAME_REQUEST,
    SET_USER_PHOTO_REQUEST
} from "./actionTypes";

export const INITIAL_STATE: AuthState = {
    userId: null,
    userName: undefined,
    userPhoto: undefined,
    email: undefined,
    login: undefined,
    isAuth: false,
    isFetching: false,
    captchaUrl: undefined,  // if null then captcha is not required
    token: undefined,
    roles: [],
    error: undefined,
    registerSuccess: false,
    isInitialized: false
}

const reducer: Reducer<AuthState> = (
  state = INITIAL_STATE,
  action: AuthActionTypes
) => {
    switch(action.type) {
        case SET_USER_DATA: {
            return {
                ...state,
                userId: action.payload.userId,
                userName: action.payload.userName,
                userPhoto: action.payload.userPhoto,
                token: action.payload.token,
                roles: action.payload.roles,
                isAuth: action.payload.isAuth
            }
        }
        case GET_CAPTCHA_URL_SUCCESS: {
            return {
                ...state,
                captchaUrl: action.payload.captchaUrl
            }
        }
        case SET_ERROR: {
            return {
                ...state,
                error: action.payload.error
            }
        }
        case SET_REGISTER_SUCCESS: {
            return {
                ...state,
                registerSuccess: action.payload.registerSuccess
            }
        }
        case INITIALIZED_SUCCESS: {
            return { 
                ...state, 
                isInitialized: true
            }
        }
        /** Set User Name */
        case SET_USER_NAME_REQUEST: {
            return {
                ...state,
                userName: action.payload.userName
            }
        }
        /** Set User Photo */
        case SET_USER_PHOTO_REQUEST: {
            return {
                ...state,
                userPhoto: action.payload.userPhoto
            }
        }
        case RESET_AUTH: {
            return { 
                ...state, 
                isAuth: action.payload.isAuth
            }
        }
        default:
            return state;
    }
};

export default reducer;