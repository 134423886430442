/** Absolute imports */
import { takeLatest } from "redux-saga/effects";

/** Types */
import { 
  DELETE_HOST_REQUEST, 
  GET_HOSTS_REQUEST, 
  GET_HOST_REQUEST, 
  SAVE_HOST_REQUEST, 
  SET_HOST_STATUS_REQUEST} from "./actionTypes";

/** Sagas */
import saveHostSaga from "./sagas/saveHostSaga";
import deleteHostSaga from "./sagas/deleteHostSaga";
import getHostsSaga from "./sagas/getHostsSaga";
import getHostSaga from "./sagas/getHostSaga";
import setHostStatusSaga from "./sagas/setHostStatusSaga";


function* watchProfileSagas() {
  yield takeLatest(SAVE_HOST_REQUEST, saveHostSaga);
  yield takeLatest(DELETE_HOST_REQUEST, deleteHostSaga);
  yield takeLatest(GET_HOSTS_REQUEST, getHostsSaga);
  yield takeLatest(GET_HOST_REQUEST, getHostSaga);
  yield takeLatest(SET_HOST_STATUS_REQUEST, setHostStatusSaga);
}

export default watchProfileSagas;