/** Absolute imports */
import { call, put, cancelled, select } from "redux-saga/effects";
import * as selectors from '../../auth/selectors';

/** Redux */
import { SaveProfileRequest } from "../actionTypes";
import {
    getUserProfileRequest,
    setError,
} from "../actions";

/** Services */
import { saveProfile } from "../../../services/profileService";
import { getLocalStorage, storageName } from "../../../services/api";

/** Types */
import { SaveProfileResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";

/** Actions */
import { setUserNameRequest } from "../../auth/actions";


function* saveProfileSaga({
  payload: {profile},
}: SaveProfileRequest) {
  const { cancelToken, source } = createCancelToken();

  try {
    yield put(setError(undefined));
    const response: SaveProfileResponseDataType = yield call(
      saveProfile,
      profile,
      { cancelToken }
    );
    if(response && response.resultCode === ResultCode.Success) {
        const userId: string | null = yield select(selectors.makeGetUserId());
        const userName: string | undefined = yield select(selectors.makeGetUserName());
        if (userId) {
            yield put(getUserProfileRequest(userId));
        } else {
            yield put(setError("UserId can't be null"));
        }
        if (userName !== profile.fullName) {
          yield put(setUserNameRequest(profile.fullName));
          const data = getLocalStorage();
          localStorage.setItem(storageName, JSON.stringify({ ...data, userName: profile.fullName }));
        }
    }
    if(response && response.resultCode === ResultCode.Error) {
        yield put(setError(response.message));
    }
  } catch (error) {
      console.log(error);
    yield put(setError(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default saveProfileSaga;
