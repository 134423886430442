/** Absolute imports */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/** Ant designe */
import { Button, Space, Upload, Divider, Modal, message } from 'antd';
import { UploadOutlined, EditOutlined } from '@ant-design/icons';

/** Components */
import { ImageAvatar } from './image-avatar';
import { ProfileData } from './profile-data';
import Preloader from '../../../components/Preloader/preloader';
import { ProfileDataForm } from './profile-data-form';

/** Types */
import { ProfileType } from '../../../store/profile/types';
import { ProfileInfoPropsType } from '../types';

/** Styles */
import s from './profile-info.module.scss';
import './profile-upload.scss';


const ProfileInfo: React.FC<ProfileInfoPropsType> = React.memo((props) => {

    const [editMode, setEditMode] = useState(false);

    const onMainPhotoSelected = useCallback((e) => {
        if(e.fileList && e.fileList.length) {
            props.savePhoto(e.fileList[0].originFileObj);
        }
    },[]);

    const sendCorrectedMainPhoto = useCallback((e) => {
        var fileData = e,
        parts, type, base64Data;

        parts = fileData.split(',');
        type = parts[0];
        base64Data = parts[1];

        type = type.split(';')[0].split(':')[1];

        let blobImage = b64toBlob(base64Data, type);
        props.savePhoto(blobImage);
    },[]);

    const b64toBlob = (b64Data: string, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
      
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
      }

    const onSubmit = (formData: ProfileType) => {
        props.saveProfile(formData) //.then(() => {
        //    setEditMode(false);
        //});
    }

    const onCancel = () => {
        setEditMode(false);
    }

    if (!props.profile) {
        return <Preloader />
    }

    return (
        <div className={s.userInfo}>
            <div style={{display: 'flex'}}>
                <ImageAvatar profile={props.profile} isOwner={props.isOwner} onMainPhotoSelected={onMainPhotoSelected} sendCorrectedMainPhoto={sendCorrectedMainPhoto} />
                <div className={s.nameAndStatusSmall}>
                    <div className={s.userName}>{props.profile.fullName}</div>
                </div>
            </div>
            <div className={s.nameAndStatus}>
                <div className={s.nameAndStatusBig}>
                    <div className={s.userName}>{props.profile.fullName}</div>
                </div>
                { editMode ? <ProfileDataForm onSubmit={onSubmit} onCancel={onCancel} initialValues={props.profile} /> 
                    : <ProfileData profile={props.profile} isOwner={props.isOwner} goToEditMode={() => {setEditMode(true)}} /> }   
            </div>
        </div>
        
    );
});


export default ProfileInfo;