export interface DeviceSettingsState {
    error: string | undefined;
    status: Status;
    types: Array<Type>;
    macroValue: Array<MacroValue>;
    macros: Array<Macro>;
    type: Type | undefined;
    macro: Macro | undefined;
    template: Template | undefined;
    templates: Array<Template>;
    isLoading: boolean;
    servers: Array<Server>;
    server: Server | undefined;
}

export enum Status {
    pending, 
    ready,
    error,
    off
}

export interface MacroValue {
    id: string;
    name: string;
    value: string;
}

export interface Template {
    id: string;
    name: string;
    templateid: string;
    description: string;
    macros: Array<MacroValue>;
}

export interface Macro {
    id: string;
    name: string;
    typeId: string;
}

export interface Server {
    id: string;
    name: string;
    address: string;
    login: string;
    password: string;
    groupid: string;
}

export interface Type {
    id: string;
    name: string;
}

export interface GetTypesResponseDataType {
    resultCode: number;
    message: string;
    data: {
        types: Array<Type>;
    }
}

export interface GetTypeResponseDataType {
    resultCode: number;
    message: string;
    data: {
        type: Type;
    }
}

export interface GetServersResponseDataType {
    resultCode: number;
    message: string;
    data: {
        servers: Array<Server>;
    }
}

export interface GetServerResponseDataType {
    resultCode: number;
    message: string;
    data: {
        server: Server;
    }
}


export interface GetMacrosResponseDataType {
    resultCode: number;
    message: string;
    data: {
        macros: Array<Macro>;
    }
}

export interface GetTemplatesResponseDataType {
    resultCode: number;
    message: string;
    data: {
        templates: Array<Template>;
    }
}

export interface GetMacroResponseDataType {
    resultCode: number;
    message: string;
    data: {
        macro: Macro;
    }
}

export interface GetTemplateResponseDataType {
    resultCode: number;
    message: string;
    data: {
        template: Template;
    }
}

export interface SaveMacroResponseDataType {
    resultCode: number;
    message: string;
    data: {
        macro: Macro;
    }  
}

export interface SaveTypeResponseDataType {
    resultCode: number;
    message: string;
    data: {
        type: Type;
    }      
}

export interface SaveServerResponseDataType {
    resultCode: number;
    message: string;
    data: {
        server: Server;
    }   
}

export interface SaveTemplateResponseDataType {
    resultCode: number;
    message: string;
    data: {
        template: Template;
    }      
}

export interface DeleteResponseDataType {
    resultCode: number;
    message: string;
}


