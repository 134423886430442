/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { GetHostRequest } from "../actionTypes";
import {
  getHostFailure,
  getHostSuccess,
} from "../actions";

/** Services */
import { getHost } from "../../../services/hostService";

/** Types */
import { GetHostResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* getHostSaga({
  payload: {hostId},
}: GetHostRequest) {
  const { source } = createCancelToken();

  try {
    const response: GetHostResponseDataType = yield call(
      getHost,
      hostId
    );
    if(response && response.resultCode === ResultCode.Success && response.data) {
      yield put(getHostSuccess(response.data.host));
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(getHostFailure(response.message));
    }    
  } catch (error) {
    yield put(getHostFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default getHostSaga;