/** Absolute imports */
import React from 'react';

/** Ant designe */
import { Divider } from 'antd';
import { EditOutlined } from '@ant-design/icons';

/** Styles */
import s from './profile-info.module.scss';

/** Components */
import { Contact } from './contact';

/** Types */
import { ProfileDataType } from '../types';


export const ProfileData: React.FC<ProfileDataType> = ({profile, isOwner, goToEditMode}) => {

    return (
        <div>
        {isOwner && <div>
            <Divider plain>
            <EditOutlined onClick={goToEditMode} />
            </Divider>
            </div>}
        <div>
            {profile.contacts.emails && profile.contacts.emails.length > 0 && <>
                <Divider plain>
                    Emails:
                </Divider>
                                 
                {profile.contacts.emails.map(item => {
                    return <Contact key={item.email} contactTitle={item.email} />
                })}
                </>
            }
        </div>
        <div>
            {profile.contacts.phones && profile.contacts.phones.length > 0 && <>
                <Divider plain>
                    Phones:
                </Divider>
            
                {profile.contacts.phones.map(item => {
                    return <Contact key={item.phone} contactTitle={item.phone} />
                })}
                </>
            }
        </div>
    </div>
    );
}
