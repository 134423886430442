export enum routeNames {
    login = "/login",
    register = "/register",
    dashboard = "/dashboard",
    addNewDevice = "/add_new_device",
    editDevice = "/edit_device/:deviceId",
    profile = "/profile/:userId?",
    types = "/types",
    macros = "/macros",
    templates = "/templates",
    users = "/access/users",
    roles = "/access/roles",
    mainSettings = "/access/main_settings",
    default = "/"
  }
  