/** Absolute imports */
import { createSelector } from "reselect";

/** Redux */
import { INITIAL_STATE } from "../auth/reducer";

/** Types */
import { ApplicationState } from "..";
import { AuthState } from "./types";

const selectUser = (state: ApplicationState): AuthState =>
  state.auth || INITIAL_STATE;

export const makeGetError = () => 
    createSelector(selectUser, (authState) => authState.error);

export const makeGetUserId = () =>
  createSelector(selectUser, (authState) => authState.userId);

export const makeGetUserName = () =>
  createSelector(selectUser, (authState) => authState.userName);

export const makeGetUserPhoto = () =>
  createSelector(selectUser, (authState) => authState.userPhoto);

export const makeGetRoles = () =>
  createSelector(selectUser, (authState) => authState.roles);

export const makeGetIsAuth = () =>
  createSelector(selectUser, (authState) => authState.isAuth);

export const makeGetCurrentUserLogin = () => 
    createSelector(selectUser, (authState) => authState.login);

export const makeGetRegisterSuccess = () => 
    createSelector(selectUser, (authState) => authState.registerSuccess);

export const makeGetCaptchaUrl = () => 
  createSelector(selectUser, (authState) => authState.captchaUrl);

export const makeGetIsInitialized = () => 
  createSelector(selectUser, (authState) => authState.isInitialized);

