/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { SaveMacroRequest } from "../actionTypes";
import {
  getMacrosRequest,
    saveMacroFailure,
    saveMacroSuccess,
} from "../actions";

/** Services */
import { saveMacro } from "../../../services/hostSettingsService";

/** Types */
import { SaveMacroResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* saveMacroSaga({
  payload: {macro},
}: SaveMacroRequest) {
  const { cancelToken, source } = createCancelToken();

  try {
    const response: SaveMacroResponseDataType = yield call(
      saveMacro,
      macro,
      { cancelToken }
    );
    if(response && response.resultCode === ResultCode.Success) {
      yield put(saveMacroSuccess());
      yield put(getMacrosRequest());
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(saveMacroFailure(response.message));
    }    
  } catch (error) {
    yield put(saveMacroFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default saveMacroSaga;