/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { DeleteServerRequest } from "../actionTypes";
import {
    deleteServerFailure,
    deleteServerSuccess,
    getServersRequest,
} from "../actions";

/** Services */
import { deleteServer } from "../../../services/hostSettingsService";

/** Types */
import { DeleteResponseDataType } from "../../device/types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* deleteServerSaga({
  payload: { serverId },
}: DeleteServerRequest) {
  const { cancelToken, source } = createCancelToken();

  try {
    const response: DeleteResponseDataType = yield call(
      deleteServer,
      serverId
    );
    if(response && response.resultCode === ResultCode.Success) {
      yield put(deleteServerSuccess());
      yield put(getServersRequest());
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(deleteServerFailure(response.message));
    }    
  } catch (error) {
    yield put(deleteServerFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default deleteServerSaga;