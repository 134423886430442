/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { GetUserProfileRequest } from "../actionTypes";
import {
    setError,
    setUserProfile,
} from "../actions";

/** Services */
import { getProfile } from "../../../services/profileService";

/** Types */
import { ProfileType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";
import { APIResponseType } from "../../../services/types";

/** Enum */
import { ResultCode } from "../../../services/types";


function* getUserProfileSaga({
  payload: {userId},
}: GetUserProfileRequest) {
  const { source } = createCancelToken();

  try {
    if(userId) {
        yield put(setError(undefined));
        const response: APIResponseType<ProfileType> = yield call(
           getProfile,
           userId
        );
        if(response && response.resultCode === ResultCode.Success && response.data) {
            yield put(setUserProfile(response.data));
        } 
        if(response && response.resultCode === ResultCode.Error) {
            yield put(setError(response.message));
        }  
    }
  } catch (error) {
    yield put(setError(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default getUserProfileSaga;