/** Absolute imports */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from 'react-router';
import { createStructuredSelector } from 'reselect';

/** Components */
import { Profile } from './profile';

/** Store */
import { ApplicationState } from '../../store';
import { getUserProfileRequest, savePhotoRequest, saveProfileRequest } from '../../store/profile/actions';

/** Types */
import { ProfileContainereSelectors } from './types';

/** Selectors */
import { makeGetUserId } from '../../store/auth/selectors';
import { makeGetProfile } from '../../store/profile/selectors';
import Preloader from '../../components/Preloader/preloader';


type PathParamsType = {
    userId: string
}

export const ProfileContainer: React.FC<RouteComponentProps<PathParamsType>> = (props) => { 

    const dispatch = useDispatch();
    const [userId, setUserId] = useState('');

    const selector = createStructuredSelector<
        ApplicationState,
        ProfileContainereSelectors
    >({
        profile: makeGetProfile(),
        autorizedUserId: makeGetUserId()
    });

    const {
       profile,
       autorizedUserId
    } = useSelector(selector);

    const refreshProfile = () => {
        let userId: string | null = props.match.params.userId;
        if(!userId) {
            userId = autorizedUserId;
            if(!userId) {
                props.history.push("/login")
            }
        }             
        dispatch(getUserProfileRequest(userId));        
    }

    const savePhoto = (file: File) => {
        console.log("test");
        dispatch(savePhotoRequest(file));
    }

    useEffect(() => {
        setUserId(props.match.params.userId);
        refreshProfile();
    },[]);

    useEffect(() => {
        if(props.match.params.userId != userId)
           refreshProfile();
    },[props.match.params.userId]);

    if (!profile) {
        return <Preloader />
    }
      
    return (
       <Profile { ...props}  profile={profile} isOwner={!props.match.params.userId} 
                savePhoto={savePhoto} saveProfile={saveProfileRequest} />
    );
}


