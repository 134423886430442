/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { ChangeUserRolesRequest } from "../actionTypes";
import {
    changeUserRolesFailure,
    changeUserRolesSuccess,
    getUsersRequest,
} from "../actions";

/** Services */
import { changeUserRoles } from "../../../services/accessService";

/** Types */
import { SaveResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* changeUserRolesSaga({
  payload: {roles, userId},
}: ChangeUserRolesRequest) {
  const { cancelToken, source } = createCancelToken();

  try {
    yield put(changeUserRolesFailure(undefined));
    const response: SaveResponseDataType = yield call(
        changeUserRoles,
      {roles, userId},
      { cancelToken }
    );
    if(response && response.resultCode === ResultCode.Success) {
      yield put(changeUserRolesSuccess());
      yield put(getUsersRequest());
    } 
    if(response && response.resultCode === ResultCode.Error) {
      console.log(response.message);
        yield put(changeUserRolesFailure(response.message));
        yield put(getUsersRequest());
    }    
  } catch (error) {
    yield put(changeUserRolesFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default changeUserRolesSaga;