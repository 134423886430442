/** Absolute imports */
import React from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

/** Components */
import Preloader from '../../components/Preloader/preloader';
import { ApplicationState } from '../../store';
import { makeGetUserId } from '../../store/auth/selectors';
import ProfileInfo from './ProfileInfo/profile-info';

/** Types */
import { ProfilePropsType } from './types';

interface ProfileSelectors {
    userId: string | null;
}

export const Profile: React.FC<ProfilePropsType> = (props) => {

    const selector = createStructuredSelector<
    ApplicationState,
    ProfileSelectors
    >({
        userId: makeGetUserId(),
    });
    const {
        userId
    } = useSelector(selector);

        return (
            <div className='profile'>
                <ProfileInfo profile={props.profile} isOwner={props.isOwner} savePhoto={props.savePhoto} saveProfile={props.saveProfile} />
            </div>
        );
}