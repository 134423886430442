/** Absolute imports */
import axios from "axios";
import { AnyAction, Dispatch } from "redux";
import { LocationChangeAction } from "connected-react-router";
import { push } from "connected-react-router";

export const storageName = "userData_Monitoring";

const getLocalStorageToken = () => {
    const stored = localStorage.getItem(storageName);
    if (stored !== null) { 
      return JSON.parse(stored).token;
    }
    return "";
}

export const getLocalStorage = () => {
    const stored = localStorage.getItem(storageName);
    if (stored !== null) { 
      return JSON.parse(stored);
    }
    return undefined;
}

export const api = axios.create({
        baseURL: 'http://camdozor.ru:3000/',
        headers:  {
            'Authorization': `Bearer ${getLocalStorageToken()}`
        }
    });

export const configureInterceptors = (
    dispatch: Dispatch<AnyAction | LocationChangeAction<unknown>>
    ) => {
    api.interceptors.response.use(
        (response) => {
        return response;
        },
        (error) => {
        if (error.response.status === 401) {
            dispatch(push("/login"));
            window.location.reload();
        }
        return error;
        }
    );
    };
