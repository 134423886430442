/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { DeleteMacroRequest } from "../actionTypes";
import {
    deleteMacroFailure,
    deleteMacroSuccess,
    getMacrosRequest,
} from "../actions";

/** Services */
import { deleteMacro } from "../../../services/hostSettingsService";

/** Types */
import { DeleteResponseDataType } from "../../device/types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* deleteMacroSaga({
  payload: { macroId },
}: DeleteMacroRequest) {
  const { cancelToken, source } = createCancelToken();

  try {
    const response: DeleteResponseDataType = yield call(
      deleteMacro,
      macroId
    );
    if(response && response.resultCode === ResultCode.Success) {
      yield put(deleteMacroSuccess());
      yield put(getMacrosRequest());
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(deleteMacroFailure(response.message));
    }    
  } catch (error) {
    yield put(deleteMacroFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default deleteMacroSaga;