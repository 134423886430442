/** Absolute imports */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

/** Ant design */
import { Button, Tabs, Modal, Alert } from 'antd';
import { AppleOutlined, AndroidOutlined } from '@ant-design/icons';

/** Components */
import Preloader from '../../../components/Preloader/preloader';
import { TemplatesPage } from './templates-page';
import { MacrosPage } from './macros-page';
import { TypesPage } from './types-page';

/** Types */
import { MainSettingsPageSelectors, TemplatesPageSelectors } from './types';

/** Store */
import { ApplicationState } from '../../../store';
import { makeGetError } from '../../../store/deviceSettings/selectors';

/** Styles */
import s from './styles.module.scss';
import { makeGetIsAuth } from '../../../store/auth/selectors';
import { ServersPage } from './servers-page';

const { TabPane } = Tabs;

export const MainSettingsPage: React.FC = () => {
    
    const dispatch = useDispatch();
    const selector = createStructuredSelector<
    ApplicationState,
    MainSettingsPageSelectors
    >({
        isAuth: makeGetIsAuth(),
        error: makeGetError(),
    });

    const {
        error,
        isAuth,
    } = useSelector(selector);


    if (!isAuth) {
        return  <Redirect to={"/login"} />
    }

    return (
    <div className={s.mainSettings}>
        {/*error && 
            <Alert
                message={error}
                type="error"
                showIcon
            />
        */}
 <Tabs defaultActiveKey="1">
    <TabPane
      tab={
        <span>
          <AppleOutlined />
          Templates
        </span>
      }
      key="1"
    >
      <TemplatesPage />
    </TabPane>
    <TabPane
      tab={
        <span>
          <AndroidOutlined />
          Servers
        </span>
      }
      key="2"
    >
      <ServersPage />
    </TabPane>
    <TabPane
      tab={
        <span>
          <AndroidOutlined />
          Macros
        </span>
      }
      key="3"
    >
      <MacrosPage />
    </TabPane>
    <TabPane
      tab={
        <span>
          <AndroidOutlined />
          Types
        </span>
      }
      key="4"
    >
      <TypesPage />
    </TabPane>
  </Tabs>
    </div>
        );
}