/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { GetUserRequest } from "../actionTypes";
import {
    getUserFailure,
  getUserSuccess,
} from "../actions";

/** Services */
import { getUser } from "../../../services/accessService";

/** Types */
import { GetUserResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* getUserSaga({
  payload: {userId},
}: GetUserRequest) {
  const { cancelToken, source } = createCancelToken();

  try {
    const response: GetUserResponseDataType = yield call(
      getUser,
      userId,
      { cancelToken }
    );
    if(response && response.resultCode === ResultCode.Success && response.data) {
      yield put(getUserSuccess(response.data.user));
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(getUserFailure(response.message));
    }    
  } catch (error) {
    yield put(getUserFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default getUserSaga;