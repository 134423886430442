/** Absolute imports */
import React, { useEffect, useState } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import classNames from 'classnames';

/** Controls */
import { createField } from '../../../components/FormsControls/forms-controls';

/** Style */
import s from './profile-info.module.scss';

/** Ant designe */
import { Button, Divider, Form, Input, Select, Space, Checkbox } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

/** Types */
import { ProfileType, ProfileTypeForm } from '../../../store/profile/types';
import { useDispatch } from 'react-redux';
import { saveProfileRequest } from '../../../store/profile/actions';


type OwnPropsType = {
    initialValues: ProfileType,
    onCancel: () => void,
    onSubmit: (values: ProfileType) => void
}

type ProfileTypeKeys = Extract<keyof ProfileType, string>;

export const ProfileDataForm: React.FC<OwnPropsType> = ({onSubmit, initialValues, onCancel}) => {

    const [form] = Form.useForm();
    
    const dispatch = useDispatch();

    const onFinish = (values: ProfileTypeForm) => {
        console.log('Success:', values);
        dispatch(saveProfileRequest({
            ...initialValues,
            fullName: values.fullName, 
            contacts: { 
                emails: values.emails,
                phones: values.phones
            }}));
        onCancel();
    };
    
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    console.log(initialValues);
    return (
        <Form
            form={form}
            initialValues={{...initialValues, emails: initialValues.contacts.emails, phones: initialValues.contacts.phones }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className={s.formTemplateEdit}
        >
        <div>
            <Divider plain>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
                <Button onClick={onCancel}>Cancel</Button>
            </Divider>
        </div>
      {/*}  {error && <div className="formSummaryError">
                    {error}
    </div>}*/}
        <Form.Item
                label="Full name"
                name="fullName"
                rules={[{ required: true, message: 'Please input a fullName!' }]}
            >
                <Input /> 
        </Form.Item>
        <Form.Item
                label="Emails"   
                >
                <Form.List name="emails">
                {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Form.Item
                            {...restField}
                            name={[name, 'email']}
                            fieldKey={[fieldKey, 'email']}
                            rules={[{ required: true, message: 'Missing Email' }]}
                        >
                            <Input />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                    ))}
                    <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add Email
                    </Button>
                    </Form.Item>
                </>
                )}
            </Form.List>
        </Form.Item>
        <Form.Item
                label="Phones"   
                >
                <Form.List name="phones">
                {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Form.Item
                            {...restField}
                            name={[name, 'phone']}
                            fieldKey={[fieldKey, 'phone']}
                            rules={[{ required: true, message: 'Missing Email' }]}
                        >
                            <Input />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                    ))}
                    <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add Phone
                    </Button>
                    </Form.Item>
                </>
                )}
            </Form.List>
        </Form.Item>
        {/*<table className={classNames(s.tableUserInfo, s.textRight)}>
            <tbody>
            <tr>
                <td className={s.columnName} >Full name:</td>
                <td>{createField<ProfileTypeKeys>("Full name", "fullName", [], Input)}</td>
            </tr>
            </tbody>
        </table>*/}

       {/*} <div>
            <Divider plain>Contacts</Divider>
            <table className={classNames(s.tableUserInfo, s.textRight)}>
                <tbody>
                {Object.keys(initialValues.contacts).map(key => {
                    return (
                    <tr key={key} className={s.contact}>
                        <td className={s.columnName}>{key}:</td> 
                        <td>{createField(key, "contacts." + key, [], Input)}</td>
                    </tr>)
                })}
                </tbody>
            </table>
            </div>*/}
    </Form>
    );
}
