/** Absolute imports */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';

/** Ant design */
import { Button, Input, Form, Modal, Space, FormInstance } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

/** Components */

/** Store */
import { ApplicationState } from '../../../../store';
import { makeGetError } from '../../../../store/access/selectors';
import { getRoleRequest, saveRoleRequest } from '../../../../store/access/actions';

/** Types */
import { RoleEditProps, RoleEditSelectors } from '../types';
import { Role } from '../../../../store/access/types';

/** Styles */
import s from '../styles.module.scss';
import { useResetFormOnCloseModal } from '../../../../hooks/useResetFormOnCloseModal';


export const RoleEditModal: React.FC<RoleEditProps> = ({visible, onCancel, initialValues}) => {

    const selector = createStructuredSelector<
    ApplicationState,
    RoleEditSelectors
    >({
        error: makeGetError()
    });

    const {
        error
    } = useSelector(selector);

    const dispatch = useDispatch();
    /*const { roleId } = useParams<{ roleId: string }>();*/
    const [form] = Form.useForm();

    useResetFormOnCloseModal({
        form,
        visible,
      });

  /*  useEffect(() => {
        if (roleId)
            dispatch(getRoleRequest(roleId));
    }, [roleId]);*/

    const onFinish = (values: Role) => {
        console.log('Success:', values);
        dispatch(saveRoleRequest(values));
        onCancel();
      };
    
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleOk = () => {
        form.submit();
    };
    console.log(initialValues);

    return (
        <Modal title={initialValues.id ? "Edit role" : "Create role"} visible={visible} onOk={handleOk} onCancel={onCancel}>

        <div className={s.templateEdit}>
        <Form
            form={form}
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className={s.formTemplateEdit}
        >
            <Form.Item
                name="id"
                hidden={true} 
            >
                <Input /> 
            </Form.Item>
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input a name!' }]}
            >
                <Input /> 
            </Form.Item>
    </Form>
    </div>
    </Modal>
    );
}


