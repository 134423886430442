/** Absolute imports */
import { createStore, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { History } from "history";
import { routerMiddleware } from "connected-react-router";

/** Redux */
import createReducer from "./rootReducer";
import rootSaga from "./rootSagas";

/** Services */
import { configureInterceptors } from "../services/api";

/** Types */
import { AuthState } from "./auth/types";
import { ProfileState } from "./profile/types";
import { HostState } from "./device/types";
import { DeviceSettingsState } from "./deviceSettings/types";
import { AccessState } from "./access/types";


export interface ApplicationState {
  auth: AuthState;
  profile: ProfileState;
  device: HostState;
  deviceSettings: DeviceSettingsState;
  access: AccessState;
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = createReducer();

const configureStore = (initialState = {}, history: History) => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware, routerMiddleware(history)];
  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(...enhancers)
  );

  const { dispatch } = store;

  configureInterceptors(dispatch);

  // @ts-ignore
  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
