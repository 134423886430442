/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { GetServerRequest, GetTypeRequest } from "../actionTypes";
import {
    getServerFailure,
    getServerSuccess,
} from "../actions";

/** Services */
import { getServer } from "../../../services/hostSettingsService";

/** Types */
import { GetServerResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* getServerSaga({
  payload: {serverId},
}: GetServerRequest) {
  const { source } = createCancelToken();

  try {
    const response: GetServerResponseDataType = yield call(
      getServer,
      serverId
    );
    if(response && response.resultCode === ResultCode.Success && response.data) {
      yield put(getServerSuccess(response.data.server));
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(getServerFailure(response.message));
    }    
  } catch (error) {
    yield put(getServerFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default getServerSaga;