import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Redirect } from 'react-router-dom';
import { Form, Input, Button, Divider, Alert } from 'antd';
import style from './login.module.scss';
import { createStructuredSelector } from 'reselect';
import { ApplicationState } from '../../store';
import { RegisterPageSelectors, RegisterSelectors } from './types';
import { makeGetError, makeGetIsAuth, makeGetRegisterSuccess } from '../../store/auth/selectors';
import { registerRequest } from '../../store/auth/actions';

export const RegisterPage: React.FC = () => {

  const selector = createStructuredSelector<
    ApplicationState,
    RegisterPageSelectors
    >({
      isAuth: makeGetIsAuth()
    });

  const {
    isAuth,
  } = useSelector(selector);


    if(isAuth) {
        return <Redirect to={"/profile"} />
    }

    return (
        <div className={style.loginForm}>
            <h1>Register</h1>    
            <Divider />        
            <RegisterReduxForm />
        </div>
    );
}

type RegisterFormValuesType = {
    email: string
    password: string,
    userName: string
}

const Register: React.FC = () => {

    const dispatch = useDispatch();

    const selector = createStructuredSelector<
    ApplicationState,
    RegisterSelectors
  >({
    error: makeGetError(),
    registerSuccess: makeGetRegisterSuccess()
  });

  const {
    error,
    registerSuccess
  } = useSelector(selector);
    
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
      };

    const onFinish = (formData: RegisterFormValuesType) => {
        console.log('Success:', formData);
        dispatch(registerRequest(formData.email, formData.password, formData.userName));
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {registerSuccess && 
            <Alert
            message="Success"
            description="Thanks for registration. User was added! Now you can login using your email and password."
            type="success"
            showIcon
          />
        }
        {error && 
            <Alert
                message={error}
                type="error"
                showIcon
            />
        }
        {!registerSuccess && 
        <div style={{marginTop: '20px'}}>
        <Form.Item
          label="Name"
          name="userName"
          rules={[{ required: true, message: 'Please input your name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input />
        </Form.Item>
  
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>
  
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        </div>}
      </Form>
    );
}

const RegisterReduxForm = reduxForm<RegisterFormValuesType>({
    form: 'registerMessanger'
})(Register);

