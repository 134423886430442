import React, {useContext, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router-dom';

/** Ant design */
import { Alert, Button, Form, Input, Modal, Space, Select, Switch } from 'antd';
import { FormInstance } from 'antd/lib/form';

/** Store */
import { ApplicationState } from '../../../store';
import { makeGetIsAuth } from '../../../store/auth/selectors';
import { addHostRequest } from '../../../store/device/actions';
import { getMacrosByTemplateIdRequest, getTemplatesRequest, resetMacrosRequest } from '../../../store/deviceSettings/actions';
import { Host } from '../../../store/device/types';
import { makeGetError, makeGetIsHostSaved } from '../../../store/device/selectors';
import { makeGetMacros, makeGetTemplates } from '../../../store/deviceSettings/selectors';

/** Types */
import { AddNewDeviceSelectors } from './type';

/** Styles */
import style from './add-new-device.module.scss';



export const AddNewDevice: React.FC = () => { 
    const selector = createStructuredSelector<
    ApplicationState,
    AddNewDeviceSelectors
    >({
        isAuth: makeGetIsAuth(),
        error: makeGetError(),
        templates: makeGetTemplates(),
        macrosList: makeGetMacros(),
        isHostSaved: makeGetIsHostSaved()
    });
    const dispatch = useDispatch();
    const {
        isAuth,
        error, 
        templates,
        macrosList,
        isHostSaved
    } = useSelector(selector);

    const formRef = React.createRef<FormInstance>();
    const [checkedIpOrDNS, setCheckedIpOrDNS] = useState(true);

    useEffect(() => {
      dispatch(getTemplatesRequest());
    },[]);

    useEffect(() => {
      return () => {
        dispatch(resetMacrosRequest());
      } 
    },[]);

    useEffect(() => {
      formRef.current!.setFieldsValue({ macros: macrosList }); 
    }, [macrosList])

    useEffect(() => {
      if(isHostSaved) {
        Modal.success({
          content: 'Device was added successfully.',
        });
      }
    }, [isHostSaved]);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
      };

    const onTemplateHandler = (id: string) => {
      console.log(`selected ${id}`);
      dispatch(getMacrosByTemplateIdRequest(id));
    }

    const onFinish = (formData: Host) => {
        dispatch(addHostRequest(formData));
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onClickIpOrDNS = (checked: boolean, event: Event) => {
      formRef.current!.setFieldsValue({ useip: checked ? 1 : 0}); 
      setCheckedIpOrDNS(checked);
      if(checked) {
        formRef.current!.setFieldsValue({ dns: ''}); 
      } else {
        formRef.current!.setFieldsValue({ ip: ''}); 
      }
    }

    if (isHostSaved) {
      return  <Redirect to={"/dashboard"} />
    }

    if (!isAuth) {
        return  <Redirect to={"/login"} />
    }

    return (
        <Form
        {...layout}
        ref={formRef}
        name="basic"
        initialValues={{useip: 1}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {error && 
            <Alert
                message={error}
                type="error"
                showIcon
            />
        }
        <div style={{marginTop: '20px', marginRight: '20px'}}>
        <Form.Item
          name="id"
        >
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          label="Name of device"
          name="name"
          rules={[{ required: true, message: 'Please input a name of a device!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: 'Please input a description of a device!' }]}
        >
          <Input />
        </Form.Item>

        {/*<Form.Item {...tailLayout} name="status" valuePropName="checked">
          <Checkbox >Status</Checkbox>
        </Form.Item>*/}

        <Form.Item
          label="IP or DNS"
          name="useip"
          rules={[{ required: true, message: 'Please input a Useip of a device!' }]}
        >
          <Switch checkedChildren="IP" unCheckedChildren="DNS" defaultChecked onChange={onClickIpOrDNS} />
        </Form.Item>
        {checkedIpOrDNS ?
          <>
          <Form.Item
            label="Ip of device"
            name="ip"          
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="dns" 
            style={{height: 0, margin: 0}}         
          >
          </Form.Item>
          </>
        :
          <>
          <Form.Item
            name="ip"
            style={{height: 0, margin: 0}}           
          >
          </Form.Item>
          <Form.Item
            label="DNS of device"
            name="dns"          
          >
            <Input />
          </Form.Item>
          </>
        }
        <Form.Item
          label="Port of device"
          name="port"
          rules={[{ required: true, message: 'Please input a port of a device!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="TemplateId of device"
          name="templateId"
          rules={[{ required: true, message: 'Please input a templateId of a device!' }]}
        >
          <Select style={{minWidth: 200}} onChange={onTemplateHandler}>
              {templates.map(template => {
              return (
                  <Select.Option key={template.id} value={template.id}>{template.name}</Select.Option>
              );
              })}
          </Select>
        </Form.Item>

        <Form.Item
          label="Macros"
        >
       <Form.List name="macros">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                <Form.Item
                  {...restField}
                  name={[name, 'id']}
                  fieldKey={[fieldKey, 'id']}
                  style={{height: 0, margin: 0}}  
                  rules={[{ required: true, message: 'Missing first name' }]}
                >
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'name']}
                  fieldKey={[fieldKey, 'name']}
                  rules={[{ required: true, message: 'Missing first name' }]}
                >
                  <Input placeholder="First Name" disabled />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'value']}
                  fieldKey={[fieldKey, 'value']}
                  rules={[{ required: true, message: 'Missing last name' }]}
                >
                  <Input placeholder="Value" />
                </Form.Item>
              </Space>
            ))}
          </>
        )}
      </Form.List>
      </Form.Item>

  
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        </div>
      </Form>
    );
}
