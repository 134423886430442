/** Absolute imports */
import { takeLatest } from "redux-saga/effects";

/** Types */
import { 
  DELETE_MACRO_REQUEST, 
  DELETE_SERVER_REQUEST, 
  DELETE_TEMPLATE_REQUEST, 
  DELETE_TYPE_REQUEST, 
  GET_MACROS_BY_TEMPLATEID_REQUEST, 
  GET_MACROS_REQUEST, 
  GET_MACRO_REQUEST, 
  GET_SERVERS_REQUEST, 
  GET_SERVER_REQUEST, 
  GET_TEMPLATES_REQUEST, 
  GET_TEMPLATE_REQUEST, 
  GET_TYPES_REQUEST, 
  GET_TYPE_REQUEST, 
  SAVE_MACRO_REQUEST, 
  SAVE_SERVER_REQUEST, 
  SAVE_TEMPLATE_REQUEST, 
  SAVE_TYPE_REQUEST } from "./actionTypes";

/** Sagas */
import saveTypeSaga from "../deviceSettings/sagas/saveTypeSaga";
import getTypesSaga from "../deviceSettings/sagas/getTypesSaga";
import deleteTypeSaga from "../deviceSettings/sagas/deleteTypeSaga";
import deleteMacroSaga from "../deviceSettings/sagas/deleteMacroSaga";
import getMacrosSaga from "../deviceSettings/sagas/getMacrosSaga";
import saveMacroSaga from "../deviceSettings/sagas/saveMacroSaga";
import getTemplatesSaga from "./sagas/getTemplatesSaga";
import saveTemplateSaga from "./sagas/saveTemplateSaga";
import deleteTemplateSaga from "./sagas/deleteTemplateSaga";
import getMacrosByTemplateIdSaga from "./sagas/getMacrosByTemplateIdSaga";
import getTemplateSaga from "./sagas/getTemplateSaga";
import getTypeSaga from "./sagas/getTypeSaga";
import getMacroSaga from "./sagas/getMacroSaga";
import getServersSaga from "./sagas/getServersSaga";
import getServerSaga from "./sagas/getServerSaga";
import saveServerSaga from "./sagas/saveServerSaga";
import deleteServerSaga from "./sagas/deleteServerSaga";


function* watchProfileSagas() {
  yield takeLatest(GET_TYPES_REQUEST, getTypesSaga);
  yield takeLatest(GET_TYPE_REQUEST, getTypeSaga);
  yield takeLatest(GET_SERVERS_REQUEST, getServersSaga);
  yield takeLatest(GET_SERVER_REQUEST, getServerSaga);
  yield takeLatest(GET_MACROS_REQUEST, getMacrosSaga);
  yield takeLatest(GET_MACRO_REQUEST, getMacroSaga);
  yield takeLatest(GET_MACROS_BY_TEMPLATEID_REQUEST, getMacrosByTemplateIdSaga);
  yield takeLatest(GET_TEMPLATES_REQUEST, getTemplatesSaga);
  yield takeLatest(GET_TEMPLATE_REQUEST, getTemplateSaga);
  yield takeLatest(SAVE_TYPE_REQUEST, saveTypeSaga); 
  yield takeLatest(SAVE_SERVER_REQUEST, saveServerSaga);   
  yield takeLatest(SAVE_MACRO_REQUEST, saveMacroSaga); 
  yield takeLatest(SAVE_TEMPLATE_REQUEST, saveTemplateSaga); 
  yield takeLatest(DELETE_TYPE_REQUEST, deleteTypeSaga);  
  yield takeLatest(DELETE_SERVER_REQUEST, deleteServerSaga);  
  yield takeLatest(DELETE_MACRO_REQUEST, deleteMacroSaga);  
  yield takeLatest(DELETE_TEMPLATE_REQUEST, deleteTemplateSaga); 
}

export default watchProfileSagas;