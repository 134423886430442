/** Absolute imports */
import { AxiosRequestConfig } from "axios";
import { Macro, Server, Template, Type } from "../store/deviceSettings/types";

/** Services */
import { api } from "./api";


export const getTypes = () => 
    api
        .get(`/api/device-settings/get-types`)
        .then((response) => {
            return response.data;
        });

export const getServers = () => 
api
    .get(`/api/device-settings/get-servers`)
    .then((response) => {
        return response.data;
    });

export const getMacros = () => 
    api
        .get(`/api/device-settings/get-macros`)
        .then((response) => {
            return response.data;
        });

export const getMacrosByTemplateId = (
    templateId: string | undefined
    ) =>
    api
        .get(`/api/device-settings/get-macros-by-templateid/${templateId}`)
        .then((response) => {
            return response.data;
        });

export const getTemplates = () => 
    api
        .get(`/api/device-settings/get-templates`)
        .then((response) => {
            return response.data;
        });

export const saveType = (
    type: Type | undefined,
    options?: AxiosRequestConfig
    ) =>
    api
        .post(`/api/device-settings/save-type`, type, options)
        .then((response) => {
            return response.data;
        });

export const saveServer = (
    server: Server | undefined,
    options?: AxiosRequestConfig
    ) =>
    api
        .post(`/api/device-settings/save-server`, server, options)
        .then((response) => {
            return response.data;
        });

export const saveMacro = (
    macro: Macro | undefined,
    options?: AxiosRequestConfig
    ) =>
    api
        .post(`/api/device-settings/save-macro`, macro, options)
        .then((response) => {
            return response.data;
        });

export const saveTemplate = (
    template: Template | undefined,
    options?: AxiosRequestConfig
    ) =>
    api
        .post(`/api/device-settings/save-template`, template, options)
        .then((response) => {
            return response.data;
        });

export const deleteType = (
    typeId: string | undefined
    ) =>
    api
        .delete(`/api/device-settings/delete-type/${typeId}`)
        .then((response) => {
            return response.data;
        });

export const deleteServer = (
    serverId: string | undefined
    ) =>
    api
        .delete(`/api/device-settings/delete-server/${serverId}`)
        .then((response) => {
            return response.data;
        });

export const deleteMacro = (
    macroId: string | undefined
    ) =>
    api
        .delete(`/api/device-settings/delete-macro/${macroId}`)
        .then((response) => {
            return response.data;
        });

export const deleteTemplate = (
    templateId: string | undefined
    ) =>
    api
        .delete(`/api/device-settings/delete-template/${templateId}`)
        .then((response) => {
            return response.data;
        });

export const getType = (
    typeId: string | undefined
    ) =>
    api
        .get(`/api/device-settings/get-type/${typeId}`)
        .then((response) => {
            return response.data;
        });

export const getServer = (
    serverId: string | undefined
    ) =>
    api
        .get(`/api/device-settings/get-server/${serverId}`)
        .then((response) => {
            return response.data;
        });

export const getMacro = (
    macroId: string | undefined
    ) =>
    api
        .get(`/api/device-settings/get-macro/${macroId}`)
        .then((response) => {
            return response.data;
        });

export const getTemplate = (
    templateId: string | undefined
    ) =>
    api
        .get(`/api/device-settings/get-template/${templateId}`)
        .then((response) => {
            return response.data;
        });