/** Absolurte imports */
import { Reducer } from "redux";

/** Types */
import { AccessState } from "./types";
import {
    AccessActionTypes,
    CHANGE_USER_ROLES_FAILURE,
    CHANGE_USER_ROLES_REQUEST,
    CHANGE_USER_ROLES_SUCCESS,
    DELETE_ROLE_FAILURE,
    DELETE_ROLE_REQUEST,
    DELETE_ROLE_SUCCESS,
    DELETE_USER_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    GET_ROLES_FAILURE,
    GET_ROLES_REQUEST,
    GET_ROLES_SUCCESS,
    GET_ROLE_FAILURE,
    GET_ROLE_REQUEST,
    GET_ROLE_SUCCESS,
    GET_USERS_FAILURE,
    GET_USERS_REQUEST,
    GET_USERS_SUCCESS,
    GET_USER_FAILURE,
    GET_USER_REQUEST,
    GET_USER_ROLES_FAILURE,
    GET_USER_ROLES_REQUEST,
    GET_USER_ROLES_SUCCESS,
    GET_USER_SUCCESS,
    SAVE_ROLE_FAILURE,
    SAVE_ROLE_REQUEST,
    SAVE_ROLE_SUCCESS,
    SAVE_USER_FAILURE,
    SAVE_USER_REQUEST,
    SAVE_USER_SUCCESS,
    SET_ERROR,
} from "./actionTypes";


export const INITIAL_STATE: AccessState = {
    users: [],
    user: undefined,
    roles: [],
    role: undefined,
    userRoles: undefined, //Only roles Id
    error: undefined,
    isLoading: false,
    isSavedUser: false,
    isSavedRole: false,
    isChangedUserRoles: false
}

const reducer: Reducer<AccessState> = (
  state = INITIAL_STATE,
  action: AccessActionTypes
) => {
    switch(action.type) {
        /** Get Users */
        case GET_USERS_REQUEST: {
            return { ...state, isLoading: true };
        }
        case GET_USERS_SUCCESS: {
            return { ...state, 
                users: action.payload.users, 
                isLoading: false };
        }
        case GET_USERS_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Get User */
        case GET_USER_REQUEST: {
            return { ...state, isLoading: true };
        }
        case GET_USER_SUCCESS: {
            return { ...state, 
                user: action.payload.user, 
                isLoading: false };
        }
        case GET_USER_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Save User */
        case SAVE_USER_REQUEST: {
            return { ...state, isLoading: true, isSavedUser: false, error: undefined };
        }
        case SAVE_USER_SUCCESS: {
            return { ...state, 
                isSavedUser: true,
                isLoading: false };
        }
        case SAVE_USER_FAILURE: {
            return { ...state, 
                isLoading: false, 
                isSavedUser: false,
                error: action.payload.error };
        }
        /** Delete User */
        case DELETE_USER_REQUEST: {
            return { ...state, isLoading: true, error: undefined };
        }
        case DELETE_USER_SUCCESS: {
            return { ...state, 
                isLoading: false };
        }
        case DELETE_USER_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Get Roles */
        case GET_ROLES_REQUEST: {
            return { ...state, isLoading: true, error: undefined };
        }
        case GET_ROLES_SUCCESS: {
            return { ...state, 
                roles: action.payload.roles, 
                isLoading: false };
        }
        case GET_ROLES_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Get Role */
        case GET_ROLE_REQUEST: {
            return { ...state, isLoading: true, error: undefined };
        }
        case GET_ROLE_SUCCESS: {
            return { ...state, 
                role: action.payload.role, 
                isLoading: false };
        }
        case GET_ROLE_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Save Role */
        case SAVE_ROLE_REQUEST: {
            return { ...state, isLoading: true, isSavedRole: false, error: undefined };
        }
        case SAVE_ROLE_SUCCESS: {
            return { ...state, 
                isSavedRole: true,
                isLoading: false };
        }
        case SAVE_ROLE_FAILURE: {
            return { ...state, 
                isLoading: false, 
                isSavedRole: false,
                error: action.payload.error };
        }
        /** Delete Role */
        case DELETE_ROLE_REQUEST: {
            return { ...state, isLoading: true, error: undefined };
        }
        case DELETE_ROLE_SUCCESS: {
            return { ...state, 
                isLoading: false };
        }
        case DELETE_ROLE_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** CHANGE USER ROLES */
        case CHANGE_USER_ROLES_REQUEST: {
            return { ...state, isLoading: true, isChangedUserRoles: false, error: undefined };
        }
        case CHANGE_USER_ROLES_SUCCESS: {
            return { ...state,
                isChangedUserRoles: true, 
                isLoading: false };
        }
        case CHANGE_USER_ROLES_FAILURE: {
            return { ...state, 
                isLoading: false, 
                isChangedUserRoles: false,
                error: action.payload.error };
        }
        /** Get User Roles */
        case GET_USER_ROLES_REQUEST: {
            return { ...state, isLoading: true, error: undefined };
        }
        case GET_USER_ROLES_SUCCESS: {
            return { ...state, 
                userRoles: action.payload.userRoles, 
                isLoading: false };
        }
        case GET_USER_ROLES_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
         /** Set error */
        case SET_ERROR: {
            return {
                ...state,
                error: action.payload.error
            }
        }
        default:
            return state;
    }
};

export default reducer;