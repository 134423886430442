/** Absolute imports */
import React from 'react';
import { useDispatch } from 'react-redux';

/** Ant design */
import { Button, Input, Form, Modal } from 'antd';

/** Components */

/** Store */
import { saveServerRequest } from '../../../../store/deviceSettings/actions';

/** Types */
import { ServerEditProps } from '../types';

/** Styles */
import s from '../styles.module.scss';


export const ServerEdit: React.FC<ServerEditProps> = ({visible, onCancel, initialValues}) => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        console.log('Success:', values);
        dispatch(saveServerRequest(values));
        onCancel();
      };
    
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleOk = () => {
        form.submit();
    };


    return (
        <Modal title="Edit server" visible={visible} onOk={handleOk} onCancel={onCancel}>
            <div className={s.typeEdit}>
                <Form
                    form={form}
                    initialValues={initialValues}
                    layout="horizontal"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className={s.formTemplateEdit}
                >
                    <Form.Item
                        name="id"
                        hidden={true} 
                    >
                        <Input /> 
                    </Form.Item>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Please input a name!' }]}
                    >
                        <Input /> 
                    </Form.Item>
                    <Form.Item
                        label="Address"
                        name="address"
                        rules={[{ required: true, message: 'Please input a address!' }]}
                    >
                        <Input /> 
                    </Form.Item>
                    <Form.Item
                        label="Login"
                        name="login"
                        rules={[{ required: true, message: 'Please input a login!' }]}
                    >
                        <Input /> 
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input a password!' }]}
                    >
                        <Input /> 
                    </Form.Item>
                    <Form.Item
                        label="GroupId"
                        name="groupid"
                        rules={[{ required: true, message: 'Please input a groupid!' }]}
                    >
                        <Input /> 
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
}
