/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import {
    getUsersSuccess,
    getUsersFailure,
} from "../actions";

/** Services */
import { getUsers } from "../../../services/accessService";

/** Types */
import { GetUsersResponseDataType, User } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* getUsersSaga() {
  const { source } = createCancelToken();

  try {
    const response: GetUsersResponseDataType = yield call(getUsers);
    if(response && response.resultCode === ResultCode.Success && response.data) {
      yield put(getUsersSuccess(
        response.data.users.map((s: User) => {
            return {
              ...s,
              createDate: new Date(s.createDate)
            } as User;
          })
        ));
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(getUsersFailure(response.message));
    }    
  } catch (error) {
    yield put(getUsersFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default getUsersSaga;