/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { SaveServerRequest, SaveTypeRequest } from "../actionTypes";
import {
    getServersRequest,
    getTypesRequest,
    saveServerFailure,
    saveServerSuccess,
    saveTypeFailure,
    saveTypeSuccess,
} from "../actions";

/** Services */
import { saveServer } from "../../../services/hostSettingsService";

/** Types */
import { SaveServerResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* saveServerSaga({
  payload: {server},
}: SaveServerRequest) {
  const { cancelToken, source } = createCancelToken();

  try {
    const response: SaveServerResponseDataType = yield call(
      saveServer,
      server,
      { cancelToken }
    );
    if(response && response.resultCode === ResultCode.Success) {
      yield put(saveServerSuccess());
      yield put(getServersRequest());
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(saveServerFailure(response.message));
    }    
  } catch (error) {
    yield put(saveServerFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default saveServerSaga;