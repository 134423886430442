/** Absolute imports */
import React from 'react';
import { useDispatch } from 'react-redux';

/** Ant design */
import { Button, Input, Form, Modal } from 'antd';

/** Components */

/** Store */
import { saveTypeRequest } from '../../../../store/deviceSettings/actions';

/** Types */
import { TypeEditProps } from '../types';

/** Styles */
import s from '../styles.module.scss';


export const TypeEdit: React.FC<TypeEditProps> = ({visible, onCancel, initialValues}) => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        console.log('Success:', values);
        dispatch(saveTypeRequest(values));
        onCancel();
      };
    
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleOk = () => {
        form.submit();
    };


    return (
        <Modal title="Edit type" visible={visible} onOk={handleOk} onCancel={onCancel}>
            <div className={s.typeEdit}>
                <Form
                    form={form}
                    initialValues={initialValues}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className={s.formTypeEdit}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 30 }}
                >
                    <Form.Item
                        name="id"
                        hidden={true} 
                    >
                        <Input /> 
                    </Form.Item>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Please input a name!' }]}
                    >
                        <Input /> 
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
}
