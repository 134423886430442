/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import {
    getHostsSuccess,
    getHostsFailure,
} from "../actions";

/** Services */
import { getHosts } from "../../../services/hostService";

/** Types */
import { GetHostsResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* getHostsSaga() {
  const { source } = createCancelToken();

  try {
    const response: GetHostsResponseDataType = yield call(getHosts);
    if(response && response.resultCode === ResultCode.Success && response.data) {
      yield put(getHostsSuccess(response.data.hosts));
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(getHostsFailure(response.message));
    }    
  } catch (error) {
    yield put(getHostsFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default getHostsSaga;