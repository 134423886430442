/** Types */
import {
    SetError,
    GetMacrosRequest,
    GET_MACROS_REQUEST,
    GetTypesRequest,
    GET_TYPES_REQUEST,
    SaveTypeRequest,
    SAVE_TYPE_REQUEST,
    SAVE_TYPE_SUCCESS,
    SaveTypeSuccess,
    SaveTypeFailure,
    SAVE_TYPE_FAILURE,
    SaveMacroRequest,
    SAVE_MACRO_REQUEST,
    SaveMacroSuccess,
    SAVE_MACRO_SUCCESS,
    SaveMacroFailure,
    SAVE_MACRO_FAILURE,
    DeleteTypeRequest,
    DELETE_TYPE_REQUEST,
    DeleteTypeSuccess,
    DELETE_TYPE_SUCCESS,
    DeleteTypeFailure,
    DELETE_TYPE_FAILURE,
    DeleteMacroRequest,
    DeleteMacroSuccess,
    DeleteMacroFailure,
    DELETE_MACRO_REQUEST,
    DELETE_MACRO_SUCCESS,
    DELETE_MACRO_FAILURE,
    GetTypeRequest,
    GetTypeSuccess,
    GetTypeFailure,
    GET_TYPE_FAILURE,
    GET_TYPE_SUCCESS,
    GET_TYPE_REQUEST,
    GetMacroRequest,
    GetMacroSuccess,
    GetMacroFailure,
    GET_MACRO_REQUEST,
    GET_MACRO_SUCCESS,
    GET_MACRO_FAILURE,
    GET_TYPES_SUCCESS,
    GetTypesSuccess,
    GetTypesFailure,
    GET_TYPES_FAILURE,
    GetMacrosSuccess,
    GetMacrosFailure,
    GET_MACROS_SUCCESS,
    GET_MACROS_FAILURE,
    SET_ERROR,
    SaveTemplateRequest,
    SAVE_TEMPLATE_REQUEST,
    SAVE_TEMPLATE_SUCCESS,
    SaveTemplateSuccess,
    SaveTemplateFailure,
    SAVE_TEMPLATE_FAILURE,
    GetTemplateRequest,
    GET_TEMPLATE_REQUEST,
    GET_TEMPLATE_SUCCESS,
    GetTemplateSuccess,
    GET_TEMPLATE_FAILURE,
    GetTemplateFailure,
    GET_TEMPLATES_REQUEST,
    GetTemplatesRequest,
    GetTemplatesSuccess,
    GET_TEMPLATES_SUCCESS,
    GET_TEMPLATES_FAILURE,
    GetTemplatesFailure,
    DeleteTemplateRequest,
    DELETE_TEMPLATE_REQUEST,
    DELETE_TEMPLATE_SUCCESS,
    DeleteTemplateSuccess,
    DeleteTemplateFailure,
    DELETE_TEMPLATE_FAILURE,
    GET_MACROS_BY_TEMPLATEID_REQUEST,
    GetMacrosByTemplateIdRequest,
    GET_MACROS_BY_TEMPLATEID_SUCCESS,
    GetMacrosByTemplateIdSuccess,
    GetMacrosByTemplateIdFailure,
    GET_MACROS_BY_TEMPLATEID_FAILURE,
    RESET_MACROS_REQUEST,
    ResetMacrosRequest,
    SaveServerRequest,
    SAVE_SERVER_REQUEST,
    SaveServerSuccess,
    SAVE_SERVER_SUCCESS,
    SaveServerFailure,
    SAVE_SERVER_FAILURE,
    GetServerRequest,
    GetServerSuccess,
    GetServerFailure,
    GET_SERVER_FAILURE,
    GET_SERVER_SUCCESS,
    GET_SERVER_REQUEST,
    GET_SERVERS_REQUEST,
    GET_SERVERS_SUCCESS,
    GET_SERVERS_FAILURE,
    GetServersRequest,
    GetServersSuccess,
    GetServersFailure,
    DELETE_SERVER_SUCCESS,
    DELETE_SERVER_FAILURE,
    DELETE_SERVER_REQUEST,
    DeleteServerRequest,
    DeleteServerSuccess,
    DeleteServerFailure
  } from "./actionTypes";
import { Macro, Server, Template, Type } from "./types";

export const setError = (
    error: string | undefined
    ): SetError => ({
    type: SET_ERROR, 
    payload: {error}
});

export const resetMacrosRequest = (): ResetMacrosRequest => ({
    type: RESET_MACROS_REQUEST
})

/** Save Type */
export const saveTypeRequest = (type: Type | undefined): SaveTypeRequest => ({
    type: SAVE_TYPE_REQUEST,
    payload: { type }
});
  
export const saveTypeSuccess = (): SaveTypeSuccess => ({
    type: SAVE_TYPE_SUCCESS,
});
  
export const saveTypeFailure = (error: string | undefined): SaveTypeFailure => ({
    type: SAVE_TYPE_FAILURE,
    payload: { error }
});

/** Get Type */
export const getTypeRequest = (typeId: string | undefined): GetTypeRequest => ({
    type: GET_TYPE_REQUEST,
    payload: { typeId }
});
  
export const getTypeSuccess = (type: Type | undefined): GetTypeSuccess => ({
    type: GET_TYPE_SUCCESS,
    payload: { type }
});
  
export const getTypeFailure = (error: string | undefined): GetTypeFailure => ({
    type: GET_TYPE_FAILURE,
    payload: { error }
});

/** Get Types */
export const getTypesRequest = (): GetTypesRequest => ({
    type: GET_TYPES_REQUEST
});
  
export const getTypesSuccess = (types: Array<Type> | undefined): GetTypesSuccess => ({
    type: GET_TYPES_SUCCESS,
    payload: { types }
});
  
export const getTypesFailure = (error: string | undefined): GetTypesFailure => ({
    type: GET_TYPES_FAILURE,
    payload: { error }
});

/** Delete Type */
export const deleteTypeRequest = (typeId: string | undefined): DeleteTypeRequest => ({
    type: DELETE_TYPE_REQUEST,
    payload: { typeId }
});
  
export const deleteTypeSuccess = (): DeleteTypeSuccess => ({
    type: DELETE_TYPE_SUCCESS
});
  
export const deleteTypeFailure = (error: string | undefined): DeleteTypeFailure => ({
    type: DELETE_TYPE_FAILURE,
    payload: { error }
});
  
/** Save Macro */
export const saveMacroRequest = (macro: Macro | undefined): SaveMacroRequest => ({
    type: SAVE_MACRO_REQUEST,
    payload: { macro }
});
  
export const saveMacroSuccess = (): SaveMacroSuccess => ({
    type: SAVE_MACRO_SUCCESS
});
  
export const saveMacroFailure = (error: string | undefined): SaveMacroFailure => ({
    type: SAVE_MACRO_FAILURE,
    payload: { error }
});

/** Get Macro */
export const getMacroRequest = (macroId: string | undefined): GetMacroRequest => ({
    type: GET_MACRO_REQUEST,
    payload: { macroId }
});
  
export const getMacroSuccess = (macro: Macro | undefined): GetMacroSuccess => ({
    type: GET_MACRO_SUCCESS,
    payload: { macro }
});
  
export const getMacroFailure = (error: string | undefined): GetMacroFailure => ({
    type: GET_MACRO_FAILURE,
    payload: { error }
});

/** Get Macros */
export const getMacrosRequest = (): GetMacrosRequest => ({
    type: GET_MACROS_REQUEST
});
  
export const getMacrosSuccess = (macros: Array<Macro> | undefined): GetMacrosSuccess  => ({
    type: GET_MACROS_SUCCESS,
    payload: { macros }
});
  
export const getMacrosFailure = (error: string | undefined): GetMacrosFailure => ({
    type: GET_MACROS_FAILURE,
    payload: { error }
});

/** Get Macros by TemplateId */
export const getMacrosByTemplateIdRequest = (templateId: string): GetMacrosByTemplateIdRequest => ({
    type: GET_MACROS_BY_TEMPLATEID_REQUEST,
    payload: { templateId }
});
  
export const getMacrosByTemplateIdSuccess = (macros: Array<Macro> | undefined):GetMacrosByTemplateIdSuccess => ({
    type: GET_MACROS_BY_TEMPLATEID_SUCCESS,
    payload: { macros }
});
  
export const getMacrosByTemplateIdFailure = (error: string | undefined): GetMacrosByTemplateIdFailure => ({
    type: GET_MACROS_BY_TEMPLATEID_FAILURE,
    payload: { error }
});

/** Delete Macro */
export const deleteMacroRequest = (macroId: string | undefined): DeleteMacroRequest => ({
    type: DELETE_MACRO_REQUEST,
    payload: { macroId }
});
  
export const deleteMacroSuccess = (): DeleteMacroSuccess => ({
    type: DELETE_MACRO_SUCCESS
});
  
export const deleteMacroFailure = (error: string | undefined): DeleteMacroFailure => ({
    type: DELETE_MACRO_FAILURE,
    payload: { error }
});


/** Save Template */
export const saveTemplateRequest = (template: Template | undefined): SaveTemplateRequest => ({
    type: SAVE_TEMPLATE_REQUEST,
    payload: { template }
});
  
export const saveTemplateSuccess = (): SaveTemplateSuccess => ({
    type: SAVE_TEMPLATE_SUCCESS
});
  
export const saveTemplateFailure = (error: string | undefined): SaveTemplateFailure => ({
    type: SAVE_TEMPLATE_FAILURE,
    payload: { error }
});
  
/** Get Template */
export const getTemplateRequest = (templateId: string | undefined): GetTemplateRequest => ({
    type: GET_TEMPLATE_REQUEST,
    payload: { templateId }
});
  
export const getTemplateSuccess = (template: Template | undefined): GetTemplateSuccess => ({
    type: GET_TEMPLATE_SUCCESS,
    payload: { template }
});
  
export const getTemplateFailure = (error: string | undefined): GetTemplateFailure => ({
    type: GET_TEMPLATE_FAILURE,
    payload: { error }
});
  
/** Get Template */
export const getTemplatesRequest = (): GetTemplatesRequest => ({
    type: GET_TEMPLATES_REQUEST
});
  
export const getTemplatesSuccess = (templates: Array<Template> | undefined): GetTemplatesSuccess => ({
    type: GET_TEMPLATES_SUCCESS,
    payload: { templates }
});
  
export const getTemplatesFailure = (error: string | undefined): GetTemplatesFailure => ({
    type: GET_TEMPLATES_FAILURE,
    payload: { error }
});
  
/** Delete Template */
export const deleteTemplateRequest = (templateId: string | undefined): DeleteTemplateRequest => ({
    type: DELETE_TEMPLATE_REQUEST,
    payload: { templateId }
})
  
export const deleteTemplateSuccess = (): DeleteTemplateSuccess => ({
    type: DELETE_TEMPLATE_SUCCESS
});
  
export const deleteTemplateFailure = (error: string | undefined): DeleteTemplateFailure => ({
    type: DELETE_TEMPLATE_FAILURE,
    payload: { error }
});

/** Save Server */
export const saveServerRequest = (server: Server | undefined): SaveServerRequest => ({
    type: SAVE_SERVER_REQUEST,
    payload: { server }
});
  
export const saveServerSuccess = (): SaveServerSuccess => ({
    type: SAVE_SERVER_SUCCESS,
});
  
export const saveServerFailure = (error: string | undefined): SaveServerFailure => ({
    type: SAVE_SERVER_FAILURE,
    payload: { error }
});

/** Get Server */
export const getServerRequest = (serverId: string | undefined): GetServerRequest => ({
    type: GET_SERVER_REQUEST,
    payload: { serverId }
});
  
export const getServerSuccess = (server: Server | undefined): GetServerSuccess => ({
    type: GET_SERVER_SUCCESS,
    payload: { server }
});
  
export const getServerFailure = (error: string | undefined): GetServerFailure => ({
    type: GET_SERVER_FAILURE,
    payload: { error }
});

/** Get Server */
export const getServersRequest = (): GetServersRequest => ({
    type: GET_SERVERS_REQUEST
});
  
export const getServersSuccess = (servers: Array<Server> | undefined): GetServersSuccess => ({
    type: GET_SERVERS_SUCCESS,
    payload: { servers }
});
  
export const getServersFailure = (error: string | undefined): GetServersFailure => ({
    type: GET_SERVERS_FAILURE,
    payload: { error }
});

/** Delete Server */
export const deleteServerRequest = (serverId: string | undefined): DeleteServerRequest => ({
    type: DELETE_SERVER_REQUEST,
    payload: { serverId }
});
  
export const deleteServerSuccess = (): DeleteServerSuccess => ({
    type: DELETE_SERVER_SUCCESS
});
  
export const deleteServerFailure = (error: string | undefined): DeleteServerFailure => ({
    type: DELETE_SERVER_FAILURE,
    payload: { error }
});