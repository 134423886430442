/** Absolute imports */
import { AxiosRequestConfig } from "axios";
import { ProfileType } from "../store/profile/types";

/** Services */
import { api } from "./api";


export const getProfile = (
    userId: string
    ) =>
    api
        .get(`/api/profile/`+userId)
        .then((response) => {
            return response.data;
        });

export const savePhoto = (
    photoFile: File
    ) => {
        let formData = new FormData();
        formData.append("file", photoFile, photoFile.name ? photoFile.name : generateUUID() + '.png');
        return api
            .put(`/api/profile/photo`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => response.data);
    }

export const saveProfile = (
    profile: ProfileType,
    options?: AxiosRequestConfig
    ) =>
    api
        .put(`/api/profile`, profile, options)
        .then((response) => {
           return response.data;
        });


function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
        d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}