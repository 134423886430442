/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import {
    getUserRolesSuccess,
    getUserRolesFailure,
} from "../actions";

/** Services */
import { getUserRoles } from "../../../services/accessService";

/** Types */
import { GetUserRolesResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";
import { GetUserRolesRequest } from "../actionTypes";


function* getUserRolesSaga({
    payload: {userId},
  }: GetUserRolesRequest) {
    const { cancelToken, source } = createCancelToken();

  try {
    const response: GetUserRolesResponseDataType = yield call(
        getUserRoles,
        userId,
        { cancelToken });
    if(response && response.resultCode === ResultCode.Success && response.data) {
      yield put(getUserRolesSuccess(response.data.userRoles));
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(getUserRolesFailure(response.message));
    }    
  } catch (error) {
    yield put(getUserRolesFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default getUserRolesSaga;