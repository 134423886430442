/** Absolute imports */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

/** Ant design */
import { Button, Table, Modal, Alert } from 'antd';

/** Components */
import Preloader from '../../../components/Preloader/preloader';
import { MacroEdit } from './Edit/macro-edit';

/** Types */
import { MacrosPageSelectors } from './types';
import { Macro } from '../../../store/deviceSettings/types';

/** Store */
import { ApplicationState } from '../../../store';
import { makeGetError, makeGetTypes, makeGetMacros, makeGetMacro } from '../../../store/deviceSettings/selectors';
import { deleteMacroRequest, getMacroRequest, getMacrosRequest, getTypesRequest } from '../../../store/deviceSettings/actions';

/** Styles */
import s from './styles.module.scss';
import { makeGetIsAuth } from '../../../store/auth/selectors';


export const MacrosPage: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isMacroEditVisible, setIsMacroEditVisible] = useState(false);
    const [macroData, setMacroData] = useState<Macro | undefined>(undefined);
    const [macroId, setMacroId] = useState<string | undefined>(undefined);

    const dispatch = useDispatch();
    const selector = createStructuredSelector<
    ApplicationState,
    MacrosPageSelectors
    >({
        types: makeGetTypes(),
        error: makeGetError(),
        macros: makeGetMacros(),
        macro: makeGetMacro(),
        isAuth: makeGetIsAuth()
    });

    const {
        types,
        error,
        macros,
        macro,
        isAuth
    } = useSelector(selector);

    useEffect(() => {
        dispatch(getTypesRequest());
        dispatch(getMacrosRequest());
    }, []);

    useEffect(() => {
        if (macro)
            setMacroData(macro);
    }, [macro]);

    const handleCancelisOpenMacroEditModal = () => {
        setIsMacroEditVisible(false);
        setMacroData(undefined);
    }

    const showModal = (macroId: string) => {
        setMacroId(macroId);
        setIsModalVisible(true);
      };

    const onEditMacroHandle = (id: string | undefined) => {
        if (id) {
            dispatch(getMacroRequest(id));
        } else {
            setMacroData({
                id: "",
                name: "",
                typeId: ""
            });
        }
        setIsMacroEditVisible(true);
    }

    const handleOk = () => {
        if(macroId) {
          setIsModalVisible(false);
          dispatch(deleteMacroRequest(macroId));
        }
    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns: any = [
        {
          width: 1,
          dataIndex: 'id',
          key: 'id',
          fixed: 'left',
          className: s.idColumn
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: '1',
            width: 250,
        },
        {
          title: 'Edit',
          key: 'editOperation',
          fixed: 'right',
          width: 50,
          render: (values: any) => { 
            return (<Button onClick={() => onEditMacroHandle(values.id)}>Edit</Button>)
          },
        },
        {
          title: 'Delete',
          key: 'deleteOperation',
          fixed: 'right',
          width: 50,
          render: (values: any) => <Button onClick={() => showModal(values.id)}>Delete</Button>,
        },
      ];      
      

    if (!isAuth) {
        return  <Redirect to={"/login"} />
    }

    return (
        <>
        {error && 
            <Alert
                message={error}
                type="error"
                showIcon
                className={s.error}
            />
        }
        <div style={{marginBottom: '15px'}}><Button onClick={() => onEditMacroHandle(undefined)}>Add macro</Button></div>
        <Table rowKey={record => record.id}  columns={columns} dataSource={macros} className={s.dashboardTable} />
        <Modal title="Delete the macro" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <div>Are you sure that you want to delete this template?</div>
        </Modal>
        {macroData && 
            <MacroEdit visible={isMacroEditVisible} initialValues={macroData} onCancel={handleCancelisOpenMacroEditModal} />
        }
        </>
    );
}        
/*<div className='macrosPage'>
            <Link to="/types"><Button type="primary">Types</Button></Link>
            <MacroEdit onSave={onSave} id={""} name={""} typeId={""} />
            {macros && macros.map(macro => {
                return (
                    <div key={macro.id}>
                        <div className={s.typeBlock}>
                            <div>{macro.name}</div>
                            <Button type="primary" onClick={() => editMacro(macro.id)}>Edit</Button>
                            <Button type="primary" onClick={() => deleteMacro(macro.id)}>Delete</Button>
                        </div>
                        {isOpenMacroEdit === macro.id && 
                            <MacroEdit id={macro.id} name={macro.name} typeId={macro.typeId} onSave={onSave} />
                        }
                    </div>
                )
            })}
        </div>*/