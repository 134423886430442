/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { DeleteTypeRequest } from "../actionTypes";
import {
    deleteTypeFailure,
    deleteTypeSuccess,
    getTypesRequest,
} from "../actions";

/** Services */
import { deleteType } from "../../../services/hostSettingsService";

/** Types */
import { DeleteResponseDataType } from "../../device/types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* deleteTypeSaga({
  payload: { typeId },
}: DeleteTypeRequest) {
  const { cancelToken, source } = createCancelToken();

  try {
    const response: DeleteResponseDataType = yield call(
      deleteType,
      typeId
    );
    if(response && response.resultCode === ResultCode.Success) {
      yield put(deleteTypeSuccess());
      yield put(getTypesRequest());
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(deleteTypeFailure(response.message));
    }    
  } catch (error) {
    yield put(deleteTypeFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default deleteTypeSaga;