/** Absolute imports */
import { put, call } from "redux-saga/effects";

/** Redux */
import {
    resetAuth,
    setAuthUserData,
    setError,
} from "../actions";

/** Services */
import { storageName } from "../../../services/api";
import { logout } from "../../../services/authService";

function* logoutSaga() {
  try {
    yield call(logout);
    yield put(setAuthUserData(null, undefined, undefined, undefined, []));
    yield put(resetAuth(false));
    localStorage.removeItem(storageName);  
  } catch (error) {
    yield put(setError(error));
  }
}

export default logoutSaga;