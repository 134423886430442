/** Types */
import {
    SET_USER_DATA,
    GET_CAPTCHA_URL_SUCCESS,
    SET_ERROR,
    SET_REGISTER_SUCCESS,
    SetRegisterSuccess,
    SetUserData,
    GetCaptchaUrlSuccess,
    SetError,
    LOGIN_REQUEST,
    LoginRequest,
    REGISTER_REQUEST,
    RegisterRequest,
    LOGOUT_REQUEST,
    LogoutRequest,
    INITIALIZED_SUCCESS,
    InitializedSuccess,
    INITIALIZE_APP_REQUEST,
    InitializeAppRequest,
    RESET_AUTH,
    ResetAuth,
    SetUserNameRequest,
    SET_USER_NAME_REQUEST,
    SetUserPhotoRequest,
    SET_USER_PHOTO_REQUEST
  } from "./actionTypes";
  
export const setAuthUserData = (
  userId: string | null, 
  userName: string | undefined, 
  userPhoto: string | undefined, 
  token: string | undefined, 
  roles: Array<string>): SetUserData => ({
  type: SET_USER_DATA, 
  payload: { userId, userName, userPhoto, token, roles, isAuth: !!token }
});

export const getCaptchaUrlSuccess = (captchaUrl: string): GetCaptchaUrlSuccess => ({
  type: GET_CAPTCHA_URL_SUCCESS, 
  payload: {captchaUrl}
});

export const setError = (
  error: string | undefined
  ): SetError => ({
  type: SET_ERROR, 
  payload: {error}
});

export const setRegisterSuccess = (registerSuccess: boolean): SetRegisterSuccess => ({
  type: SET_REGISTER_SUCCESS, 
  payload: {registerSuccess}
});

export const loginRequest = (email: string, password: string): LoginRequest => ({
  type: LOGIN_REQUEST,
  payload: {email, password}
});

export const resetAuth = (isAuth: boolean): ResetAuth => ({
  type: RESET_AUTH,
  payload: {isAuth}
});

export const logoutRequest = (): LogoutRequest => ({
  type: LOGOUT_REQUEST
});

export const registerRequest = (email: string, password: string, userName: string): RegisterRequest => ({
  type: REGISTER_REQUEST,
  payload: { email, password, userName }
});

export const initializedSuccess = (): InitializedSuccess => ({ 
  type: INITIALIZED_SUCCESS 
});

export const initializeAppRequest = (): InitializeAppRequest => ({ 
  type: INITIALIZE_APP_REQUEST 
});

/** Set User Name */
export const setUserNameRequest = (userName: string): SetUserNameRequest => ({
  type: SET_USER_NAME_REQUEST,
  payload: { userName }
});

/** Set User Photo */
export const setUserPhotoRequest = (userPhoto: string): SetUserPhotoRequest => ({
  type: SET_USER_PHOTO_REQUEST,
  payload: { userPhoto }
});