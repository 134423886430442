/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { GetTypeRequest } from "../actionTypes";
import {
  getTypeFailure,
  getTypeSuccess,
} from "../actions";

/** Services */
import { getType } from "../../../services/hostSettingsService";

/** Types */
import { GetTypeResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* getTypeSaga({
  payload: {typeId},
}: GetTypeRequest) {
  const { source } = createCancelToken();

  try {
    const response: GetTypeResponseDataType = yield call(
      getType,
      typeId
    );
    if(response && response.resultCode === ResultCode.Success && response.data) {
      yield put(getTypeSuccess(response.data.type));
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(getTypeFailure(response.message));
    }    
  } catch (error) {
    yield put(getTypeFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default getTypeSaga;