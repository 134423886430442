/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { LoginRequest } from "../actionTypes";
import {
    setAuthUserData,
    setError,
} from "../actions";

/** Services */
import { login } from "../../../services/authService";
import { storageName } from "../../../services/api";

/** Types */
import { LoginResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";

function* loginSaga({
  payload: {email, password},
}: LoginRequest) {
  const { cancelToken, source } = createCancelToken();

  try {
    yield put(setError(undefined));
    const response: LoginResponseDataType = yield call(
      login,
      { email, password },
      { cancelToken }
    );
    if(response && response.resultCode === ResultCode.Success && response.data) {
      localStorage.setItem(storageName, JSON.stringify({ 
        userId: response.data.userId, 
        userName: response.data.userName,
        userPhoto: response.data.userPhoto, 
        token: response.data.token, 
        roles: response.data.roles }));
      yield put(setAuthUserData(response.data.userId, response.data.userName, response.data.userPhoto, response.data.token, response.data.roles));
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(setError(response.message));
    }    
  } catch (error) {
    yield put(setError(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default loginSaga;