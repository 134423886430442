/** Absolute imports */
import { put, cancelled } from "redux-saga/effects";

/** Redux */
import {
    addPhoto,
    setError,
} from "../actions";

/** Types */
import { AddPhotoRequest } from "../actionTypes";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";


function* addPhotoSaga({
  payload: {photo},
}: AddPhotoRequest) {
  const { source } = createCancelToken();

  try {
    if (photo)
      yield put(addPhoto(photo));
 
  } catch (error) {
    yield put(setError(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default addPhotoSaga;