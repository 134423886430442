import React, {useContext, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

/** Ant design */
import { Button, Table, Modal, Alert } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';

/** Store */
import { ApplicationState } from '../../../store';
import { makeGetIsAuth } from '../../../store/auth/selectors';
import { makeGetError, makeGetRole, makeGetRoles } from '../../../store/access/selectors';

/** Types */
import { AccessRoleSelectors } from './types';

/** Styles */
import style from './styles.module.scss';
import { deleteRoleRequest, getRoleRequest, getRolesRequest } from '../../../store/access/actions';
import { RoleEditModal } from './Edit/role-edit-modal';
import { Role } from '../../../store/access/types';


export const RolesPage: React.FC = () => { 
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isRoleEditModalVisible, setIsRoleEditModalVisible] = useState(false);
    const [roleData, setRoleData] = useState<Role | undefined>(undefined);
    const [rolesData, setRolesData] = useState<Array<Role> | undefined>(undefined);
    const [roleId, setRoleId] = useState<string | undefined>(undefined);
    const selector = createStructuredSelector<
    ApplicationState,
    AccessRoleSelectors
    >({
        error: makeGetError(),
        isAuth: makeGetIsAuth(),
        roles: makeGetRoles(),
        role: makeGetRole()
    });

    const {
        isAuth,
        roles,
        role,
        error
    } = useSelector(selector);

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getRolesRequest());
    }, []); 

    useEffect(() => {
      if (role) {
        setRoleData(role);
      }
    }, [role]);

    useEffect(() => {
        if (roles) {
          setRolesData(roles.map(r => ({...r, key: r.id })));
        }
      }, [roles]);

    const showModal = (roleId: string) => {
      setRoleId(roleId);
      setIsModalVisible(true);
    };
  
    const handleOk = () => {
        if(roleId) {
            setIsModalVisible(false);
            dispatch(deleteRoleRequest(roleId));
        }
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };

    const handleCancelRoleEditModal = () => {
      setIsRoleEditModalVisible(false);
      setRoleData(undefined);
    }

    const onEditRoleHandle = (id: string | undefined) => {
        if(id) {
        dispatch(getRoleRequest(id));
        } else {
            setRoleData({id: "", name: ""});
        }
        setIsRoleEditModalVisible(true); 
    }


    const columns: any = [
        {
          width: 1,
          dataIndex: 'id',
          key: 'id',
          className: style.idColumn,
          fixed: 'left',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: '1',
            width: 250,
            fixed: 'left',
        },
        {
          title: 'Edit',
          key: 'editOperation',
          fixed: 'right',
          width: 50,
          render: (values: any) => { 
            return (<Button onClick={() => onEditRoleHandle(values.id)}>Edit</Button>)
          },
        },
        {
          title: 'Delete',
          key: 'deleteOperation',
          fixed: 'right',
          width: 50,
          render: (values: any) => <Button onClick={() => showModal(values.id)}>Delete</Button>,
        },
      ];      
      

    if (!isAuth) {
        return  <Redirect to={"/login"} />
    }

    return (
        <>
        {error && 
            <Alert
                message={error}
                type="error"
                showIcon
            />
        }
        <Button type="primary" onClick={() => onEditRoleHandle(undefined)}>Add New Role</Button>
        <Table columns={columns} dataSource={rolesData} className={style.dashboardTable} />
        <Modal title="Delete the device" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <div>Are you shore that you want to delete this role?</div>
        </Modal>
        {roleData &&
          <RoleEditModal visible={isRoleEditModalVisible} initialValues={roleData} onCancel={handleCancelRoleEditModal} />
        }
        </>
    );
}
