/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { SavePhotoRequest } from "../actionTypes";
import {
    savePhotoSuccess,
    setError,
} from "../actions";

/** Services */
import { savePhoto } from "../../../services/profileService";

/** Types */
import { SavePhotoResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { APIResponseType, ResultCode } from "../../../services/types";
import { setUserPhotoRequest } from "../../auth/actions";
import { getLocalStorage, storageName } from "../../../services/api";


function* savePhotoSaga({
  payload: {file},
}: SavePhotoRequest) {
  const { cancelToken, source } = createCancelToken();

  try {
    yield put(setError(undefined));
    const response: APIResponseType<SavePhotoResponseDataType> = yield call(
      savePhoto,
      file
    );
    if(response && response.resultCode === ResultCode.Success) {
        yield put(savePhotoSuccess(response.data.photos));
        if (response.data.photos.small) {
          yield put(setUserPhotoRequest(response.data.photos.small));
          const data = getLocalStorage();
          localStorage.setItem(storageName, JSON.stringify({ ...data, userPhoto: response.data.photos.small }));
        }
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(setError(response.message));
    }    
  } catch (error) {
    yield put(setError(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default savePhotoSaga;