/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { SaveTemplateRequest } from "../actionTypes";
import {
    getTemplatesRequest,
    saveTemplateFailure,
    saveTemplateSuccess,
} from "../actions";

/** Services */
import { saveTemplate } from "../../../services/hostSettingsService";

/** Types */
import { SaveTemplateResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* saveTemplateSaga({
  payload: {template},
}: SaveTemplateRequest) {
  const { cancelToken, source } = createCancelToken();

  try {
    const response: SaveTemplateResponseDataType = yield call(
      saveTemplate,
      template,
      { cancelToken }
    );
    if(response && response.resultCode === ResultCode.Success) {
      yield put(saveTemplateSuccess());
      yield put(getTemplatesRequest());
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(saveTemplateFailure(response.message));
    }    
  } catch (error) {
    yield put(saveTemplateFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default saveTemplateSaga;