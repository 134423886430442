/** Absolute imports */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

/** Ant design */
import { Button, Table, Modal, Alert } from 'antd';

/** Types */
import { TypesPageSelectors } from './types';
import { Type } from '../../../store/deviceSettings/types';

/** Components */
import { TypeEdit } from './Edit/types-edit';

/** Store */
import { ApplicationState } from '../../../store';
import { deleteTypeRequest, getTypeRequest, getTypesRequest } from '../../../store/deviceSettings/actions';
import { makeGetError, makeGetType, makeGetTypes } from '../../../store/deviceSettings/selectors';

/** Styles */
import s from './styles.module.scss';
import { makeGetIsAuth } from '../../../store/auth/selectors';


export const TypesPage: React.FC = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isTypeEditVisible, setIsTypeEditVisible] = useState(false);
    const [typeData, setTypeData] = useState<Type | undefined>(undefined);
    const [typeId, setTypeId] = useState<string | undefined>(undefined);

    const dispatch = useDispatch();
    const selector = createStructuredSelector<
    ApplicationState,
    TypesPageSelectors
    >({
        isAuth: makeGetIsAuth(),
        types: makeGetTypes(),
        error: makeGetError(),
        type: makeGetType()
    });

    const {
        isAuth,
        types,
        error,
        type
    } = useSelector(selector);

    useEffect(() => {
        dispatch(getTypesRequest());
    }, []);

    useEffect(() => {
        if (type)
            setTypeData(type);
    }, [type]);

    const handleCancelIsOpenTypeEditModal = () => {
        setIsTypeEditVisible(false);
        setTypeData(undefined);
    }

    const showModal = (typeId: string) => {
        setTypeId(typeId);
        setIsModalVisible(true);
      };

    const onEditTypeHandle = (id: string | undefined) => {
        if (id) {
            dispatch(getTypeRequest(id));
        } else {
            setTypeData({
                id: "",
                name: "",
            });
        }
        setIsTypeEditVisible(true);
    }

    const handleOk = () => {
        if(typeId) {
          setIsModalVisible(false);
          dispatch(deleteTypeRequest(typeId));
        }
    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns: any = [
        {
          width: 1,
          dataIndex: 'id',
          key: 'id',
          fixed: 'left',
          className: s.idColumn
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: '1',
            width: 350
        },
        {
          title: 'Edit',
          key: 'editOperation',
          width: 50,
          className: s.buttonsEditDelete,
          render: (values: any) => { 
            return (<Button onClick={() => onEditTypeHandle(values.id)}>Edit</Button>)
          },
        },
        {
          title: 'Delete',
          key: 'deleteOperation',
          width: 50,
          className: s.buttonsEditDelete,
          render: (values: any) => <Button onClick={() => showModal(values.id)}>Delete</Button>,
        },
      ];      
      

    if (!isAuth) {
        return  <Redirect to={"/login"} />
    }
 
    return (
        <>
        {error && 
            <Alert
                message={error}
                type="error"
                showIcon
                className={s.error}
            />
        }
        <div style={{marginBottom: '15px'}}><Button onClick={() => onEditTypeHandle(undefined)}>Add type</Button></div>
        <Table rowKey={record => record.id} columns={columns} dataSource={types} className={s.dashboardTable} />
        <Modal title="Delete the type" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <div>Are you sure that you want to delete this type?</div>
        </Modal>
        {typeData && 
            <TypeEdit visible={isTypeEditVisible} initialValues={typeData} onCancel={handleCancelIsOpenTypeEditModal} />
        }
        </>
    );
}