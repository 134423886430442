import React, {useContext, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

/** Ant design */
import { Button, Table, Modal } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';

/** Store */
import { deleteHostRequest, getHostsRequest, setHostStatusRequest } from '../../store/device/actions';
import { makeGetHosts } from '../../store/device/selectors';
import { ApplicationState } from '../../store';
import { makeGetIsAuth } from '../../store/auth/selectors';

/** Types */
import { DashboardSelectors } from './types';

/** Styles */
import style from './dashboard.module.scss';
import { HostForShowing } from '../../store/device/types';



export const DashboardPage: React.FC = () => { 
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [hostId, setHostId] = useState<string | undefined>(undefined);
    const [hostData, setHostData] = useState<HostForShowing[] | undefined>();
    const selector = createStructuredSelector<
    ApplicationState,
    DashboardSelectors
    >({
        isAuth: makeGetIsAuth(),
        hosts: makeGetHosts()
    });

    const {
        isAuth,
        hosts
    } = useSelector(selector);

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getHostsRequest());
    }, []); 

    useEffect(() => {
      if (hosts) {
        setHostData(hosts.map(u => ({...u, key: u.id})));
      }
    }, [hosts]); 

    const showModal = (hostId: string) => {
      setHostId(hostId);
      setIsModalVisible(true);
    };
  
    const handleOk = () => {
      setIsModalVisible(false);
      dispatch(deleteHostRequest(hostId));
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };

    const changeStatusHandle = (id: string, status: boolean) => {
      console.log(id);
      dispatch(setHostStatusRequest(id, status));
    }

    const columns: any = [
        {
          width: 1,
          dataIndex: 'id',
          key: 'id',
          fixed: 'left',
          className: style.idColumn
        },
        {
          title: 'Status',
          key: 'editOperation',
          width: 30,
          fixed: 'left',
          render: (values: any) => { 
            return (values.status  
            ? <><PauseCircleOutlined className={style.pauseIcon} onClick={() => changeStatusHandle(values.id, !values.status)} /><span className={style.statusText}>Working...</span></>
            : <><PlayCircleOutlined className={style.playIcon} onClick={() => changeStatusHandle(values.id, !values.status)} /><span className={style.statusText}>Not working...</span></>)
          },
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: '2',
          width: 150,
          fixed: 'left',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: '3',
          width: 250,
        },
        {
          title: 'Edit',
          key: 'editOperation',
          fixed: 'right',
          width: 50,
          render: (values: any) => { 
            return (<Link to={`/edit_device/${values.id}`}>edit</Link>)
          },
        },
        {
          title: 'Delete',
          key: 'deleteOperation',
          fixed: 'right',
          width: 50,
          render: (values: any) => <div onClick={() => showModal(values.id)}><a>delete</a></div>,
        },
      ];
      
    if (!isAuth) {
        return  <Redirect to={"/login"} />
    }

    return (
        <>
        <Link to={"/add_new_device"}><Button type="primary">+ Add new device</Button></Link>
        <Table columns={columns} dataSource={hostData} className={style.dashboardTable} />
        <Modal title="Delete the device" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <div>Are you sure that you want to delete this device?</div>
        </Modal>
        </>
    );
}
