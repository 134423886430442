/** Absolurte imports */
import { Reducer } from "redux";

/** Types */
import { ProfileState } from "./types";
import {
    ProfileActionTypes,
    SET_USER_PROFILE,
    SAVE_PHOTO_SUCCESS,
    ADD_PHOTO,
    SET_ERROR
} from "./actionTypes";

export const INITIAL_STATE: ProfileState = {
    profile: undefined,
    photo: undefined,
    error: undefined,
}

const reducer: Reducer<ProfileState> = (
  state = INITIAL_STATE,
  action: ProfileActionTypes | any
) => {
    switch(action.type) {
        case SET_USER_PROFILE: {
            return { ...state, profile: action.payload.profile };
        }
        case SAVE_PHOTO_SUCCESS: {
            return {
                ...state,
                profile: { ...state.profile, photos: action.payload.photos }
            }
        }
        case ADD_PHOTO: {
            return {
                ...state,
                photo: action.payload.photo
            }
        }
        case SET_ERROR: {
            return {
                ...state,
                error: action.payload.error
            }
        }
        default:
            return state;
    }
};

export default reducer;