/** Absolute imports */
import { takeLatest } from "redux-saga/effects";

/** Types */
import { ADD_PHOTO_REQUEST, GET_USER_PROFILE_REQUEST, SAVE_PHOTO_REQUEST, SAVE_PROFILE_REQUEST } from "./actionTypes";
import addPhotoSaga from "./sagas/addPhotoSaga";

/** Sagas */
import getUserProfileSaga from "./sagas/getUserProfileSaga";
import savePhotoSaga from "./sagas/savePhotoSaga";
import saveProfileSaga from "./sagas/saveProfileSaga";


function* watchProfileSagas() {
  yield takeLatest(GET_USER_PROFILE_REQUEST, getUserProfileSaga);
  yield takeLatest(SAVE_PROFILE_REQUEST, saveProfileSaga);
  yield takeLatest(ADD_PHOTO_REQUEST, addPhotoSaga);
  yield takeLatest(SAVE_PHOTO_REQUEST, savePhotoSaga);
}

export default watchProfileSagas;