/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { SaveRoleRequest } from "../actionTypes";
import {
    getRolesRequest,
    saveRoleFailure,
    saveRoleSuccess,
} from "../actions";

/** Services */
import { saveRole } from "../../../services/accessService";

/** Types */
import { SaveResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* saveRoleSaga({
  payload: {role},
}: SaveRoleRequest) {
  const { cancelToken, source } = createCancelToken();

  try {
    const response: SaveResponseDataType = yield call(
      saveRole,
      role,
      { cancelToken }
    );
    if(response && response.resultCode === ResultCode.Success) {
      yield put(saveRoleSuccess());
      yield put(getRolesRequest());
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(saveRoleFailure(response.message));
    }    
  } catch (error) {
    yield put(saveRoleFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default saveRoleSaga;