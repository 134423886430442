export type APIResponseType<D = {}, RC = ResultCode> = {
    data: D
    message: string
    errors: Array<ErrorsType>
    resultCode: RC
}

export enum ResultCode {
    Success = 0,
    Error = 1,
}

export type ErrorsType = {
    value: string
    msg: string
    param: string
    location: string
}