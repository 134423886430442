/** Absolute imports */
import axios from "axios";

export const createCancelToken = () => {
  const source = axios.CancelToken.source();
  const { token: cancelToken } = source;
  return {
    source,
    cancelToken,
  };
};