/** Absolute imports */
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, withRouter } from 'react-router-dom';

/** Redux */
import configureStore from "./store";

/** Routes */
import { LayoutPage } from "./routes/Layout";

/** Utils */
import { history } from "./utils/routerUtils";
import { compose } from "redux";


const store = configureStore(undefined, history);

const RoutesContainer = compose<React.ComponentType>(withRouter)(LayoutPage);

const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
          <RoutesContainer />
      </Provider>
    </BrowserRouter>
  );
};

export default App;
