/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import {
    getRolesFailure,
    getRolesSuccess,
} from "../actions";

/** Services */
import { getRoles } from "../../../services/accessService";

/** Types */
import { GetRolesResponseDataType, User } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* getRolesSaga() {
  const { source } = createCancelToken();

  try {
    const response: GetRolesResponseDataType = yield call(getRoles);
    if(response && response.resultCode === ResultCode.Success && response.data) {
      yield put(getRolesSuccess(response.data.roles));
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(getRolesFailure(response.message));
    }    
  } catch (error) {
    yield put(getRolesFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default getRolesSaga;