/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import {
    getMacrosSuccess,
    getMacrosFailure,
} from "../actions";

/** Services */
import { getMacros } from "../../../services/hostSettingsService";

/** Types */
import { GetMacrosResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* getMacrosSaga() {
  const { source } = createCancelToken();

  try {
    //yield put(setError(undefined));
    const response: GetMacrosResponseDataType = yield call(getMacros);
    if(response && response.resultCode === ResultCode.Success && response.data) {
      yield put(getMacrosSuccess(response.data.macros));
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(getMacrosFailure(response.message));
    }    
  } catch (error) {
    yield put(getMacrosFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default getMacrosSaga;