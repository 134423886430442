/** Absolute imports */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';

/** Ant design */
import { Button, Input, Form, Modal, Checkbox, Row, Col, FormInstance } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

/** Components */

/** Store */
import { ApplicationState } from '../../../../store';
import { makeGetError } from '../../../../store/access/selectors';
import { changeUserRolesRequest, getRoleRequest, getRolesRequest, getUserRolesRequest, saveRoleRequest } from '../../../../store/access/actions';

/** Types */
import { AccessEditProps, AccessEditSelectors } from '../types';
import { Role, UserRoles } from '../../../../store/access/types';

/** Styles */
import s from '../styles.module.scss';
import { useResetFormOnCloseModal } from '../../../../hooks/useResetFormOnCloseModal';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { makeGetRoles } from '../../../../store/access/selectors';


export const AccessEditModal: React.FC<AccessEditProps> = ({visible, onCancel, initialValues}) => {

    const formRef = React.createRef<FormInstance>();
    const selector = createStructuredSelector<
    ApplicationState,
    AccessEditSelectors
    >({
        error: makeGetError(),
        roles: makeGetRoles()
    });

    const {
        error,
        roles
    } = useSelector(selector);

    const dispatch = useDispatch();
    /*const { userId } = useParams<{ userId: string }>();*/
    const [form] = Form.useForm();

    useResetFormOnCloseModal({
        form,
        visible,
      });

    /*useEffect(() => {
        if (userId)
            dispatch(getUserRolesRequest(userId));
    }, [userId]);*/

    useEffect(() => {
            dispatch(getRolesRequest());
    }, []);

    const onFinish = (values: UserRoles) => {
        console.log('Success:', values);
        dispatch(changeUserRolesRequest(values.userId, values.roles));
        onCancel();
      };
    
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onChange = (checkedValues: CheckboxValueType[]) => {
        console.log('checked = ', checkedValues);
        formRef.current!.setFieldsValue({ roles: checkedValues }); 
    }

    const handleOk = () => {
        form.submit();
    };
    console.log(initialValues);

    return (
        <Modal title="Change Access" visible={visible} onOk={handleOk} onCancel={onCancel}>

        <div className={s.templateEdit}>
        <Form
            form={form}
            ref={formRef}
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className={s.formTemplateEdit}
        >
            <Form.Item
                name="userId"
                hidden={true} 
            >
                <Input /> 
            </Form.Item>
            <Form.Item
                label="Roles"
                name="roles"
            >
                <Checkbox.Group style={{ width: '100%' }}>
                    <Row>
                        {roles && roles.map(role => (
                            <Col key={role.id} span={8}><Checkbox value={role.id}>{role.name}</Checkbox></Col>
                        ))}                  
                    </Row>
                </Checkbox.Group>
            </Form.Item>
    </Form>
    </div>
    </Modal>
    );
}


