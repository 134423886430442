/** Absolute imports */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

/** Ant design */
import { Button, Input, Form, Select, Modal } from 'antd';

/** Components */

/** Store */
import { saveMacroRequest } from '../../../../store/deviceSettings/actions';
import { ApplicationState } from '../../../../store';
import { makeGetError, makeGetTypes } from '../../../../store/deviceSettings/selectors';

/** Types */
import { MacroEditSelectors, MacroEditProps, TypesPropsType } from '../types';
import { Macro } from '../../../../store/deviceSettings/types';

/** Styles */
import s from '../styles.module.scss';


export const MacroEdit: React.FC<MacroEditProps> = ({visible, onCancel, initialValues}) => {

    const selector = createStructuredSelector<
    ApplicationState,
    MacroEditSelectors
    >({
        types: makeGetTypes(),
        error: makeGetError()
    });

    const {
        types,
        error
    } = useSelector(selector);

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const onFinish = (values: Macro) => {
        console.log('Success:', values);
        dispatch(saveMacroRequest(values));
        onCancel();
      };
    
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleOk = () => {
        form.submit();
    };

    console.log(initialValues);

    return (
        <Modal title="Edit macro" visible={visible} onOk={handleOk} onCancel={onCancel}>
        <div className={s.macroEdit}>
        <Form
            form={form}
            initialValues={initialValues}
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className={s.formTemplateEdit}
        >
            <Form.Item
                name="id"
                hidden={true} 
            >
                <Input /> 
            </Form.Item>
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input a name!' }]}
            >
                <Input placeholder="{$EXAMPLE}" /> 
            </Form.Item>
            <Form.Item
                label="Type"
                name="type"
                rules={[{ required: true, message: 'Please input a type!' }]}
            >
                <Select style={{minWidth: 200}}>
                    {types.map(type => {
                    return (
                        <Select.Option key={type.id} value={type.id}>{type.name}</Select.Option>
                    );
                    })}
                </Select>
            </Form.Item>
        </Form>
        </div>
        </Modal>
    );
}
