/** Absolute imports */
import React, { useEffect, useState } from 'react';

/** Styles */
import s from './profile-info.module.scss';


type ContactPropsType = {
    contactTitle: string
   // contactValue: boolean
}

export const Contact: React.FC<ContactPropsType> = ({contactTitle/*,contactValue*/}) => {
    return (
        <table className={s.tableUserInfo}>
            <tbody>
            <tr>
                <td width="180"><span className={s.title}>{contactTitle}</span></td>
                {/*<td><span className={s.infoAboutUser}>{contactValue ? 'active' : ''}</span></td>*/}
            </tr> 
            </tbody>           
        </table>     
    );
}