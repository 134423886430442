/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import {
    getTypesSuccess,
    getTypesFailure,
} from "../actions";

/** Services */
import { getTypes } from "../../../services/hostSettingsService";

/** Types */
import { GetTypesResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* getTypesSaga() {
  const { source } = createCancelToken();

  try {
    //yield put(setError(undefined));
    const response: GetTypesResponseDataType = yield call(getTypes);
    if(response && response.resultCode === ResultCode.Success && response.data) {
      yield put(getTypesSuccess(response.data.types));
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(getTypesFailure(response.message));
    }    
  } catch (error) {
    yield put(getTypesFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default getTypesSaga;