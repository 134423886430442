/** Absolute imports */
import { AxiosRequestConfig } from "axios";
import { Host } from "../store/device/types";

/** Services */
import { api } from "./api";


export const getHosts = () => 
api
    .get(`/api/device/get-hosts`)
    .then((response) => {
        return response.data;
    });

export const saveHost = (
    device: Host | undefined,
    options?: AxiosRequestConfig
    ) =>
    api
        .post(`/api/device/save-host`, device, options)
        .then((response) => {
            return response.data;
        });

export const setHostStatus = (
    statusData: {id: string, status: boolean},
    options?: AxiosRequestConfig
    ) =>
    api
        .post(`/api/device/set-host-status`, statusData, options)
        .then((response) => {
            return response.data;
        });

export const deleteHost = (
    hostId: string | undefined
    ) =>
    api
        .delete(`/api/device/delete-host/${hostId}`)
        .then((response) => {
            return response.data;
        });

export const getHost = (
    hostId: string | undefined
    ) =>
    api
        .get(`/api/device/get-host/${hostId}`)
        .then((response) => {
            return response.data;
        });