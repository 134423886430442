/** Absolute imports */
import { takeLatest } from "redux-saga/effects";

/** Redux */
import { INITIALIZE_APP_REQUEST, LOGIN_REQUEST, LOGOUT_REQUEST, REGISTER_REQUEST } from "./actionTypes";
import initializeAppSaga from "./sagas/initializeAppSaga";
import loginSaga from "./sagas/loginSaga";
import logoutSaga from "./sagas/logoutSaga";
import registerSaga from "./sagas/registerSaga";

function* watchAuthSagas() {
  yield takeLatest(LOGIN_REQUEST, loginSaga);
  yield takeLatest(REGISTER_REQUEST, registerSaga);
  yield takeLatest(INITIALIZE_APP_REQUEST, initializeAppSaga);
  yield takeLatest(LOGOUT_REQUEST, logoutSaga);
}

export default watchAuthSagas;