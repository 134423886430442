/** Absolurte imports */
import { Reducer } from "redux";

/** Types */
import { DeviceSettingsState, Status } from "./types";
import {
    DeviceSettingsActionTypes,
    SET_ERROR,
    SAVE_TYPE_REQUEST,
    SAVE_TYPE_SUCCESS,
    SAVE_TYPE_FAILURE,
    SAVE_MACRO_REQUEST,
    SAVE_MACRO_SUCCESS,
    SAVE_MACRO_FAILURE,
    DELETE_TYPE_REQUEST,
    DELETE_TYPE_SUCCESS,
    DELETE_TYPE_FAILURE,
    DELETE_MACRO_REQUEST,
    DELETE_MACRO_SUCCESS,
    DELETE_MACRO_FAILURE,
    GET_TYPE_REQUEST,
    GET_TYPE_SUCCESS,
    GET_TYPE_FAILURE,
    GET_TYPES_REQUEST,
    GET_TYPES_SUCCESS,
    GET_TYPES_FAILURE,
    GET_MACRO_REQUEST,
    GET_MACRO_SUCCESS,
    GET_MACRO_FAILURE,
    GET_MACROS_REQUEST,
    GET_MACROS_SUCCESS,
    GET_MACROS_FAILURE,
    DELETE_TEMPLATE_REQUEST,
    DELETE_TEMPLATE_SUCCESS,
    DELETE_TEMPLATE_FAILURE,
    SAVE_TEMPLATE_REQUEST,
    SAVE_TEMPLATE_SUCCESS,
    SAVE_TEMPLATE_FAILURE,
    GET_TEMPLATES_REQUEST,
    GET_TEMPLATES_SUCCESS,
    GET_TEMPLATES_FAILURE,
    GET_TEMPLATE_REQUEST,
    GET_TEMPLATE_SUCCESS,
    GET_TEMPLATE_FAILURE,
    GET_MACROS_BY_TEMPLATEID_REQUEST,
    GET_MACROS_BY_TEMPLATEID_SUCCESS,
    GET_MACROS_BY_TEMPLATEID_FAILURE,
    RESET_MACROS_REQUEST,
    GET_SERVER_REQUEST,
    GET_SERVER_SUCCESS,
    GET_SERVER_FAILURE,
    GET_SERVERS_REQUEST,
    GET_SERVERS_SUCCESS,
    GET_SERVERS_FAILURE,
    SAVE_SERVER_REQUEST,
    SAVE_SERVER_SUCCESS,
    SAVE_SERVER_FAILURE,
    DELETE_SERVER_REQUEST,
    DELETE_SERVER_SUCCESS,
    DELETE_SERVER_FAILURE,
} from "./actionTypes";

export const INITIAL_STATE: DeviceSettingsState = {
    error: undefined,
    status: Status.off,
    types: [],
    macros: [],
    macroValue: [],
    servers: [],
    server: undefined,
    type: undefined,
    macro: undefined,
    template: undefined,
    templates: [],
    isLoading: false
}

const reducer: Reducer<DeviceSettingsState> = (
  state = INITIAL_STATE,
  action: DeviceSettingsActionTypes | any
) => {
    switch(action.type) {
        /** Get Server */
        case GET_SERVER_REQUEST: {
            return { ...state, type: undefined, isLoading: true, error: undefined };
        }
        case GET_SERVER_SUCCESS: {
            return { ...state, 
                server: action.payload.server, 
                isLoading: false };
        }
        case GET_SERVER_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Get Servers */
        case GET_SERVERS_REQUEST: {
            return { ...state, isLoading: true, error: undefined };
        }
        case GET_SERVERS_SUCCESS: {
            return { ...state, 
                servers: action.payload.servers, 
                isLoading: false };
        }
        case GET_SERVERS_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
         /** Get Type */
        case GET_TYPE_REQUEST: {
            return { ...state, type: undefined, isLoading: true, error: undefined };
        }
        case GET_TYPE_SUCCESS: {
            return { ...state, 
                type: action.payload.type, 
                isLoading: false };
        }
        case GET_TYPE_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Get Types */
        case GET_TYPES_REQUEST: {
            return { ...state, isLoading: true, error: undefined };
        }
        case GET_TYPES_SUCCESS: {
            return { ...state, 
                types: action.payload.types, 
                isLoading: false };
        }
        case GET_TYPES_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Get Macro */
        case GET_MACRO_REQUEST: {
            return { ...state, macro: undefined, isLoading: true, error: undefined };
        }
        case GET_MACRO_SUCCESS: {
            return { ...state, 
                macro: action.payload.macro, 
                isLoading: false };
        }
        case GET_MACRO_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Get Template */
        case GET_TEMPLATE_REQUEST: {
            return { ...state, template: undefined, isLoading: true, error: undefined };
        }
        case GET_TEMPLATE_SUCCESS: {
            return { ...state, 
                template: action.payload.template, 
                isLoading: false };
        }
        case GET_TEMPLATE_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Reset Macros */
        case RESET_MACROS_REQUEST: {
            return { ...state, macros: [] };
        }
        /** Get Macros */
        case GET_MACROS_REQUEST: {
            return { ...state, isLoading: true, error: undefined };
        }
        case GET_MACROS_SUCCESS: {
            return { ...state, 
                macros: action.payload.macros, 
                isLoading: false };
        }
        case GET_MACROS_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Get Macros by TemplateId */
        case GET_MACROS_BY_TEMPLATEID_REQUEST: {
            return { ...state, isLoading: true, error: undefined };
        }
        case GET_MACROS_BY_TEMPLATEID_SUCCESS: {
            return { ...state, 
                macros: action.payload.macros, 
                isLoading: false };
        }
        case GET_MACROS_BY_TEMPLATEID_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Get Templates */
        case GET_TEMPLATES_REQUEST: {
            return { ...state, isLoading: true, error: undefined };
        }
        case GET_TEMPLATES_SUCCESS: {
            return { ...state, 
                templates: action.payload.templates, 
                isLoading: false };
        }
        case GET_TEMPLATES_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Save Server */
        case SAVE_SERVER_REQUEST: {
            return { ...state, type: undefined, isLoading: true, error: undefined };
        }
        case SAVE_SERVER_SUCCESS: {
            return { ...state, isLoading: false };
        }
        case SAVE_SERVER_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Save Type */
        case SAVE_TYPE_REQUEST: {
            return { ...state, type: undefined, isLoading: true, error: undefined };
        }
        case SAVE_TYPE_SUCCESS: {
            return { ...state, isLoading: false };
        }
        case SAVE_TYPE_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Save Macro */
        case SAVE_MACRO_REQUEST: {
            return { ...state, macro: undefined, isLoading: true, error: undefined };
        }
        case SAVE_MACRO_SUCCESS: {
            return { ...state, isLoading: false };
        }
        case SAVE_MACRO_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Save Template */
        case SAVE_TEMPLATE_REQUEST: {
            return { ...state, template: undefined, isLoading: true, error: undefined };
        }
        case SAVE_TEMPLATE_SUCCESS: {
            return { ...state, isLoading: false };
        }
        case SAVE_TEMPLATE_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
         /** Delete Macro */
        case DELETE_MACRO_REQUEST: {
            return { ...state, isLoading: true, error: undefined };
        }
        case DELETE_MACRO_SUCCESS: {
            return { ...state, isLoading: false };
        }
        case DELETE_MACRO_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Delete Server */
        case DELETE_SERVER_REQUEST: {
            return { ...state, isLoading: true, error: undefined };
        }
        case DELETE_SERVER_SUCCESS: {
            return { ...state, isLoading: false };
        }
        case DELETE_SERVER_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Delete Type */
        case DELETE_TYPE_REQUEST: {
            return { ...state, isLoading: true, error: undefined };
        }
        case DELETE_TYPE_SUCCESS: {
            return { ...state, isLoading: false };
        }
        case DELETE_TYPE_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Delete Template */
        case DELETE_TEMPLATE_REQUEST: {
            return { ...state, isLoading: true, error: undefined };
        }
        case DELETE_TEMPLATE_SUCCESS: {
            return { ...state, isLoading: false };
        }
        case DELETE_TEMPLATE_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Set error */
        case SET_ERROR: {
            return {
                ...state,
                error: action.payload.error
            }
        }
        default:
            return state;
    }
};

export default reducer;