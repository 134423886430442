/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { DeleteHostRequest } from "../actionTypes";
import {
  deleteHostFailure,
  deleteHostSuccess,
  getHostsRequest,
} from "../actions";

/** Services */
import { deleteHost } from "../../../services/hostService";

/** Types */
import { DeleteResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* deleteHostSaga({
  payload: {hostId},
}: DeleteHostRequest) {
  const { source } = createCancelToken();

  try {
    const response: DeleteResponseDataType = yield call(
      deleteHost,
      hostId
    );
    if(response && response.resultCode === ResultCode.Success) {
      yield put(deleteHostSuccess());
      yield put(getHostsRequest());
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(deleteHostFailure(response.message));
    }    
  } catch (error) {
    yield put(deleteHostFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default deleteHostSaga;