/** Absolute imports */
import { AxiosRequestConfig } from "axios";
import { Role, UserEdit } from "../store/access/types";
import { Host } from "../store/device/types";

/** Services */
import { api } from "./api";


export const getUsers = () => 
api
    .get(`/api/access/get-users`)
    .then((response) => {
        return response.data;
    });

export const getUser = (
    userId: string,
    options?: AxiosRequestConfig) => 
api
    .get(`/api/access/get-user/${userId}`)
    .then((response) => {
        return response.data;
    });

export const saveUser = (    
    user: UserEdit,
    options?: AxiosRequestConfig
    ) =>
    api
        .put(`/api/access/save-user`, user, options)
        .then((response) => {
           return response.data;
        });

export const deleteUser = (
    userId: string,
    options?: AxiosRequestConfig) => 
api
    .delete(`/api/access/delete-user/${userId}`)
    .then((response) => {
        return response.data;
    });

export const getRoles = () => 
api
    .get(`/api/access/get-roles`)
    .then((response) => {
        return response.data;
    });

export const getUserRoles = (
    userId: string,
    options?: AxiosRequestConfig) => 
api
    .get(`/api/access/get-user-roles/${userId}`)
    .then((response) => {
        return response.data;
    });

export const getRole = (
    roleId: string,
    options?: AxiosRequestConfig) => 
api
    .get(`/api/access/get-role/${roleId}`)
    .then((response) => {
        return response.data;
    });

export const saveRole = (    
    role: Role,
    options?: AxiosRequestConfig
    ) =>
    api
        .put(`/api/access/save-role`, role, options)
        .then((response) => {
            return response.data;
        });

export const deleteRole = (
    roleId: string,
    options?: AxiosRequestConfig) => 
api
    .delete(`/api/access/delete-role/${roleId}`)
    .then((response) => {
        return response.data;
    });

export const changeUserRoles = (
    userRoles: { roles: Array<string>, userId: string},
    options?: AxiosRequestConfig
    ) =>
    api
        .put(`/api/access/change-user-roles/${userRoles.userId}`, {roles: userRoles.roles}, options)
        .then((response) => {
            return response.data;
        });
