/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import {
    getServersSuccess,
    getServersFailure,
} from "../actions";

/** Services */
import { getServers } from "../../../services/hostSettingsService";

/** Types */
import { GetServersResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* getServersSaga() {
  const { source } = createCancelToken();

  try {
    //yield put(setError(undefined));
    const response: GetServersResponseDataType = yield call(getServers);
    if(response && response.resultCode === ResultCode.Success && response.data) {
      yield put(getServersSuccess(response.data.servers));
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(getServersFailure(response.message));
    }    
  } catch (error) {
    yield put(getServersFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default getServersSaga;