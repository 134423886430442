import React, {useContext, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

/** Ant design */
import { Button, Table, Modal, Alert } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';

/** Components */
import { UserEditModal } from './Edit/user-edit-modal';
import { AccessEditModal } from './Edit/access-edit-modal';

/** Store */
import { ApplicationState } from '../../../store';
import { makeGetIsAuth } from '../../../store/auth/selectors';
import { makeGetError, makeGetUser, makeGetUserRoles, makeGetUsers } from '../../../store/access/selectors';
import { deleteUserRequest, getUserRequest, getUserRolesRequest, getUsersRequest } from '../../../store/access/actions';

/** Types */
import { AccessSelectors } from './types';
import { User, UserEdit, UserRoles } from '../../../store/access/types';

/** Styles */
import style from './styles.module.scss';


export const UsersPage: React.FC = () => { 
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isUserEditModalVisible, setIsUserEditModalVisible] = useState(false);
    const [isEditAccessModalVisible, setIsEditAccessModalVisible] = useState(false);
    const [userData, setUserData] = useState<UserEdit | undefined>(undefined);
    const [usersData, setUsersData] = useState<Array<User> | undefined>();
    const [userRolesData, setUserRolesData] = useState<UserRoles>();
    const [userId, setUserId] = useState<string | undefined>(undefined);
    const selector = createStructuredSelector<
    ApplicationState,
    AccessSelectors
    >({
        error: makeGetError(),
        isAuth: makeGetIsAuth(),
        users: makeGetUsers(),
        user: makeGetUser(),
        userRoles: makeGetUserRoles()
    });

    const {
        error,
        isAuth,
        users,
        user,
        userRoles
    } = useSelector(selector);

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getUsersRequest());
    }, []); 

    useEffect(() => {
      if (user) {
        setUserData(user);
      }
    }, [user]);

    useEffect(() => {
      if (users) {
        setUsersData(users.map(u => ({...u, key: u.id})));
      }
    }, [users]);

    useEffect(() => {
      if (userRoles) {
        setUserRolesData(userRoles);
      }
    },[userRoles]);

    const showModal = (userId: string) => {
      setUserId(userId);
      setIsModalVisible(true);
    };
  
    const handleOk = () => {
      if(userId) {
        setIsModalVisible(false);
        dispatch(deleteUserRequest(userId));
      }
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };

    const handleCancelUserEditModal = () => {
      setIsUserEditModalVisible(false);
      setUserData(undefined);
    }

    const handleCancelAccessEditModal = () => {
      setIsEditAccessModalVisible(false);
      setUserRolesData(undefined);
    }

    const onEditUserHandle = (id: string) => {
      dispatch(getUserRequest(id));
      setIsUserEditModalVisible(true);
    }

    const onEditAccessHandle = (id: string) => {
      dispatch(getUserRolesRequest(id));
      setIsEditAccessModalVisible(true);
    }


    const columns: any = [
        {
          width: 1,
          dataIndex: 'id',
          key: 'id',
          fixed: 'left',
          className: style.idColumn
        },
        {
            title: 'Name',
            dataIndex: 'fullName',
            key: '1',
            width: 250,
            fixed: 'left',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: '2',
          width: 150,
        },
        {
            title: 'Roles',
            key: '3',
            fixed: 'right',
            width: 50,
            render: (values: any) => { 
              return values.roles.join();
            },
          },
        {
          title: 'CreateDate',
          dataIndex: 'createDate',
          key: '4',
          width: 400,
          fixed: 'right',
          render: (values: any) => { 
            return values.toLocaleString();
          },
        },
        {
          title: 'EditAccess',
          key: 'editAccessOperation',
          fixed: 'right',
          width: 50,
          render: (values: any) => { 
            return (<Button onClick={() => onEditAccessHandle(values.id)}>Access</Button>)
          },
        },
        {
          title: 'Edit',
          key: 'editOperation',
          fixed: 'right',
          width: 50,
          render: (values: any) => { 
            return (<Button onClick={() => onEditUserHandle(values.id)}>Edit</Button>)
          },
        },
        {
          title: 'Delete',
          key: 'deleteOperation',
          fixed: 'right',
          width: 50,
          render: (values: any) => <Button onClick={() => showModal(values.id)}>Delete</Button>,
        },
      ];      
      

    if (!isAuth) {
        return  <Redirect to={"/login"} />
    }

    console.log(error);

    return (
        <>
        {error && 
            <Alert
                message={error}
                type="error"
                showIcon
            />
        }
        <Table columns={columns} dataSource={usersData} className={style.dashboardTable} />
        <Modal title="Delete the device" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <div>Are you sure that you want to delete this user?</div>
        </Modal>
        {userData &&
          <UserEditModal visible={isUserEditModalVisible} initialValues={userData} onCancel={handleCancelUserEditModal} />
        }
        {userRolesData &&
          <AccessEditModal visible={isEditAccessModalVisible} initialValues={userRolesData} onCancel={handleCancelAccessEditModal} />
        }
        </>
    );
}
