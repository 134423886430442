/** Absolute imports */
import React from 'react';
import { useSelector } from 'react-redux';

/** Ant designe */
import { Button, Upload, Modal, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

/** Style */
import s from './profile-info.module.scss';
import userPhoto from '../../../assets/images/user_default.png';

/** Types */
import { ProfileType } from '../../../store/profile/types';
import { ReactCropper } from './react-cropper';
import { makeGetProfilePhoto } from '../../../store/profile/selectors';
import { createStructuredSelector } from 'reselect';
import { ApplicationState } from '../../../store';
import { ImageAvatarSelectors } from '../types';

type ImageAvatarType = {
    profile: ProfileType
    isOwner: boolean
    onMainPhotoSelected: (e: any) => void,
    sendCorrectedMainPhoto: (e: any) => void
}

export const ImageAvatar: React.FC<ImageAvatarType> = React.memo(({profile, isOwner, onMainPhotoSelected, sendCorrectedMainPhoto}) => {
    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);

    const selector = createStructuredSelector<
       ApplicationState,
       ImageAvatarSelectors
     >({
       photo: makeGetProfilePhoto()
     });

    const {
        photo
    } = useSelector(selector);
  
    const showModal = () => {
      setVisible(true);
    };
  
    const handleOk = () => {
      setConfirmLoading(true);
        setVisible(false);
        setConfirmLoading(false);
        sendCorrectedMainPhoto(photo);
    };
  
    const handleCancel = () => {
      console.log('Clicked cancel button');
      setVisible(false);
    };

    const props = {
        beforeUpload: (file: File) => {
          if ((file.type !== 'image/jpeg') && (file.type !== 'image/png'))  {
            message.error(`${file.name} is not a png or jpg file`);
          }
          return file.type === 'image/jpeg' || file.type === 'image/png' ? true : false;
        },
        onChange: (info: any) => {
            if (info.file.status !== 'uploading') {
                onMainPhotoSelected(info);
            }
        },
      };


    return (
        <div>
            <div className={s.photoBig}><img src={profile.photos.large || userPhoto} className={s.mainPhoto} /></div>
            {/*<div className={s.photoSmall}><img src={profile.photos.small || userPhoto} className={s.mainPhotoSmall} /></div>*/}
                <div>
                    <div className={s.updateButtonBig}>
                        <Button onClick={showModal} className={s.updateButton}>
                            Edit photo
                        </Button>
                    </div>
                    <div onClick={showModal} className={s.updateButtonSmall}>Edit photo</div>
                    <Modal  
                        title="Image editor"
                        visible={visible}
                        onOk={handleOk}
                        confirmLoading={confirmLoading}
                        onCancel={handleCancel}>
                        <ReactCropper rerender={confirmLoading} /> 
                        <Upload {...props}  className={s.uploadNewImage}> 
                            <Button>
                            <UploadOutlined /> Upload new Image
                        </Button>
                    </Upload> 
                    </Modal>
                </div>
        </div>
    );
});