export const SET_USER_DATA = "[AUTH] SET_USER_DATA";
export const GET_CAPTCHA_URL_SUCCESS = "[AUTH] GET_CAPTCHA_URL_SUCCESS";
export const SET_ERROR = "[AUTH] SET_ERROR";
export const SET_REGISTER_SUCCESS = "[AUTH] SET_REGISTER_SUCCESS";

export const LOGIN_REQUEST = "[AUTH] LOGIN_REQUEST";
export const REGISTER_REQUEST = "[AUTH] REGISTER_REQUEST";
export const GET_AUTH_USER_DATA_REQUEST = "[AUTH] GET_AUTH_USER_DATA_REQUEST";
export const LOGOUT_REQUEST = "[AUTH] LOGOUT_REQUEST";

export const INITIALIZED_SUCCESS = "[AUTH] INITIALIZED_SUCCESS";
export const INITIALIZE_APP_REQUEST = "[AUTH] INITIALIZE_APP_REQUEST";
export const RESET_AUTH = "[AUTH] RESET_AUTH";

export const SET_USER_NAME_REQUEST = "[ACCESS] SET_USER_NAME_REQUEST";
export const SET_USER_PHOTO_REQUEST = "[AUTH] SET_USER_PHOTO_REQUEST"

export interface LoginRequest {
    type: typeof LOGIN_REQUEST;
    payload: {
        email: string, 
        password: string
    };
}

export interface ResetAuth {
  type: typeof RESET_AUTH;
  payload: {
    isAuth: boolean;
};
}

export interface LogoutRequest {
  type: typeof LOGOUT_REQUEST;
}

export interface InitializedSuccess {
  type: typeof INITIALIZED_SUCCESS;
}

export interface InitializeAppRequest {
  type: typeof INITIALIZE_APP_REQUEST;
}

export interface RegisterRequest {
  type: typeof REGISTER_REQUEST;
  payload: {
      email: string;
      password: string;
      userName: string;
  };
}

export interface SetRegisterSuccess {
  type: typeof SET_REGISTER_SUCCESS;
  payload: {
    registerSuccess: boolean;
  };
}

export interface SetUserData {
    type: typeof SET_USER_DATA;
    payload: {
        userId: string | null;
        token: string | undefined;
        isAuth: boolean;
        roles: Array<string>;
        userName: string | undefined;
        userPhoto: string | undefined;
    };
}


export interface GetCaptchaUrlSuccess {
    type: typeof GET_CAPTCHA_URL_SUCCESS;
    payload: {
        captchaUrl: string | undefined;
    };
  }

export interface SetError {
  type: typeof SET_ERROR;
  payload: {
    error: string | undefined;
  };
}

/** Set User Name */
export interface SetUserNameRequest {
  type: typeof SET_USER_NAME_REQUEST;
  payload: {
    userName: string;
  };
}

/** Set User Photo */
export interface SetUserPhotoRequest {
  type: typeof SET_USER_PHOTO_REQUEST;
  payload: {
    userPhoto: string;
  };
}

export type AuthActionTypes =
  | SetUserData
  | SetRegisterSuccess
  | GetCaptchaUrlSuccess
  | ResetAuth
  | InitializedSuccess
  | SetUserNameRequest
  | SetUserPhotoRequest
  | SetError;