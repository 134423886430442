/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { DeleteUserRequest } from "../actionTypes";
import {
    deleteUserFailure,
    deleteUserSuccess,
    getUsersRequest,
} from "../actions";

/** Services */
import { deleteUser } from "../../../services/accessService";

/** Types */
import { SaveResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* deleteUserSaga({
  payload: {userId},
}: DeleteUserRequest) {
  const { cancelToken, source } = createCancelToken();

  try {
    const response: SaveResponseDataType = yield call(
      deleteUser,
      userId,
      { cancelToken }
    );
    if(response && response.resultCode === ResultCode.Success) {
      yield put(deleteUserSuccess());
      yield put(getUsersRequest());
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(deleteUserFailure(response.message));
    }    
  } catch (error) {
    yield put(deleteUserFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default deleteUserSaga;