import { Macro, Server, Template, Type } from "./types";

export const SET_ERROR = "[DEVICE_SETTING] SET_ERROR";

export const GET_MACROS_REQUEST = "[DEVICE_SETTING] GET_MACROS_REQUEST";
export const GET_MACROS_SUCCESS = "[DEVICE_SETTING] GET_MACROS_SUCCESS";
export const GET_MACROS_FAILURE = "[DEVICE_SETTING] GET_MACROS_FAILURE";

export const GET_MACROS_BY_TEMPLATEID_REQUEST = "[DEVICE_SETTING] GET_MACROS_BY_TEMPLATEID_REQUEST";
export const GET_MACROS_BY_TEMPLATEID_SUCCESS = "[DEVICE_SETTING] GET_MACROS_BY_TEMPLATEID_SUCCESS";
export const GET_MACROS_BY_TEMPLATEID_FAILURE = "[DEVICE_SETTING] GET_MACROS_BY_TEMPLATEID_FAILURE";

export const GET_TYPES_REQUEST = "[DEVICE_SETTING] GET_TYPES_REQUEST";
export const GET_TYPES_SUCCESS = "[DEVICE_SETTING] GET_TYPES_SUCCESS";
export const GET_TYPES_FAILURE = "[DEVICE_SETTING] GET_TYPES_FAILURE";

export const SAVE_TYPE_REQUEST = "[DEVICE_SETTING] SAVE_TYPE_REQUEST";
export const SAVE_TYPE_SUCCESS = "[DEVICE_SETTING] SAVE_TYPE_SUCCESS";
export const SAVE_TYPE_FAILURE = "[DEVICE_SETTING] SAVE_TYPE_FAILURE";

export const GET_TYPE_REQUEST = "[DEVICE_SETTING] GET_TYPE_REQUEST";
export const GET_TYPE_SUCCESS = "[DEVICE_SETTING] GET_TYPE_SUCCESS";
export const GET_TYPE_FAILURE = "[DEVICE_SETTING] GET_TYPE_FAILURE";

export const DELETE_TYPE_REQUEST = "[DEVICE_SETTING] DELETE_TYPE_REQUEST";
export const DELETE_TYPE_SUCCESS = "[DEVICE_SETTING] DELETE_TYPE_SUCCESS";
export const DELETE_TYPE_FAILURE = "[DEVICE_SETTING] DELETE_TYPE_FAILURE";

export const SAVE_MACRO_REQUEST = "[DEVICE_SETTING] SAVE_MACRO_REQUEST";
export const SAVE_MACRO_SUCCESS = "[DEVICE_SETTING] SAVE_MACRO_SUCCESS";
export const SAVE_MACRO_FAILURE = "[DEVICE_SETTING] SAVE_MACRO_FAILURE";

export const GET_MACRO_REQUEST = "[DEVICE_SETTING] GET_MACRO_REQUEST";
export const GET_MACRO_SUCCESS = "[DEVICE_SETTING] GET_MACRO_SUCCESS";
export const GET_MACRO_FAILURE = "[DEVICE_SETTING] GET_MACRO_FAILURE";

export const DELETE_MACRO_REQUEST = "[DEVICE_SETTING] DELETE_MACRO_REQUEST";
export const DELETE_MACRO_SUCCESS = "[DEVICE_SETTING] DELETE_MACRO_SUCCESS";
export const DELETE_MACRO_FAILURE = "[DEVICE_SETTING] DELETE_MACRO_FAILURE";

export const SAVE_TEMPLATE_REQUEST = "[DEVICE_SETTING] SAVE_TEMPLATE_REQUEST";
export const SAVE_TEMPLATE_SUCCESS = "[DEVICE_SETTING] SAVE_TEMPLATE_SUCCESS";
export const SAVE_TEMPLATE_FAILURE = "[DEVICE_SETTING] SAVE_TEMPLATE_FAILURE";

export const GET_TEMPLATE_REQUEST = "[DEVICE_SETTING] GET_TEMPLATE_REQUEST";
export const GET_TEMPLATE_SUCCESS = "[DEVICE_SETTING] GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_FAILURE = "[DEVICE_SETTING] GET_TEMPLATE_FAILURE";

export const GET_TEMPLATES_REQUEST = "[DEVICE_SETTING] GET_TEMPLATES_REQUEST";
export const GET_TEMPLATES_SUCCESS = "[DEVICE_SETTING] GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_FAILURE = "[DEVICE_SETTING] GET_TEMPLATES_FAILURE";

export const DELETE_TEMPLATE_REQUEST = "[DEVICE_SETTING] DELETE_TEMPLATE_REQUEST";
export const DELETE_TEMPLATE_SUCCESS = "[DEVICE_SETTING] DELETE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_FAILURE = "[DEVICE_SETTING] DELETE_TEMPLATE_FAILURE";

export const GET_SERVERS_REQUEST = "[DEVICE_SETTING] GET_SERVERS_REQUEST";
export const GET_SERVERS_SUCCESS = "[DEVICE_SETTING] GET_SERVERS_SUCCESS";
export const GET_SERVERS_FAILURE = "[DEVICE_SETTING] GET_SERVERS_FAILURE";

export const SAVE_SERVER_REQUEST = "[DEVICE_SETTING] SAVE_SERVER_REQUEST";
export const SAVE_SERVER_SUCCESS = "[DEVICE_SETTING] SAVE_SERVER_SUCCESS";
export const SAVE_SERVER_FAILURE = "[DEVICE_SETTING] SAVE_SERVER_FAILURE";

export const GET_SERVER_REQUEST = "[DEVICE_SETTING] GET_SERVER_REQUEST";
export const GET_SERVER_SUCCESS = "[DEVICE_SETTING] GET_SERVER_SUCCESS";
export const GET_SERVER_FAILURE = "[DEVICE_SETTING] GET_SERVER_FAILURE";

export const DELETE_SERVER_REQUEST = "[DEVICE_SETTING] DELETE_SERVER_REQUEST";
export const DELETE_SERVER_SUCCESS = "[DEVICE_SETTING] DELETE_SERVER_SUCCESS";
export const DELETE_SERVER_FAILURE = "[DEVICE_SETTING] DELETE_SERVER_FAILURE";

export const RESET_MACROS_REQUEST = "[DEVICE_SETTING] RESET_MACROS_REQUEST";

export interface ResetMacrosRequest {
  type: typeof RESET_MACROS_REQUEST;
}

/** Save Type */
export interface SaveTypeRequest {
  type: typeof SAVE_TYPE_REQUEST;
  payload: {
      type: Type | undefined;
  };
}

export interface SaveTypeSuccess {
  type: typeof SAVE_TYPE_SUCCESS;
}

export interface SaveTypeFailure {
  type: typeof SAVE_TYPE_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Get Type */
export interface GetTypeRequest {
  type: typeof GET_TYPE_REQUEST;
  payload: {
      typeId: string | undefined;
  };
}

export interface GetTypeSuccess {
  type: typeof GET_TYPE_SUCCESS;
  payload: {
      type: Type | undefined;
  };
}

export interface GetTypeFailure {
  type: typeof GET_TYPE_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Get Types */
export interface GetTypesRequest {
  type: typeof GET_TYPES_REQUEST;
}

export interface GetTypesSuccess {
  type: typeof GET_TYPES_SUCCESS;
  payload: {
      types: Array<Type> | undefined;
  };
}

export interface GetTypesFailure {
  type: typeof GET_TYPES_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Delete Type */
export interface DeleteTypeRequest {
  type: typeof DELETE_TYPE_REQUEST;
  payload: {
      typeId: string | undefined;
  };
}

export interface DeleteTypeSuccess {
  type: typeof DELETE_TYPE_SUCCESS;
}

export interface DeleteTypeFailure {
  type: typeof DELETE_TYPE_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Save Macro */
export interface SaveMacroRequest {
  type: typeof SAVE_MACRO_REQUEST;
  payload: {
      macro: Macro | undefined;
  };
}

export interface SaveMacroSuccess {
  type: typeof SAVE_MACRO_SUCCESS;
}

export interface SaveMacroFailure {
  type: typeof SAVE_MACRO_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Get Macro */
export interface GetMacroRequest {
  type: typeof GET_MACRO_REQUEST;
  payload: {
      macroId: string | undefined;
  };
}

export interface GetMacroSuccess {
  type: typeof GET_MACRO_SUCCESS;
  payload: {
      macro: Macro | undefined;
  };
}

export interface GetMacroFailure {
  type: typeof GET_MACRO_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Get Macros */
export interface GetMacrosRequest {
  type: typeof GET_MACROS_REQUEST;
}

export interface GetMacrosSuccess {
  type: typeof GET_MACROS_SUCCESS;
  payload: {
      macros: Array<Macro> | undefined;
  };
}

export interface GetMacrosFailure {
  type: typeof GET_MACROS_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Get Macros by TemplateId */
export interface GetMacrosByTemplateIdRequest {
  type: typeof GET_MACROS_BY_TEMPLATEID_REQUEST;
  payload: {
    templateId: string
  };
}

export interface GetMacrosByTemplateIdSuccess {
  type: typeof GET_MACROS_BY_TEMPLATEID_SUCCESS;
  payload: {
      macros: Array<Macro> | undefined;
  };
}

export interface GetMacrosByTemplateIdFailure {
  type: typeof GET_MACROS_BY_TEMPLATEID_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Delete Macro */
export interface DeleteMacroRequest {
  type: typeof DELETE_MACRO_REQUEST;
  payload: {
      macroId: string | undefined;
  };
}

export interface DeleteMacroSuccess {
  type: typeof DELETE_MACRO_SUCCESS;
}

export interface DeleteMacroFailure {
  type: typeof DELETE_MACRO_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Save Template */
export interface SaveTemplateRequest {
  type: typeof SAVE_TEMPLATE_REQUEST;
  payload: {
    template: Template | undefined;
  };
}

export interface SaveTemplateSuccess {
  type: typeof SAVE_TEMPLATE_SUCCESS;
}

export interface SaveTemplateFailure {
  type: typeof SAVE_TEMPLATE_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Get Template */
export interface GetTemplateRequest {
  type: typeof GET_TEMPLATE_REQUEST;
  payload: {
    templateId: string | undefined;
  };
}

export interface GetTemplateSuccess {
  type: typeof GET_TEMPLATE_SUCCESS;
  payload: {
    template: Template | undefined;
  };
}

export interface GetTemplateFailure {
  type: typeof GET_TEMPLATE_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Get Template */
export interface GetTemplatesRequest {
  type: typeof GET_TEMPLATES_REQUEST;
}

export interface GetTemplatesSuccess {
  type: typeof GET_TEMPLATES_SUCCESS;
  payload: {
    templates: Array<Template> | undefined;
  };
}

export interface GetTemplatesFailure {
  type: typeof GET_TEMPLATES_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Delete Template */
export interface DeleteTemplateRequest {
  type: typeof DELETE_TEMPLATE_REQUEST;
  payload: {
    templateId: string | undefined;
  };
}

export interface DeleteTemplateSuccess {
  type: typeof DELETE_TEMPLATE_SUCCESS;
}

export interface DeleteTemplateFailure {
  type: typeof DELETE_TEMPLATE_FAILURE;
  payload: {
    error: string | undefined;
  };
}


/** Save Server */
export interface SaveServerRequest {
  type: typeof SAVE_SERVER_REQUEST;
  payload: {
      server: Server | undefined;
  };
}

export interface SaveServerSuccess {
  type: typeof SAVE_SERVER_SUCCESS;
}

export interface SaveServerFailure {
  type: typeof SAVE_SERVER_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Get Server */
export interface GetServerRequest {
  type: typeof GET_SERVER_REQUEST;
  payload: {
      serverId: string | undefined;
  };
}

export interface GetServerSuccess {
  type: typeof GET_SERVER_SUCCESS;
  payload: {
      server: Server | undefined;
  };
}

export interface GetServerFailure {
  type: typeof GET_SERVER_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Get Servers */
export interface GetServersRequest {
  type: typeof GET_SERVERS_REQUEST;
}

export interface GetServersSuccess {
  type: typeof GET_SERVERS_SUCCESS;
  payload: {
    servers: Array<Server> | undefined;
  };
}

export interface GetServersFailure {
  type: typeof GET_SERVERS_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Delete Server */
export interface DeleteServerRequest {
  type: typeof DELETE_SERVER_REQUEST;
  payload: {
    serverId: string | undefined;
  };
}

export interface DeleteServerSuccess {
  type: typeof DELETE_SERVER_SUCCESS;
}

export interface DeleteServerFailure {
  type: typeof DELETE_SERVER_FAILURE;
  payload: {
    error: string | undefined;
  };
}

export interface SetError {
    type: typeof SET_ERROR;
    payload: {
      error: string | undefined;
    };
  }

export type DeviceSettingsActionTypes =
  | GetMacrosByTemplateIdRequest
  | GetMacrosByTemplateIdSuccess
  | GetMacrosByTemplateIdFailure
  | SaveServerRequest
  | SaveServerSuccess
  | SaveServerFailure
  | SaveTypeRequest
  | SaveTypeSuccess
  | SaveTypeFailure
  | SaveMacroRequest
  | SaveMacroSuccess
  | SaveMacroFailure
  | DeleteServerRequest
  | DeleteServerSuccess
  | DeleteServerFailure
  | DeleteTypeRequest
  | DeleteTypeSuccess
  | DeleteTypeFailure
  | DeleteMacroRequest
  | DeleteMacroSuccess
  | DeleteMacroFailure
  | GetServerRequest
  | GetServerSuccess
  | GetServerFailure
  | GetServersRequest
  | GetServersSuccess
  | GetServersFailure
  | GetTypeRequest
  | GetTypeSuccess
  | GetTypeFailure
  | GetTypesRequest
  | GetTypesSuccess
  | GetTypesFailure
  | GetMacroRequest
  | GetMacroSuccess
  | GetMacroFailure
  | GetMacrosRequest
  | GetMacrosSuccess
  | GetMacrosFailure
  | ResetMacrosRequest
  | SetError;
