import { PhotosType, ProfileType } from "./types";

export const SET_USER_PROFILE = "[PROFILE] SET_USER_PROFILE";
export const SAVE_PHOTO_SUCCESS = "[PROFILE] SAVE_PHOTO_SUCCESS";
export const ADD_PHOTO = "[PROFILE] ADD_PHOTO";
export const SET_ERROR = "[PROFILE] SET_ERROR";

export const GET_USER_PROFILE_REQUEST = "[PROFILE] GET_USER_PROFILE_REQUEST";
export const SAVE_PROFILE_REQUEST = "[PROFILE] SAVE_PROFILE_REQUEST";
export const SAVE_PHOTO_REQUEST = "[PROFILE] SAVE_PHOTO_REQUEST";
export const ADD_PHOTO_REQUEST = "[PROFILE] ADD_PHOTO_REQUEST";

export interface SetUserProfile {
    type: typeof SET_USER_PROFILE;
    payload: {
       profile: ProfileType;
    };
}

export interface GetUserProfileRequest {
    type: typeof GET_USER_PROFILE_REQUEST;
    payload: {
        userId: string | null
    };
}

export interface SaveProfileRequest {
    type: typeof SAVE_PROFILE_REQUEST;
    payload: {
        profile: ProfileType
    };
}

export interface SavePhotoRequest {
    type: typeof SAVE_PHOTO_REQUEST;
    payload: { 
        file: File;
    }
}

export interface AddPhotoRequest {
    type: typeof ADD_PHOTO_REQUEST;
    payload: { 
        photo: HTMLImageElement | undefined;
    }
}

export interface SavePhotoSuccess {
    type: typeof SAVE_PHOTO_SUCCESS;
    payload: { 
        photos: PhotosType;
    }
}

export interface AddPhoto {
    type: typeof ADD_PHOTO;
    payload: {
        photo: HTMLImageElement | undefined;
    };
}

export interface SetError {
    type: typeof SET_ERROR;
    payload: {
      error: string | undefined;
    };
  }

export type ProfileActionTypes =
  | SetUserProfile
  | GetUserProfileRequest
  | SaveProfileRequest
  | SavePhotoRequest
  | AddPhotoRequest
  | SavePhotoSuccess
  | SetError
  | AddPhoto;