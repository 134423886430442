/** Absolute imports */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

/** Ant design */
import { Button, Table, Modal, Alert } from 'antd';

/** Components */
import Preloader from '../../../components/Preloader/preloader';
import { TemplateEdit } from './Edit/template-edit';

/** Types */
import { TemplatesPageSelectors } from './types';

/** Store */
import { ApplicationState } from '../../../store';
import { makeGetError, makeGetTemplate, makeGetTemplates } from '../../../store/deviceSettings/selectors';
import { deleteTemplateRequest, getMacrosRequest, getTemplateRequest, getTemplatesRequest } from '../../../store/deviceSettings/actions';

/** Styles */
import s from './styles.module.scss';
import { Template } from '../../../store/deviceSettings/types';
import { makeGetIsAuth } from '../../../store/auth/selectors';

export const TemplatesPage: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isTemplateEditVisible, setIsTemplateEditVisible] = useState(false);
    const [templateData, setTemplateData] = useState<Template | undefined>(undefined);
    const [templateId, setTemplateId] = useState<string | undefined>(undefined);
    
    const dispatch = useDispatch();
    const selector = createStructuredSelector<
    ApplicationState,
    TemplatesPageSelectors
    >({
        isAuth: makeGetIsAuth(),
        error: makeGetError(),
        templates: makeGetTemplates(),
        template: makeGetTemplate()
    });

    const {
        error,
        templates,
        isAuth,
        template
    } = useSelector(selector);

    useEffect(() => {
        dispatch(getTemplatesRequest());
        dispatch(getMacrosRequest());
    }, []);

    useEffect(() => {
        if (template)
            setTemplateData(template);
    }, [template]);

    const handleCancelisOpenTemplateEditModal = () => {
        setIsTemplateEditVisible(false);
        setTemplateData(undefined);
    }

    const showModal = (templateId: string) => {
        setTemplateId(templateId);
        setIsModalVisible(true);
      };

    const onEditTemplateHandle = (id: string | undefined) => {
        if (id) {
            dispatch(getTemplateRequest(id));
        } else {
            setTemplateData({
                id: "",
                description: "",
                macros: [],
                name: "",
                templateid: ""
            });
        }
        setIsTemplateEditVisible(true);
    }

    const handleOk = () => {
        if(templateId) {
          setIsModalVisible(false);
          dispatch(deleteTemplateRequest(templateId));
        }
    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns: any = [
        {
          width: 1,
          dataIndex: 'id',
          key: 'id',
          fixed: 'left',
          className: s.idColumn
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: '1',
            width: 250,
            fixed: 'left',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: '2',
          width: 250,
        },
        {
          title: 'Edit',
          key: 'editOperation',
          fixed: 'right',
          width: 50,
          render: (values: any) => { 
            return (<Button onClick={() => onEditTemplateHandle(values.id)}>Edit</Button>)
          },
        },
        {
          title: 'Delete',
          key: 'deleteOperation',
          fixed: 'right',
          width: 50,
          render: (values: any) => <Button onClick={() => showModal(values.id)}>Delete</Button>,
        },
      ];      
      

    if (!isAuth) {
        return  <Redirect to={"/login"} />
    }

    return (
    <>
        {error && 
            <Alert
                message={error}
                type="error"
                className={s.error}
                showIcon
            />
        }
        <div style={{marginBottom: '15px'}}><Button onClick={() => onEditTemplateHandle(undefined)}>Add template</Button></div>
        <Table rowKey={record => record.id}  columns={columns} dataSource={templates} className={s.dashboardTable} />
        <Modal title="Delete the template" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <div>Are you sure that you want to delete this template?</div>
        </Modal>
        {templateData && 
            <TemplateEdit visible={isTemplateEditVisible} initialValues={templateData} onCancel={handleCancelisOpenTemplateEditModal} />
        }
    </>
    );
}