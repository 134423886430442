/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { DeleteRoleRequest, DeleteUserRequest, SaveUserRequest } from "../actionTypes";
import {
    deleteRoleFailure,
    deleteRoleSuccess,
    getRolesRequest,
} from "../actions";

/** Services */
import { deleteRole } from "../../../services/accessService";

/** Types */
import { SaveResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* deleteRoleSaga({
  payload: {roleId},
}: DeleteRoleRequest) {
  const { cancelToken, source } = createCancelToken();

  try {
    const response: SaveResponseDataType = yield call(
      deleteRole,
      roleId,
      { cancelToken }
    );
    if(response && response.resultCode === ResultCode.Success) {
      yield put(deleteRoleSuccess());
      yield put(getRolesRequest());
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(deleteRoleFailure(response.message));
    }    
  } catch (error) {
    yield put(deleteRoleFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default deleteRoleSaga;