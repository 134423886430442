/** Absolute imports */
import { createSelector } from "reselect";

/** Redux */
import { INITIAL_STATE } from "../auth/reducer";

/** Types */
import { ApplicationState } from "..";
import { DeviceSettingsState } from "./types";

const selectDeviceSettings = (state: ApplicationState): DeviceSettingsState =>
  state.deviceSettings || INITIAL_STATE;

export const makeGetError = () => 
    createSelector(selectDeviceSettings, (deviceState) => deviceState.error);

export const makeGetTypes = () => 
    createSelector(selectDeviceSettings, (deviceState) => deviceState.types);

export const makeGetServers = () => 
    createSelector(selectDeviceSettings, (deviceState) => deviceState.servers);

export const makeGetMacros = () => 
    createSelector(selectDeviceSettings, (deviceState) => deviceState.macros);

export const makeGetTemplates = () => 
    createSelector(selectDeviceSettings, (deviceState) => deviceState.templates);

export const makeGetType = () => 
    createSelector(selectDeviceSettings, (deviceState) => deviceState.type);

export const makeGetServer = () => 
    createSelector(selectDeviceSettings, (deviceState) => deviceState.server);

export const makeGetMacro = () => 
    createSelector(selectDeviceSettings, (deviceState) => deviceState.macro);

export const makeGetTemplate = () => 
    createSelector(selectDeviceSettings, (deviceState) => deviceState.template);