/** Absolurte imports */
import { Reducer } from "redux";

/** Types */
import { HostState } from "./types";
import { Status } from "../deviceSettings/types";
import {
    DeviceActionTypes,
    SET_ERROR,
    SAVE_HOST_FAILURE,
    SAVE_HOST_SUCCESS,
    SAVE_HOST_REQUEST,
    DELETE_HOST_REQUEST,
    DELETE_HOST_SUCCESS,
    DELETE_HOST_FAILURE,
    GET_HOST_REQUEST,
    GET_HOST_SUCCESS,
    GET_HOST_FAILURE,
    GET_HOSTS_REQUEST,
    GET_HOSTS_SUCCESS,
    GET_HOSTS_FAILURE,
    SET_HOST_STATUS_REQUEST,
    SET_HOST_STATUS_SUCCESS,
    SET_HOST_STATUS_FAILURE,
} from "./actionTypes";


export const INITIAL_STATE: HostState = {
    host: undefined,
    hosts: [],
    error: undefined,
    status: Status.off,
    types: [],
    macros: [],
    macroValue: [],
    type: undefined,
    macro: undefined,
    isLoading: false,
    isHostSaved: false,
    isStatusChanged: false
}

const reducer: Reducer<HostState> = (
  state = INITIAL_STATE,
  action: DeviceActionTypes | any
) => {
    switch(action.type) {
        /** Get Host */
        case GET_HOST_REQUEST: {
            return { ...state, host: undefined, isLoading: true, isHostSaved: false };
        }
        case GET_HOST_SUCCESS: {
            return { ...state, 
                host: action.payload.host, 
                isLoading: false };
        }
        case GET_HOST_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Get Hosts */
        case GET_HOSTS_REQUEST: {
            return { ...state, isLoading: true, isHostSaved: false };
        }
        case GET_HOSTS_SUCCESS: {
            return { ...state, 
                hosts: action.payload.hosts, 
                isLoading: false };
        }
        case GET_HOSTS_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
        /** Save Host */
        case SAVE_HOST_REQUEST: {
            return { ...state, isLoading: true, isHostSaved: false };
        }
        case SAVE_HOST_SUCCESS: {
            return { ...state, 
                isLoading: false,
                isHostSaved: true
            };
        }
        case SAVE_HOST_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error,
                isHostSaved: false
            };
        }
        /** Save Host */
        case SET_HOST_STATUS_REQUEST: {
            return { ...state, isLoading: true, isStatusChanged: false };
        }
        case SET_HOST_STATUS_SUCCESS: {
            return { ...state, 
                isLoading: false,
                isStatusChanged: true
            };
        }
        case SET_HOST_STATUS_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error,
                isStatusChanged: false
            };
        }
        /** Delete Host */
        case DELETE_HOST_REQUEST: {
            return { ...state, isLoading: true };
        }
        case DELETE_HOST_SUCCESS: {
            return { ...state, isLoading: false };
        }
        case DELETE_HOST_FAILURE: {
            return { ...state, 
                isLoading: false, 
                error: action.payload.error };
        }
         /** Set error */
        case SET_ERROR: {
            return {
                ...state,
                error: action.payload.error
            }
        }
        default:
            return state;
    }
};

export default reducer;