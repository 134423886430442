/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { RegisterRequest } from "../actionTypes";
import {
    setError,
    setRegisterSuccess,
} from "../actions";

/** Services */
import { register } from "../../../services/authService";

/** Types */
import { RegisterResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";

function* registerSaga({
  payload: {email, password, userName},
}: RegisterRequest) {
  const { cancelToken, source } = createCancelToken();

  try {
    yield put(setError(undefined));
    yield put(setRegisterSuccess(false));
    const response: RegisterResponseDataType = yield call(
      register,
      { email, password, userName },
      { cancelToken }
    );
    console.log(response);
    if(response && response.resultCode === ResultCode.Success) {
        yield put(setRegisterSuccess(true));
    }
    if(response && response.resultCode === ResultCode.Error) {
        yield put(setError(response.message));
    }
  } catch (error) {
      console.log(error);
    yield put(setError(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default registerSaga;
