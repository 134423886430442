/** Absolute imports */
import { takeLatest } from "redux-saga/effects";

/** Types */
import { 
  CHANGE_USER_ROLES_REQUEST,
  DELETE_ROLE_REQUEST,
  DELETE_USER_REQUEST,
  GET_ROLES_REQUEST,
    GET_ROLE_REQUEST,
    GET_USERS_REQUEST, 
    GET_USER_REQUEST, 
    GET_USER_ROLES_REQUEST, 
    SAVE_ROLE_REQUEST, 
    SAVE_USER_REQUEST,
} from "./actionTypes";


/** Sagas */
import getUsersSaga from "./sagas/getUsersSaga";
import getUserSaga from "./sagas/getUserSaga";
import saveRoleSaga from "./sagas/saveRoleSaga";
import saveUserSaga from "./sagas/saveUserSaga";
import getRoleSaga from "./sagas/getRoleSaga";
import getRolesSaga from "./sagas/getRolesSaga";
import changeUserRolesSaga from "./sagas/changeUserRolesSaga";
import deleteUserSaga from "./sagas/deleteUserSaga";
import deleteRoleSaga from "./sagas/deleteRoleSaga";
import getUserRolesSaga from "./sagas/getUserRolesSaga";

function* watchProfileSagas() {
  yield takeLatest(GET_USERS_REQUEST, getUsersSaga);
  yield takeLatest(GET_USER_REQUEST, getUserSaga);
  yield takeLatest(SAVE_USER_REQUEST, saveUserSaga);
  yield takeLatest(DELETE_USER_REQUEST, deleteUserSaga);
  yield takeLatest(GET_ROLES_REQUEST, getRolesSaga);
  yield takeLatest(GET_ROLE_REQUEST, getRoleSaga);
  yield takeLatest(SAVE_ROLE_REQUEST, saveRoleSaga);
  yield takeLatest(DELETE_ROLE_REQUEST, deleteRoleSaga);
  yield takeLatest(CHANGE_USER_ROLES_REQUEST, changeUserRolesSaga);
  yield takeLatest(GET_USER_ROLES_REQUEST, getUserRolesSaga);
}

export default watchProfileSagas;