/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { SaveUserRequest } from "../actionTypes";
import {
    getUsersRequest,
  saveUserFailure,
  saveUserSuccess,
} from "../actions";

/** Services */
import { saveUser } from "../../../services/accessService";

/** Types */
import { SaveResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* saveUserSaga({
  payload: {user},
}: SaveUserRequest) {
  const { cancelToken, source } = createCancelToken();

  try {
    const response: SaveResponseDataType = yield call(
      saveUser,
      user,
      { cancelToken }
    );
    if(response && response.resultCode === ResultCode.Success) {
      yield put(saveUserSuccess());
      yield put(getUsersRequest());
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(saveUserFailure(response.message));
    }    
  } catch (error) {
    yield put(saveUserFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default saveUserSaga;