/** Absolute imports */
import { createSelector } from "reselect";

/** Redux */
import { INITIAL_STATE } from "../auth/reducer";

/** Types */
import { ApplicationState } from "..";
import { HostState } from "./types";

const selectDevice = (state: ApplicationState): HostState =>
  state.device || INITIAL_STATE;

export const makeGetError = () => 
    createSelector(selectDevice, (deviceState) => deviceState.error);

export const makeGetHosts = () => 
    createSelector(selectDevice, (deviceState) => deviceState.hosts);

export const makeGetHost = () => 
    createSelector(selectDevice, (deviceState) => deviceState.host);

export const makeGetIsHostSaved = () => 
    createSelector(selectDevice, (deviceState) => deviceState.isHostSaved);