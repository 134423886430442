import React, {useContext} from 'react';
import { reduxForm } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Alert, Divider } from 'antd';
import style from './login.module.scss';
import { makeGetCaptchaUrl, makeGetError, makeGetIsAuth, makeGetRegisterSuccess } from '../../store/auth/selectors';
import { LoginPageSelectors, LoginSelectors } from './types';
import { ApplicationState } from '../../store';
import { createStructuredSelector } from 'reselect';
import { loginRequest } from '../../store/auth/actions';

export type LoginFormValuesType = {
    email: string
    password: string
    rememberMe: boolean
    //captcha: string
}

export const LoginPage: React.FC = () => { 

  const selector = createStructuredSelector<
      ApplicationState,
      LoginPageSelectors
    >({
      isAuth: makeGetIsAuth(),
      captchaUrl: makeGetCaptchaUrl()
    });

    const {
      isAuth,
      captchaUrl
    } = useSelector(selector);

    const dispatch = useDispatch();

    const onSubmit = (formData: LoginFormValuesType) => {
        dispatch(loginRequest(formData.email, formData.password));
    }

    if(isAuth) {
        return <Redirect to={"/dashboard"} />
    }
    return <div className={style.loginForm}>  
        <h1>Login</h1>   
        <Divider />
        <LoginReduxForm  onSubmit={onSubmit} captchaUrl={captchaUrl} />
    </div>
}

type LoginFormPropsType = {
    captchaUrl: string | undefined
}

const LoginForm: React.FC<LoginFormPropsType> = ({captchaUrl}) => {
    
    const dispatch = useDispatch();

    const selector = createStructuredSelector<
      ApplicationState,
      LoginSelectors
    >({
      error: makeGetError()
    });

    const {
      error
    } = useSelector(selector);
    
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
      };

    const onFinish = (formData: LoginFormValuesType) => {
        console.log('Success:', formData);
        dispatch(loginRequest(formData.email, formData.password));
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {error && 
            <Alert
                message={error}
                type="error"
                showIcon
            />
        }
        <div style={{marginTop: '20px'}}>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input />
        </Form.Item>
  
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>
  
        <Form.Item {...tailLayout} name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
  
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        </div>
      </Form>
    );
}

const LoginReduxForm = reduxForm<LoginFormValuesType, LoginFormPropsType>({
    form: 'login'
})(LoginForm);