/** Types */
import {
    SaveHostRequest,
    SetError,
    SAVE_HOST_REQUEST,
    SaveHostSuccess,
    SAVE_HOST_SUCCESS,
    SAVE_HOST_FAILURE,
    SaveHostFailure,
    DeleteHostRequest,
    DELETE_HOST_SUCCESS,
    DELETE_HOST_REQUEST,
    DeleteHostSuccess,
    DELETE_HOST_FAILURE,
    DeleteHostFailure,
    GetHostRequest,
    GetHostSuccess,
    GetHostFailure,
    GET_HOST_REQUEST,
    GET_HOST_SUCCESS,
    GET_HOST_FAILURE,
    GET_HOSTS_REQUEST,
    GET_HOSTS_SUCCESS,
    GET_HOSTS_FAILURE,
    GetHostsRequest,
    GetHostsSuccess,
    GetHostsFailure,
    SET_ERROR,
    SetHostStatusRequest,
    SET_HOST_STATUS_REQUEST,
    SET_HOST_STATUS_SUCCESS,
    SetHostStatusSuccess,
    SET_HOST_STATUS_FAILURE,
    SetHostStatusFailure
  } from "./actionTypes";
import { Host, HostForShowing } from "./types";

export const setError = (
    error: string | undefined
    ): SetError => ({
    type: SET_ERROR, 
    payload: {error}
});

/** Set Host Status */
export const setHostStatusRequest = (id: string, status: boolean): SetHostStatusRequest => ({
    type: SET_HOST_STATUS_REQUEST,
    payload: {
        id,
        status
    }
});
  
export const setHostStatusSuccess = (): SetHostStatusSuccess => ({
    type: SET_HOST_STATUS_SUCCESS
});
  
export const setHostStatusFailure = (error: string | undefined): SetHostStatusFailure => ({
    type: SET_HOST_STATUS_FAILURE,
    payload: { error }
});

/** Save Host */
export const addHostRequest = (host: Host | undefined): SaveHostRequest => ({
    type: SAVE_HOST_REQUEST,
    payload: { host }
});
  
export const addHostSuccess = (): SaveHostSuccess => ({
    type: SAVE_HOST_SUCCESS
});
  
export const addHostFailure = (error: string | undefined): SaveHostFailure => ({
    type: SAVE_HOST_FAILURE,
    payload: { error }
});

/** Get Host */
export const getHostRequest = (hostId: string | undefined): GetHostRequest => ({
    type: GET_HOST_REQUEST,
    payload: { hostId }
});
  
export const getHostSuccess = (host: Host | undefined): GetHostSuccess => ({
    type: GET_HOST_SUCCESS,
    payload: { host }
});
  
export const getHostFailure = (error: string | undefined): GetHostFailure => ({
    type: GET_HOST_FAILURE,
    payload: { error }
});

/** Get Hosts */
export const getHostsRequest = (): GetHostsRequest => ({
    type: GET_HOSTS_REQUEST
});
  
export const getHostsSuccess = (hosts: Array<HostForShowing> | undefined): GetHostsSuccess => ({
    type: GET_HOSTS_SUCCESS,
    payload: { hosts }
});
  
export const getHostsFailure = (error: string | undefined): GetHostsFailure => ({
    type: GET_HOSTS_FAILURE,
    payload: { error }
});

/** Delete Host */
export const deleteHostRequest = (hostId: string | undefined): DeleteHostRequest => ({
    type: DELETE_HOST_REQUEST,
    payload: { hostId }
});
  
export const deleteHostSuccess = (): DeleteHostSuccess => ({
    type: DELETE_HOST_SUCCESS
});
  
export const deleteHostFailure = (error: string | undefined): DeleteHostFailure => ({
    type: DELETE_HOST_FAILURE,
    payload: { error }
});