/** Absolute imports */
import { call, put, cancelled } from "redux-saga/effects";

/** Redux */
import { SaveTypeRequest } from "../actionTypes";
import {
    getTypesRequest,
    saveTypeFailure,
    saveTypeSuccess,
} from "../actions";

/** Services */
import { saveType } from "../../../services/hostSettingsService";

/** Types */
import { SaveTypeResponseDataType } from "../types";

/** Utils */
import { createCancelToken } from "../../../utils/httpUtils";

/** Enum */
import { ResultCode } from "../../../services/types";


function* saveTypeSaga({
  payload: {type},
}: SaveTypeRequest) {
  const { cancelToken, source } = createCancelToken();

  try {
    const response: SaveTypeResponseDataType = yield call(
      saveType,
      type,
      { cancelToken }
    );
    if(response && response.resultCode === ResultCode.Success) {
      yield put(saveTypeSuccess());
      yield put(getTypesRequest());
    } 
    if(response && response.resultCode === ResultCode.Error) {
        yield put(saveTypeFailure(response.message));
    }    
  } catch (error) {
    yield put(saveTypeFailure(error));
  } finally {
    //@ts-ignore
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export default saveTypeSaga;