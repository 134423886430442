import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, Dropdown, Avatar, Row, Layout, Button } from 'antd';
import s from './header.module.scss';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { BellOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import { createStructuredSelector } from 'reselect';
import { ApplicationState } from '../../store';
import { HeaderSelectors } from './types';
import { makeGetIsAuth, makeGetUserName, makeGetUserPhoto } from '../../store/auth/selectors';
import { logoutRequest } from '../../store/auth/actions';
import { getLocalStorage } from '../../services/api';


export const Header: React.FC<RouteComponentProps> = (props) => {

    const selector = createStructuredSelector<
        ApplicationState,
        HeaderSelectors
    >({
        isAuth: makeGetIsAuth(),
        userName: makeGetUserName(),
        userPhoto: makeGetUserPhoto()
    });

    const {
        isAuth,
        userName,
        userPhoto
    } = useSelector(selector);

    /*const avatars = useSelector((state: AppStateType) => state.chat.data.userAvatar);
    const login = useSelector(selectCurrentUserLogin);
    const profile = useSelector((state: AppStateType) => { return state.profilePage.profile });*/
    const [localData, setLocalData] = useState({ userName: '', userId: '' });
    const [avatar, setAvatar] = useState('');
    const location = useLocation();
    //const status = useSelector((state: AppStateType) => state.chat.status);

    const dispatch = useDispatch();

    const logoutCallback = () => {
        dispatch(logoutRequest());
        props.history.push('/login');
    }

    useEffect(() => {
        if (userName)
            setLocalData({ ...localData, userName: userName});
    },[userName]);

    useEffect(() => {
        if (userPhoto)
            setAvatar(userPhoto);
    },[userPhoto]);

    /*useEffect(() => {
        if ((status === 'ready') && localData && (localData.userId !== '')) {
            dispatch(getUserAvatar(localData.userId));
        }
    }, [status, profile, location, localData]);*/

    /*useEffect(() => {
        if (localData && localData.userId !== '') {
            if(avatars && avatars.userAvatar) {
                setAvatar(avatars.userAvatar);
            }
        }
    }, [avatars, localData]);*/

    useEffect(() => {
        setLocalData(getLocalStorage()); 
    }, [location])

    const menu = (
        <Menu className={s.menu}>
          <Menu.Item key="0">
            <Link to={'/about'}>About this project</Link>
          </Menu.Item>
          <Menu.Item key="1">
            <div onClick={logoutCallback}>Log out</div>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="3" disabled>
            © NikitaKirav
          </Menu.Item>
        </Menu>
      );

    const { Header } = Layout;

    return (
        <Header className={s.header}>
        <div className="logo" />
            { isAuth
                ? 
                <Row className={s.headerRowAuth}>
                <div className={s.headerDropdownAuth}>
                <div className={s.rightBlock}>
                    <div className={s.bellBlock}><BellOutlined className={s.bellIcon} /></div>
                    <Dropdown overlay={menu} className={s.headerMenu} placement="bottomRight">
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <span className={s.userName}>{localData && localData.userName}</span>
                            {avatar ? <Avatar style={{ margin: '10px' }} src={avatar} /> : <Avatar size={32} style={{ margin: '10px' }} icon={<UserOutlined />} /> }
                            <DownOutlined className={s.menuIcon} />
                        </a>
                    </Dropdown>
                </div>
                </div>
                </Row>
                :  <Row className={s.headerRow}><div className={s.headerDropdown}>
                        <div className={s.loginRegister}>
                            <div>
                                <Button>
                                    <Link to={'/login'}>Login</Link>
                                </Button>
                            </div>
                            <div style={{marginLeft: '10px'}}>
                                <Button>
                                    <Link to={'/register'}>Register</Link>
                                </Button>
                            </div>
                        </div>
                    </div>
                    </Row>    
            }
        </Header>           
    );
}