import { PhotosType, ProfileType } from "./types";

/** Types */
import {
    SET_USER_PROFILE,
    SAVE_PHOTO_SUCCESS,
    ADD_PHOTO,
    SetUserProfile,
    SavePhotoSuccess,
    AddPhoto,
    SetError,
    SET_ERROR,
    GetUserProfileRequest,
    GET_USER_PROFILE_REQUEST,
    SaveProfileRequest,
    SAVE_PROFILE_REQUEST,
    SAVE_PHOTO_REQUEST,
    SavePhotoRequest,
    AddPhotoRequest,
    ADD_PHOTO_REQUEST
  } from "./actionTypes";

export const setUserProfile = (profile: ProfileType): SetUserProfile => ({
    type: SET_USER_PROFILE, 
    payload: { profile }
});
    
export const savePhotoSuccess = (photos: PhotosType): SavePhotoSuccess => ({
    type: SAVE_PHOTO_SUCCESS, 
    payload: { photos }
});

export const addPhoto = (photo: HTMLImageElement): AddPhoto => ({
    type: ADD_PHOTO, 
    payload: { photo }
});

export const setError = (
    error: string | undefined
    ): SetError => ({
    type: SET_ERROR, 
    payload: {error}
});

export const getUserProfileRequest = (userId: string | null): GetUserProfileRequest => ({
    type: GET_USER_PROFILE_REQUEST, 
    payload: { userId }
});

export const saveProfileRequest = (profile: ProfileType): SaveProfileRequest => ({
    type: SAVE_PROFILE_REQUEST, 
    payload: { profile }
});

export const savePhotoRequest = (file: File): SavePhotoRequest => ({
    type: SAVE_PHOTO_REQUEST, 
    payload: { file }
});

export const addPhotoRequest = (photo: HTMLImageElement | undefined): AddPhotoRequest => ({
    type: ADD_PHOTO_REQUEST,
    payload: { photo }
});