import { Role, User, UserEdit, UserRoles } from "./types";

export const SET_ERROR = "[ACCESS] SET_ERROR";

export const GET_USERS_REQUEST = "[ACCESS] GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "[ACCESS] GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "[ACCESS] GET_USERS_FAILURE";

export const GET_USER_REQUEST = "[ACCESS] GET_USER_REQUEST";
export const GET_USER_SUCCESS = "[ACCESS] GET_USER_SUCCESS";
export const GET_USER_FAILURE = "[ACCESS] GET_USER_FAILURE";

export const DELETE_USER_REQUEST = "[ACCESS] DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "[ACCESS] DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "[ACCESS] DELETE_USER_FAILURE";

export const GET_ROLES_REQUEST = "[ACCESS] GET_ROLES_REQUEST";
export const GET_ROLES_SUCCESS = "[ACCESS] GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE = "[ACCESS] GET_ROLES_FAILURE";

export const GET_ROLE_REQUEST = "[ACCESS] GET_ROLE_REQUEST";
export const GET_ROLE_SUCCESS = "[ACCESS] GET_ROLE_SUCCESS";
export const GET_ROLE_FAILURE = "[ACCESS] GET_ROLE_FAILURE";

export const SAVE_ROLE_REQUEST = "[ACCESS] SAVE_ROLE_REQUEST";
export const SAVE_ROLE_SUCCESS = "[ACCESS] SAVE_ROLE_SUCCESS";
export const SAVE_ROLE_FAILURE = "[ACCESS] SAVE_ROLE_FAILURE";

export const DELETE_ROLE_REQUEST = "[ACCESS] DELETE_ROLE_REQUEST";
export const DELETE_ROLE_SUCCESS = "[ACCESS] DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILURE = "[ACCESS] DELETE_ROLE_FAILURE";

export const SAVE_USER_REQUEST = "[ACCESS] SAVE_USER_REQUEST";
export const SAVE_USER_SUCCESS = "[ACCESS] SAVE_USER_SUCCESS";
export const SAVE_USER_FAILURE = "[ACCESS] SAVE_USER_FAILURE";

export const GET_USER_ROLES_REQUEST = "[ACCESS] GET_USER_ROLES_REQUEST";
export const GET_USER_ROLES_SUCCESS = "[ACCESS] GET_USER_ROLES_SUCCESS";
export const GET_USER_ROLES_FAILURE = "[ACCESS] GET_USER_ROLES_FAILURE"; 

export const CHANGE_USER_ROLES_REQUEST = "[ACCESS] CHANGE_USER_ROLES_REQUEST";
export const CHANGE_USER_ROLES_SUCCESS = "[ACCESS] CHANGE_USER_ROLES_SUCCESS";
export const CHANGE_USER_ROLES_FAILURE = "[ACCESS] CHANGE_USER_ROLES_FAILURE";

/** Get Users */
export interface GetUsersRequest {
    type: typeof GET_USERS_REQUEST;
}

export interface GetUsersSuccess {
  type: typeof GET_USERS_SUCCESS;
  payload: {
    users: Array<User>;
};
}

export interface GetUsersFailure {
    type: typeof GET_USERS_FAILURE;
    payload: {
      error: string | undefined;
    };
}

/** Get Roles */
export interface GetRolesRequest {
  type: typeof GET_ROLES_REQUEST;
}

export interface GetRolesSuccess {
type: typeof GET_ROLES_SUCCESS;
payload: {
  roles: Array<Role>;
};
}

export interface GetRolesFailure {
  type: typeof GET_ROLES_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Get User */
export interface GetUserRequest {
  type: typeof GET_USER_REQUEST;
  payload: {
    userId: string;
  };
}

export interface GetUserSuccess {
  type: typeof GET_USER_SUCCESS;
  payload: {
    user: UserEdit;
  };
}

export interface GetUserFailure {
  type: typeof GET_USER_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Get Role */
export interface GetRoleRequest {
  type: typeof GET_ROLE_REQUEST;
  payload: {
    roleId: string;
  };
}

export interface GetRoleSuccess {
  type: typeof GET_ROLE_SUCCESS;
  payload: {
    role: Role;
  };
}

export interface GetRoleFailure {
  type: typeof GET_ROLE_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Save User */
export interface SaveUserRequest {
  type: typeof SAVE_USER_REQUEST;
  payload: {
    user: UserEdit;
  };
}

export interface SaveUserSuccess {
  type: typeof SAVE_USER_SUCCESS;
}

export interface SaveUserFailure {
  type: typeof SAVE_USER_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Save Role */
export interface SaveRoleRequest {
  type: typeof SAVE_ROLE_REQUEST;
  payload: {
    role: Role;
  };
}

export interface SaveRoleSuccess {
  type: typeof SAVE_ROLE_SUCCESS;
}

export interface SaveRoleFailure {
  type: typeof SAVE_ROLE_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Delete User */
export interface DeleteUserRequest {
  type: typeof DELETE_USER_REQUEST;
  payload: {
    userId: string;
  };
}

export interface DeleteUserSuccess {
  type: typeof DELETE_USER_SUCCESS;
}

export interface DeleteUserFailure {
  type: typeof DELETE_USER_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Delete Role */
export interface DeleteRoleRequest {
  type: typeof DELETE_ROLE_REQUEST;
  payload: {
    roleId: string;
  };
}

export interface DeleteRoleSuccess {
  type: typeof DELETE_ROLE_SUCCESS;
}

export interface DeleteRoleFailure {
  type: typeof DELETE_ROLE_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Change Roles of User */
export interface ChangeUserRolesRequest {
  type: typeof CHANGE_USER_ROLES_REQUEST;
  payload: {
    userId: string;
    roles: Array<string>;
  };
}

export interface ChangeUserRolesSuccess {
  type: typeof CHANGE_USER_ROLES_SUCCESS;
}

export interface ChangeUserRolesFailure {
  type: typeof CHANGE_USER_ROLES_FAILURE;
  payload: {
    error: string | undefined;
  };
}

/** Get User Roles */
export interface GetUserRolesRequest {
  type: typeof GET_USER_ROLES_REQUEST;
  payload: {
    userId: string;
  };
}

export interface GetUserRolesSuccess {
  type: typeof GET_USER_ROLES_SUCCESS;
  payload: {
    userRoles: UserRoles;
  };
}

export interface GetUserRolesFailure {
  type: typeof GET_USER_ROLES_FAILURE;
  payload: {
    error: string | undefined;
  };
}


export interface SetError {
  type: typeof SET_ERROR;
  payload: {
    error: string | undefined;
  };
}

export type AccessActionTypes =
  | GetUserRolesRequest
  | GetUserRolesSuccess
  | GetUserRolesFailure
  | GetUsersRequest
  | GetUsersSuccess
  | GetUsersFailure
  | GetUserRequest
  | GetUserSuccess
  | GetUserFailure
  | SaveUserRequest
  | SaveUserSuccess
  | SaveUserFailure
  | DeleteUserRequest
  | DeleteUserSuccess
  | DeleteUserFailure
  | GetRolesRequest
  | GetRolesSuccess
  | GetRolesFailure
  | GetRoleRequest
  | GetRoleSuccess
  | GetRoleFailure
  | SaveRoleRequest
  | SaveRoleSuccess
  | SaveRoleFailure
  | DeleteRoleRequest
  | DeleteRoleSuccess
  | DeleteRoleFailure
  | ChangeUserRolesRequest
  | ChangeUserRolesSuccess
  | ChangeUserRolesFailure
  | SetError;