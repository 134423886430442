/** Absolute imports */
import { createSelector } from "reselect";

/** Redux */
import { INITIAL_STATE } from "../auth/reducer";

/** Types */
import { ApplicationState } from "..";
import { AccessState } from "./types";

const selectAccess = (state: ApplicationState): AccessState =>
  state.access || INITIAL_STATE;

export const makeGetError = () => 
    createSelector(selectAccess, (accessState) => accessState.error);

export const makeGetUsers = () => 
    createSelector(selectAccess, (accessState) => accessState.users);

export const makeGetUser = () => 
    createSelector(selectAccess, (accessState) => accessState.user);

export const makeGetRoles = () => 
    createSelector(selectAccess, (accessState) => accessState.roles);

export const makeGetRole = () => 
    createSelector(selectAccess, (accessState) => accessState.role);

export const makeGetUserRoles = () => 
    createSelector(selectAccess, (accessState) => accessState.userRoles);

export const makeGetIsSavedUser = () => 
createSelector(selectAccess, (accessState) => accessState.isSavedUser);

export const makeGetIsSavedRole = () => 
createSelector(selectAccess, (accessState) => accessState.isSavedRole);

export const makeGetIsChangedUserRoles = () => 
createSelector(selectAccess, (accessState) => accessState.isChangedUserRoles);